import axiosConfig from "./axiosConfig";

const getReports = async (authToken) => {
  const { data } = await axiosConfig.get("/reports", {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
  return data;
};

export default getReports;
