/* eslint-disable react-refresh/only-export-components */
import { Feature, LineString, Polygon } from "geojson";
import * as L from "leaflet";
import "leaflet-editable";
import "leaflet.path.drag";
import "leaflet/dist/leaflet.css";
import React from "react";
import { GeoJSON, GeoJSONProps } from "react-leaflet";
import {
  coordinatesToMultiPolygonGeoJson,
  getStyle,
} from "./pvcustomobjectmap-utils";

interface GeoMultiSelectedCustomObjectsProps {
  newCustomObjects: Record<string, Feature<Polygon> | Feature<LineString>>;
  selectedObjectIDs: string[];
  onClick: (event: L.LeafletMouseEvent) => void;
}

const GeoJSONMultiSelectedCustomObjects = GeoJSON as React.ComponentType<
  GeoJSONProps & {
    draggable: boolean;
    ids: string[];
  }
>;

let lastDragLatLong: L.LatLng | undefined = undefined;

// Extract the first point from the provided latLngs
const getFirstLatLng = (
  latLngs: L.LatLng[] | L.LatLng[][] | L.LatLng[][][]
) => {
  let result: L.LatLng;
  if (latLngs[0] instanceof L.LatLng) {
    result = latLngs[0];
  } else {
    const firstArray = latLngs[0];
    if (firstArray[0] instanceof L.LatLng) {
      result = firstArray[0];
    } else {
      result = firstArray[0][0];
    }
  }
  return result.clone();
};

// Apply the provided deltas to the latLngs
export const updateLatLngs = (
  latLngs: L.LatLng[] | L.LatLng[][] | L.LatLng[][][],
  deltaLat: number,
  deltaLng: number
) => {
  return latLngs.map((latLng: L.LatLng | L.LatLng[] | L.LatLng[][]) => {
    if (latLng instanceof L.LatLng) {
      latLng.lat += deltaLat;
      latLng.lng += deltaLng;
    } else {
      return updateLatLngs(latLng, deltaLat, deltaLng);
    }
    return latLng;
  });
};

// Update the latLngs of all the other layers in the group based on the drag
const onDrag = (
  _dragEvent: L.LeafletEvent,
  featureGroupLayer: L.FeatureGroup,
  layer: L.Polyline | L.Polygon
) => {
  const newLatLng = getFirstLatLng(layer.getLatLngs());

  const deltaLat = newLatLng.lat - lastDragLatLong!.lat;
  const deltaLng = newLatLng.lng - lastDragLatLong!.lng;

  featureGroupLayer.getLayers().forEach((subLayer) => {
    if (subLayer !== layer && subLayer instanceof L.Polyline) {
      subLayer.setLatLngs(
        updateLatLngs(subLayer.getLatLngs(), deltaLat, deltaLng)
      );
    }
  });
  lastDragLatLong = newLatLng;
};

type PolylineInLayerGroup = L.Polyline & {
  layerGroup?: L.FeatureGroup;
};

const setPolyLineLayerGroup = (
  layer: L.Polyline,
  layerGroup: L.FeatureGroup
) => {
  const layerWithGroup = layer as PolylineInLayerGroup;
  layerWithGroup.layerGroup = layerGroup;
};

export const getPolyLineLayerGroup = (layer: L.Polyline) => {
  const layerWithGroup = layer as PolylineInLayerGroup;
  return layerWithGroup.layerGroup!;
};

export const GeoMultiSelectedCustomObjects: React.FC<
  GeoMultiSelectedCustomObjectsProps
> = ({ newCustomObjects, selectedObjectIDs, onClick }) => {
  const keyData =
    selectedObjectIDs.length > 1
      ? selectedObjectIDs
          .map((id) => newCustomObjects[id])
          .map((customObject) => {
            return {
              id: customObject.properties!.id,
              coordinates: customObject.geometry.coordinates,
              source: customObject.properties!.source,
              type: customObject.properties!.type,
            };
          })
      : [];

  const selectedObjectsCoords =
    selectedObjectIDs.length > 1
      ? selectedObjectIDs
          .map((id) => newCustomObjects[id])
          .map((customObject) => customObject.geometry.coordinates)
      : [];

  // Setting "key={JSON.stringify(keyData)}" is critical to ensure that React re-creates GeoJSON when the inputs change
  // rather than attempting to update them in place which GeoJSON doesn't support.
  return (
    <GeoJSONMultiSelectedCustomObjects
      key={JSON.stringify(keyData)}
      data={coordinatesToMultiPolygonGeoJson(selectedObjectsCoords)}
      eventHandlers={{
        click: onClick,
      }}
      draggable={true}
      ids={selectedObjectIDs}
      onEachFeature={(_feature, layer) => {
        if (layer instanceof L.FeatureGroup) {
          let layerIndex = 0;
          layer.eachLayer((subLayer: L.Layer) => {
            if (subLayer instanceof L.Polyline) {
              // Bind drag events handlers, and store reference to layer group
              setPolyLineLayerGroup(subLayer, layer);
              subLayer.on("dragstart", function () {
                lastDragLatLong = getFirstLatLng(subLayer.getLatLngs());
              });
              subLayer.on("drag", (dragEvent) =>
                onDrag(dragEvent, layer, subLayer)
              );
              // Set style of each subLayer
              const id = selectedObjectIDs[layerIndex];
              const object = newCustomObjects[id];
              subLayer.setStyle(getStyle(object.properties!.type, true));
              // Stash the object ID in the layer so we can retrieve it later
              subLayer.options["idsObjectId"] = id;
              layerIndex++;
            }
          });
        }
      }}
    />
  );
};
