import { usePvData } from "@inrange/building-manager-api-client";
import Table from "react-bootstrap/Table";
import Loading from "../Loading";

const PVSummary = () => {
  const { fetchPVData } = usePvData();

  if (fetchPVData.isLoading) {
    return <Loading label="Loading PV data summary..." height={"auto"} />;
  }
  if (fetchPVData.isError) {
    return (
      <div style={{ color: "red" }}>Error loading the PV data summary.</div>
    );
  }

  const summary = fetchPVData.data?.summary || {};

  const numSitesVerified = summary.sites_verified;
  const numOfSitesWithZeroPanels = summary.zero_panels?.length || 0;
  const mapRenderFailed = summary.map_render_failed?.length || 0;

  const belowBounds = summary.below_bounds?.length || 0;
  const aboveBounds = summary.above_bounds?.length || 0;

  const numOfSitesOutOfBounds = belowBounds + aboveBounds;

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>No. with 0 panels</th>
            <th>% of sites out of bounds</th>
          </tr>
        </thead>
        <thead>
          <tr>
            <td>{numOfSitesWithZeroPanels + mapRenderFailed}</td>
            <td>
              {((numOfSitesOutOfBounds / numSitesVerified) * 100).toFixed(3)}%
            </td>
          </tr>
        </thead>
      </Table>
    </>
  );
};

export default PVSummary;
