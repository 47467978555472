import axiosConfig from "./axiosConfig";

const getOrganisationList = async (authToken) => {
  const { data } = await axiosConfig.get("/organisation", {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
  return data;
};

export default getOrganisationList;
