import { PvaData } from "@inrange/inrange-data-api-client";
import React from "react";

interface PvaTaskStatusProps {
  pvaData: PvaData;
  autoRefresh: boolean;
  timeSinceRefresh: number;
}

const PvaTaskStatus: React.FC<PvaTaskStatusProps> = ({
  pvaData,
  autoRefresh,
  timeSinceRefresh,
}) => {
  const numBuildings = pvaData.status?.num_buildings || 0;
  const completedPanelLayoutBuildings =
    pvaData.status?.num_buildings_triggered || 0;
  const completedPanelRoofObjectIntersectionBuildings =
    pvaData.status?.num_buildings_packed || 0;

  return (
    <>
      <p className="text-danger">
        {pvaData.task_metrics && (
          <>
            Last PV Design task run{" "}
            {pvaData.task_metrics.pv_design_available ? "succeeded" : "failed"}.
            Task was started at{" "}
            {pvaData.task_metrics.start_time?.replace(/\.\d+.*/, "")}.
          </>
        )}{" "}
      </p>
      <div className="text-danger">
        <ul>
          <li>
            <strong>
              {completedPanelLayoutBuildings}/{numBuildings}
            </strong>{" "}
            buildings - PV panel initial layout completed
          </li>
          <li>
            <strong>
              {completedPanelRoofObjectIntersectionBuildings}/{numBuildings}
            </strong>{" "}
            buildings - PV panel layout intersected with roof objects
          </li>
        </ul>
      </div>
      <p className="text-danger">
        {pvaData.task && (
          <>
            Currently running PV Design task, started at{" "}
            {pvaData.task.started_at?.replace(/\.\d+.*/, "")}.
            <br />
          </>
        )}
      </p>
      {autoRefresh && (
        <p className="text-danger">
          Data last updated {timeSinceRefresh} seconds ago, updating every 30
          seconds.
        </p>
      )}
    </>
  );
};

export default PvaTaskStatus;
