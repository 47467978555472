import axiosConfig from "./axiosConfig";

const getBuildingsInRegionList = async (
  authToken,
  north,
  south,
  west,
  east,
  testOrgId
) => {
  const { data } = await axiosConfig.get(
    `/building/${north}/${south}/${east}/${west}${
      testOrgId ? `?testOrgId=${testOrgId}` : ""
    }`,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  );
  return data;
};

export default getBuildingsInRegionList;
