import React from "react";

import { KindeOrg } from "@inrange/building-manager-api-client/models-organisation";
import styled from "styled-components";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import LeasedSitesInActive from "../images/icons/LeasedSitesInActive.svg";
import OperationalSitesInActive from "../images/icons/OperationalSitesInActive.svg";
import OwnedSitesInActive from "../images/icons/OwnedSitesInActive.svg";
import MapIcon from "../images/icons/map.svg";

interface SidebarWithHeaderProps {
  children: React.ReactNode;
  crumbs: string[];
  currentUrl: string;
  userEmail: string;
  rootPath: string;
  orgType: string;
  siteCounts: Record<string, number>;
  onClickLogout: () => void;
  onOrgSwitch: (id: string) => void;
  userOrgs: KindeOrg[];
  currentKindeOrg: string;
  score?: number;
  siteStatus?: string;
  siteOperationalDateEpochSeconds?: number;
}

const SidebarWithHeader: React.FC<SidebarWithHeaderProps> = ({
  children,
  crumbs,
  currentUrl,
  userEmail,
  rootPath,
  orgType,
  siteCounts,
  onClickLogout,
  onOrgSwitch,
  userOrgs,
  currentKindeOrg,
  score,
  siteStatus,
  siteOperationalDateEpochSeconds,
}) => {
  const ownerOccupierCount = siteCounts.ownerOccupier || 0;
  const tenantCount = siteCounts.tenant || 0;
  const operationalCount = siteCounts.operational || 0;

  const isTenantOnly = tenantCount > 0 && ownerOccupierCount === 0;
  const isOwnerOccupierOnly = tenantCount === 0 && ownerOccupierCount > 0;

  const marketplaceLink = {
    label: "Marketplace",
    icon: MapIcon,
    url: `${rootPath}marketplace`,
  };

  const ownedSites = {
    label: isOwnerOccupierOnly ? "Sites" : "Owned Sites",
    icon: OwnedSitesInActive,
    url: isOwnerOccupierOnly
      ? `${rootPath}dashboard`
      : `${rootPath}sites/ownerOccupier`,
  };

  const landlordSites = {
    label: "Sites",
    icon: OwnedSitesInActive,
    url: `${rootPath}dashboard`,
  };

  const leasedSites = {
    label: isTenantOnly ? "Sites" : "Leased Sites",
    icon: LeasedSitesInActive,
    url: isTenantOnly ? `${rootPath}dashboard` : `${rootPath}sites/tenant`,
  };

  const operationalSites = {
    label: "Operational Sites",
    icon: OperationalSitesInActive,
    url: `${rootPath}sites/operational`,
  };

  const tenantSiteLinks: {
    label: string;
    icon: string;
    url: string;
  }[] = [];

  if (ownerOccupierCount > 0) tenantSiteLinks.push(ownedSites);
  if (tenantCount > 0) tenantSiteLinks.push(leasedSites);
  if (operationalCount > 0) tenantSiteLinks.push(operationalSites);

  const tenantSidebarLinks = [...tenantSiteLinks, marketplaceLink];

  const landlordSidebarLinks = [landlordSites, marketplaceLink];

  const sidebarLinks =
    orgType === "Tenant" ? tenantSidebarLinks : landlordSidebarLinks;

  return (
    <Container>
      <FixedSidebar>
        <Sidebar
          currentUrl={currentUrl}
          links={sidebarLinks}
          onClickLogout={onClickLogout}
          onOrgSwitch={onOrgSwitch}
          userOrgs={userOrgs}
          currentKindeOrg={currentKindeOrg}
        />
      </FixedSidebar>
      <MainContainer>
        <Header
          crumbs={crumbs}
          userEmail={userEmail}
          rootPath={rootPath}
          score={score}
          siteStatus={siteStatus}
          siteOperationalDateEpochSeconds={siteOperationalDateEpochSeconds}
        />
        <ChildContainer>{children}</ChildContainer>
      </MainContainer>
    </Container>
  );
};

const Container = styled.div`
  padding: 0;
  margin: 0;
  background: ${({ theme }) => theme.colors.grey100};
  height: 100vh;
`;

const MainContainer = styled.div`
  background: ${({ theme }) => theme.colors.grey100};
  margin: 0 0 0 100px;
  padding: 0 0 50px 0;
`;

const ChildContainer = styled.div`
  border-top: 5px solid ${({ theme }) => theme.colors.grey100};
  margin: 0;
  padding: 0;
`;

export default SidebarWithHeader;

const FixedSidebar = styled.div`
  background: white;
  border-right: 5px solid ${({ theme }) => theme.colors.grey100};
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  overflow-x: hidden;
`;
