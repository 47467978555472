import { SiteOwnership } from "@inrange/building-manager-api-client/models-site";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import RadioBox from "../RadioBox";
import SimpleButton from "../buttons/SimpleButton";
import Dropdown from "../input/Dropdown";
import InputContainer from "../input/InputContainer";
import { Row } from "../layout/Flex";
import "./addSite.css";

export interface AddSiteFormValidity {
  building: boolean;
  name: boolean;
  addressSearched: boolean;
}

export interface NewSiteValues {
  isNewOrg?: boolean;
  orgType?: string;
  orgName?: string;
  generator?: boolean;
  sitePpaLength?: number;
  siteTenantAnnualDemandKwh?: number;
}

interface AddSiteFormProps {
  siteName: string;
  isLandlord: boolean;
  orgId: string;
  orgName: string;
  relatedOrgList: any[];
  isCalculating: boolean;
  setSiteNameAndCrumb: (siteName: string, relatedOrgName?: string) => void;
  handleAddSite: (
    siteOwnerships: SiteOwnership[],
    siteValues: NewSiteValues
  ) => void;
  validSiteName: boolean;
  showValidationErrors: boolean;
  defaultDemand: number;
  siteCreating: boolean;
  orgDropdownLoading: boolean;
  isLoading: boolean;
}

const AddSiteForm: React.FC<AddSiteFormProps> = ({
  siteName,
  isLandlord,
  orgId,
  orgName,
  relatedOrgList,
  isCalculating,
  setSiteNameAndCrumb,
  handleAddSite,
  validSiteName,
  showValidationErrors,
  defaultDemand,
  siteCreating,
  orgDropdownLoading,
  isLoading,
}) => {
  return (
    <>
      <FormHeader>Give your site a name.</FormHeader>
      <InputContainer $width={"100%"}>
        <FormInput
          $ellipsis={true}
          type="text"
          value={siteName}
          onChange={(e) => setSiteNameAndCrumb(e.target.value)}
          data-testid="addsite-name"
        ></FormInput>
      </InputContainer>
      {showValidationErrors && !validSiteName && (
        <SiteNameError>
          Provide a name for your site before adding.
        </SiteNameError>
      )}
      <Form
        isLandlord={isLandlord}
        orgId={orgId}
        orgName={orgName}
        relatedOrgList={relatedOrgList}
        isCalculating={isCalculating}
        handleAddSite={handleAddSite}
        defaultDemand={defaultDemand}
        siteCreating={siteCreating}
        orgDropdownLoading={orgDropdownLoading}
        siteName={siteName}
        setSiteNameAndCrumb={setSiteNameAndCrumb}
        isLoading={isLoading}
      />
    </>
  );
};

export default AddSiteForm;

interface FormProps {
  isLandlord: boolean;
  orgId: string;
  orgName: string;
  relatedOrgList: any[];
  isCalculating: boolean;
  handleAddSite: (
    siteOwnerships: SiteOwnership[],
    siteValues: NewSiteValues
  ) => void;
  defaultDemand: number;
  siteCreating: boolean;
  orgDropdownLoading: boolean;
  siteName: string;
  setSiteNameAndCrumb: (siteName: string, relatedOrgName?: string) => void;
  isLoading: boolean;
}

const Form: React.FC<FormProps> = ({
  isLandlord,
  orgId,
  orgName,
  relatedOrgList,
  isCalculating,
  handleAddSite,
  defaultDemand,
  siteCreating,
  orgDropdownLoading,
  siteName,
  setSiteNameAndCrumb,
  isLoading,
}) => {
  const navigate = useNavigate();
  const [ownerOccupier, setOwnerOccupier] = useState<boolean>(false);
  const [isSiteGenerating, setGenerator] = useState<boolean>(true);
  const [isNewOrg, setIsNewOrg] = useState<boolean>(false);
  const [org, setOrg] = useState<{ orgID: string; ownership: string }>({
    orgID: orgId,
    ownership: isLandlord ? "landlord" : "tenant",
  });
  const [relatedOrg, setRelatedOrg] = useState<{
    orgID: string;
    ownership: string;
  }>({
    orgID: "",
    ownership: isLandlord ? "tenant" : "landlord",
  });
  const [relatedOrgName, setRelatedOrgName] = useState<string>("");
  const [newOrgName, setNewOrgName] = useState<string>("");
  const [procurerValues, setProcurerValues] = useState<{
    tenantAnnualDemandKwh: number;
    ppaLength: number;
  }>({
    tenantAnnualDemandKwh: 0,
    ppaLength: 10,
  });

  const setOrgOwnershipType = (ownership: string) => {
    setOrg({ orgID: orgId, ownership });
  };

  const onGeneratorChange = (isGenerator: boolean) => {
    const ownershipType = isLandlord ? "landlord" : "tenant";
    if (isGenerator) {
      setIsNewOrg(relatedOrg.orgID === "other" && !ownerOccupier);
      setProcurerValues({
        tenantAnnualDemandKwh: 0,
        ppaLength: 10,
        // default values for generator
      });
      setOrgOwnershipType(ownerOccupier ? "ownerOccupier" : ownershipType);
    } else {
      setIsNewOrg(false);
      setOrgOwnershipType(ownershipType);
      setProcurerValues((prevProcurerValues) => {
        return { ...prevProcurerValues, installedCapacity: 0 }; // Set installed capacity to 0 for non-generator
      });
    }
    setGenerator(isGenerator);
  };

  const onRelatedOrg = (
    relatedOrg: { orgID: string; ownership: string },
    relatedOrgName: string
  ) => {
    const isNewOrg = relatedOrg.orgID === "other";
    const newRelatedOrgName = isNewOrg ? "" : relatedOrgName;
    setIsNewOrg(isNewOrg);
    setRelatedOrg(relatedOrg);
    setRelatedOrgName(relatedOrgName);
    setNewOrgName("");
    setSiteNameAndCrumb(siteName, newRelatedOrgName);
  };

  const createSiteOwnerships = () => {
    if (isNewOrg || ownerOccupier || !isSiteGenerating) {
      return [org];
    }

    if (relatedOrg.orgID) {
      return [
        org,
        { orgID: relatedOrg.orgID, ownership: relatedOrg.ownership },
      ];
    }

    return [org];
  };

  const siteOwnerships = createSiteOwnerships();

  return (
    <>
      <FormHeader>
        Will this site generate energy or only procure it?
      </FormHeader>
      <RadioBox
        usageProfileOptions={[
          "Generate & procure (solar installed)",
          "Procure only (no install)",
        ]}
        selectedOption={
          isSiteGenerating
            ? "Generate & procure (solar installed)"
            : "Procure only (no install)"
        }
        onOptionChange={() => onGeneratorChange(!isSiteGenerating)}
        testid="addsite-generator"
      />
      {isSiteGenerating ? (
        <GeneratorForm
          isLandlord={isLandlord}
          setOrgOwnershipType={setOrgOwnershipType}
          relatedOrg={relatedOrg}
          relatedOrgList={relatedOrgList}
          ownerOccupier={ownerOccupier}
          setOwnerOccupier={setOwnerOccupier}
          onRelatedOrg={onRelatedOrg}
          isNewOrg={isNewOrg}
          setIsNewOrg={setIsNewOrg}
          newOrgName={newOrgName}
          setNewOrgName={setNewOrgName}
          orgDropdownLoading={orgDropdownLoading}
          siteName={siteName}
          setSiteNameAndCrumb={setSiteNameAndCrumb}
        />
      ) : (
        <ProcurerForm
          defaultDemand={defaultDemand}
          isCalculating={isCalculating}
          procurerValues={procurerValues}
          setProcurerValues={setProcurerValues}
        />
      )}
      <Row
        $gap={"10px"}
        $justifyContent={"left"}
        $margin={"0px"}
        $padding={"0px"}
        style={{ marginTop: 20 }}
      >
        <SimpleButton
          label={"Add site"}
          fontWeight="400"
          onClick={() => {
            const currentRelatedOrgName = ownerOccupier
              ? orgName
              : isNewOrg
                ? newOrgName
                : relatedOrgName;
            handleAddSite(siteOwnerships, {
              isNewOrg,
              generator: isSiteGenerating,
              orgName: currentRelatedOrgName,
              orgType: relatedOrg.ownership,
              sitePpaLength: procurerValues.ppaLength,
              siteTenantAnnualDemandKwh: procurerValues.tenantAnnualDemandKwh,
            });
          }}
          disabled={siteCreating || isLoading}
          style={{
            paddingLeft: 40,
            paddingRight: 40,
            height: 36,
          }}
          data-testid="addsite-button"
        />
        <SimpleButton
          label={"Cancel"}
          background={"#ffffff"}
          fontWeight="400"
          onClick={() => navigate(-1)}
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            height: 36,
            color: "#00022F",
          }}
        />
      </Row>
    </>
  );
};

interface GeneratorFormProps {
  isLandlord: boolean;
  setOrgOwnershipType: (ownership: string) => void;
  relatedOrg: { orgID: string; ownership: string };
  relatedOrgList: any[];
  ownerOccupier: boolean;
  setOwnerOccupier: (ownerOccupier: boolean) => void;
  isNewOrg: boolean;
  setIsNewOrg: (isNewOrg: boolean) => void;
  onRelatedOrg: (
    relatedOrg: { orgID: string; ownership: string },
    relatedOrgName: string
  ) => void;
  newOrgName: string;
  setNewOrgName: (newOrgName: string) => void;
  orgDropdownLoading: boolean;
  siteName: string;
  setSiteNameAndCrumb: (name: string, crumb?: string) => void;
}

const GeneratorForm: React.FC<GeneratorFormProps> = ({
  isLandlord,
  setOrgOwnershipType,
  relatedOrg,
  relatedOrgList,
  ownerOccupier,
  setOwnerOccupier,
  isNewOrg,
  setIsNewOrg,
  onRelatedOrg,
  newOrgName,
  setNewOrgName,
  orgDropdownLoading,
  siteName,
  setSiteNameAndCrumb,
}) => {
  const radioOptions = isLandlord
    ? ["I'm the owner", "I'm the owner and the on-site tenant"]
    : ["I'm the on-site tenant", "I'm the on-site tenant and I own it"];
  const selectedOption = !ownerOccupier ? radioOptions[0] : radioOptions[1];

  const organisationOptions = relatedOrgList.map((org, index) => (
    <option key={index} value={org.orgID}>
      {org.name}
    </option>
  ));

  const relationshipOwnership = isLandlord ? "tenant" : "landlord";

  const onOwnershipChange = (isOwnerOccupier: boolean) => {
    setOwnerOccupier(isOwnerOccupier);
    if (isOwnerOccupier) {
      setOrgOwnershipType("ownerOccupier");
      setIsNewOrg(false);
    } else {
      setIsNewOrg(relatedOrg.orgID === "other");
      setOrgOwnershipType(isLandlord ? "landlord" : "tenant");
    }
  };

  return (
    <>
      <FormHeader>{"What's"} your relationship to this site?</FormHeader>
      <RadioBox
        usageProfileOptions={radioOptions}
        selectedOption={selectedOption}
        onOptionChange={() => onOwnershipChange(!ownerOccupier)}
        testid="addsite-relationship"
      />
      {!ownerOccupier && (
        <>
          <FormHeader>
            Who is the {isLandlord ? "on-site tenant" : "landlord"}?
          </FormHeader>
          <InputContainer $width={"100%"}>
            <Dropdown
              value={relatedOrg.orgID ?? ""}
              disabled={orgDropdownLoading}
              onChange={(e) => {
                const selectElement = e.target;
                const selectedOptionText =
                  selectElement.options[selectElement.selectedIndex].text;
                onRelatedOrg(
                  {
                    orgID: e.target.value,
                    ownership: relationshipOwnership,
                  },
                  selectedOptionText
                );
              }}
              data-testid="addsite-related-org-dropdown"
            >
              <option value={""} disabled>
                {orgDropdownLoading
                  ? "Loading..."
                  : `Select the ${relationshipOwnership}`}
              </option>
              {organisationOptions}
              <option
                value={"other"}
              >{`Add a ${relationshipOwnership}`}</option>
            </Dropdown>
          </InputContainer>
          {isNewOrg && (
            <InputContainer $width={"100%"}>
              <FormInput
                value={newOrgName}
                placeholder={`Enter the ${relationshipOwnership}'s name`}
                onChange={(e) => {
                  setNewOrgName(e.target.value);
                  setSiteNameAndCrumb(siteName, e.target.value);
                }}
              />
            </InputContainer>
          )}
        </>
      )}
    </>
  );
};

interface ProcurerFormProps {
  defaultDemand: number;
  isCalculating: boolean;
  procurerValues: { tenantAnnualDemandKwh: number; ppaLength: number };
  setProcurerValues: React.Dispatch<
    React.SetStateAction<{ tenantAnnualDemandKwh: number; ppaLength: number }>
  >;
}

const ProcurerForm: React.FC<ProcurerFormProps> = ({
  defaultDemand,
  isCalculating,
  procurerValues,
  setProcurerValues,
}) => {
  useEffect(() => {
    setProcurerValues((prevProcurerValues) => {
      return { ...prevProcurerValues, tenantAnnualDemandKwh: defaultDemand };
    });
  }, [defaultDemand, setProcurerValues]);

  const onProcurerChange = (
    changeValue: Partial<{ tenantAnnualDemandKwh: number; ppaLength: number }>
  ) => {
    setProcurerValues((prevProcurerValues) => {
      return { ...prevProcurerValues, ...changeValue };
    });
  };

  return (
    <>
      <FormHeader>
        How much energy would you want to procure? (optional)
      </FormHeader>
      <Row
        $gap={"10px"}
        $width={"100%"}
        $margin={"0px"}
        $padding={"0px"}
        $alignItems={"center"}
      >
        <InputContainer $width={"45%"}>
          {isCalculating ? (
            <span className="dots" /* see addSite.css for this class */>
              Calculating
              <span>.</span>
              <span>.</span>
              <span>.</span>
            </span>
          ) : (
            <FormInput
              value={procurerValues.tenantAnnualDemandKwh}
              onChange={(e) =>
                onProcurerChange({
                  tenantAnnualDemandKwh: parseFloat(e.target.value),
                })
              }
              type="number"
              min={1}
            ></FormInput>
          )}
        </InputContainer>
        <InputDescription>kWh/year</InputDescription>
      </Row>
      <FormHeader>
        What would be your preferred PPA length? (optional)
      </FormHeader>
      <Row
        $gap={"10px"}
        $width={"100%"}
        $margin={"0px"}
        $padding={"0px"}
        $alignItems={"center"}
      >
        <InputContainer $width={"45%"}>
          <FormInput
            value={procurerValues.ppaLength}
            min={1}
            type="number"
            step={1}
            onChange={(e) =>
              onProcurerChange({ ppaLength: parseFloat(e.target.value) })
            }
          ></FormInput>
        </InputContainer>
        <InputDescription>years</InputDescription>
      </Row>
    </>
  );
};

const FormHeader = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.grey700};
  margin-top: 20px;
`;

const InputDescription = styled.span`
  font-size: 14px;
  line-height: 13px;
  margin-bottom: 3px;
`;

const FormInput = styled.input<{ $ellipsis?: boolean }>`
  width: 100%;
  height: 24px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 14px;
  line-height: 24px;
  padding: 0px;
  border: none;
  text-overflow: ${(props) => (props.$ellipsis ? "ellipsis" : "clip")};
  ::-webkit-search-decoration,
  ::-webkit-search-cancel-button,
  ::-webkit-search-results-button,
  ::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
`;

const SiteNameError = styled.span`
  color: ${({ theme }) => theme.colors.redSolid};
  font-size: 14px;
  margin-left: 8px;
`;
