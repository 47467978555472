import React from "react";
import styled from "styled-components";
import TabButtons, { TabButtonProps } from "./buttons/TabButtons";

interface TableListToggleButtonsProps {
  tabs: {
    tab1: TabButtonProps;
    tab2?: TabButtonProps;
    tab3: TabButtonProps;
  };
  paddingLeft?: string;
}

const TableListToggleButtons: React.FC<TableListToggleButtonsProps> = ({
  tabs,
  paddingLeft,
}) => {
  return (
    <TableListToggleButtonsContainer $paddingLeft={paddingLeft}>
      <TabButtons tab1={tabs.tab1} tab2={tabs.tab2} tab3={tabs.tab3} />
    </TableListToggleButtonsContainer>
  );
};

export default TableListToggleButtons;

const TableListToggleButtonsContainer = styled.div<{ $paddingLeft?: string }>`
  display: flex;
  flex-grow: 1;
  padding-left: ${({ $paddingLeft }) => $paddingLeft || "12px"};
`;
