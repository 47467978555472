import { ReactNode, useEffect, useMemo, useRef, useState } from "react";

interface NonOverflowTooltipProps {
  point: { x: number; y: number };
  containerSize: { width: number; height: number };
  children: ReactNode;
}

export function NonOverflowTooltip({
  point,
  containerSize,
  children,
}: NonOverflowTooltipProps) {
  const ref = useRef<HTMLDivElement | null>(null);
  const [tooltipSize, setTooltipSize] = useState({ width: 0, height: 0 });

  // dynamically get the size of the tooltip
  useEffect(() => {
    const tooltip = ref.current;
    if (tooltip) {
      const { width, height } = tooltip.getBoundingClientRect();
      setTooltipSize({ width, height });
    }
  }, [setTooltipSize]);

  const offsetHorizontal = useMemo(() => {
    // only show it to the right of the pointer when we are close to the left edge
    if (point.x < tooltipSize.width) {
      return tooltipSize.width / 3;
    }

    // only show it to the left of the pointer when we are close to the right edge
    const rightEdge = containerSize.width - point.x;
    if (rightEdge < tooltipSize.width) {
      return -(tooltipSize.width / 3);
    }

    return 0;
  }, [tooltipSize, point.x, containerSize.width]);

  const offsetVertical = useMemo(() => {
    // only show it above the pointer when we are close to the bottom edge
    if (point.y > containerSize.height - tooltipSize.height) {
      return -tooltipSize.height;
    }

    const bottomEdge = containerSize.height - point.y;
    if (bottomEdge < tooltipSize.height) {
      return -tooltipSize.height;
    }

    return 0;
  }, [tooltipSize, point.y, containerSize.height]);

  return (
    <div
      ref={ref}
      style={{
        position: "relative",
        left: offsetHorizontal,
        right: 0,
        top: offsetVertical,
        bottom: 0,
      }}
    >
      {children}
    </div>
  );
}
