import { DateTime } from "luxon";

function formatDateTimeFromSeconds(seconds) {
  if (!seconds) {
    return "";
  }
  const dateTime = DateTime.fromSeconds(seconds);
  return dateTime.toLocaleString(DateTime.DATETIME_FULL);
}

export default formatDateTimeFromSeconds;
