import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useMutation } from "react-query";
import getSiteFileUploadUrl from "../http/getSiteFileUploadUrl";
import putSiteFile from "../http/putSiteFile";

const useSiteFileUpload = (siteId) => {
  const { getToken } = useKindeAuth();

  const uploadSiteFile = useMutation(async ({ file, siteFile }) => {
    const { name, type: contentType } = file;
    const fetchSiteFileUploadUrl = await getSiteFileUploadUrl(
      await getToken(),
      siteId,
      siteFile.fileType,
      contentType,
      name
    );
    const siteFileUploadUrl = fetchSiteFileUploadUrl.url;
    await putSiteFile(siteFileUploadUrl, contentType, file);
    return { ...siteFile, fileGuid: fetchSiteFileUploadUrl.fileGuid };
  });

  return {
    uploadSiteFile,
  };
};

export default useSiteFileUpload;
