import toNearestTen from "./toNearestTen";

/*
  formatUnits(1000) => "1,000 W"
  formatUnits(100, "kWh") => "100 kWh"
  formatUnits(1000, "Wh") => "1,000 Wh"
  formatUnits(1000000, "kWh") => "1,111,111 kWh"
*/
export const formatUnits = (
  value: number | null | undefined,
  unit: string = "W",
  fixedDecimalPlaces?: number
): string => {
  if (value === null || value === undefined) return `0 ${unit}`;
  const formattedNumber = new Intl.NumberFormat("en-GB", {
    style: "decimal",
    minimumFractionDigits: fixedDecimalPlaces,
    maximumFractionDigits: fixedDecimalPlaces,
  }).format(value);
  return `${formattedNumber} ${unit}`;
};

/*
  formatUnitsToNearestTen(1000) => "1,000 W"
  formatUnitsToNearestTen(1234, "kWh") => "1,230 kWh"
  formatUnitsToNearestTen(1000000, "Wh") => "1,000,000 Wh"
  formatUnitsToNearestTen(1234567, "kWh") => "1,234,570 Wh"
*/
export const formatUnitsToNearestTen = (
  value: number | null | undefined | string,
  unit: string = "W",
  fixedDecimalPlaces?: number
): string => {
  if (value === null || value === undefined) return `0 ${unit}`;
  return formatUnits(
    toNearestTen(typeof value === "string" ? parseFloat(value) : value),
    unit,
    fixedDecimalPlaces
  );
};

/*
  formatKiloUnitsWithValue(1) => "1 kW"
  formatKiloUnitsWithValue(1, "Wh") => "1 kWh"
  formatKiloUnitsWithValue(100, "Wh") => "100 kWh"
  formatKiloUnitsWithValue(1000, "Wh") => "1 MWh"
  formatKiloUnitsWithValue(1100, "Wh") => "1.1 MWh"
  formatKiloUnitsWithValue(1234, "Wh", 3) => "1.234 MWh"
*/
export const formatKiloUnitsWithValue = (
  kiloValue: number | null | undefined,
  baseUnit: string = "W",
  largeUnitDecimalPlaces: number = 1
): string => {
  if (kiloValue === null || kiloValue === undefined) return `0 ${baseUnit}`;

  let returnValue = kiloValue;
  let steps = 0;
  while (returnValue >= 1000 && steps < 3) {
    returnValue = returnValue / 1000;
    steps += 1;
  }

  const units = ["k", "M", "G", "T"];
  const returnValueStr = returnValue
    .toFixed(steps > 0 ? largeUnitDecimalPlaces : 1)
    .replace(/\.0$/, "");

  return formatUnits(parseFloat(returnValueStr), units[steps] + baseUnit);
};

/*
  formatKiloUnitsOnly(1) => "kW"
  formatKiloUnitsOnly(1, "Wh") => "kWh"
  formatKiloUnitsOnly(100, "Wh") => "kWh"
  formatKiloUnitsOnly(1100, "Wh") => "MWh"
  formatKiloUnitsOnly(1000000, "Wh") => "GWh"
*/
export const formatKiloUnitsOnly = (
  kiloValue: number | null | undefined,
  baseUnit: string = "W"
): string => {
  if (kiloValue === null || kiloValue === undefined) return `${baseUnit}`;

  let returnValue = kiloValue;
  let steps = 0;
  while (returnValue >= 1000 && steps < 3) {
    returnValue = returnValue / 1000;
    steps += 1;
  }

  const units = ["k", "M", "G", "T"];

  return `${units[steps]}${baseUnit}`;
};
