/* eslint-disable react-refresh/only-export-components */
import { Building } from "@inrange/building-manager-api-client/models-site";
import "leaflet-editable";
import "leaflet.path.drag";
import "leaflet/dist/leaflet.css";
import React from "react";
import { GeoJSON } from "react-leaflet";
import { outlineGreenStyle } from "./pvcustomobjectmap-utils";

interface GeoBuildingsProps {
  buildings: Building[];
}

export const GeoBuildings: React.FC<GeoBuildingsProps> = ({ buildings }) => {
  if (!buildings) {
    return null;
  }

  return Object.values(buildings).map((building, index) => {
    const data = { ...building.geometry };

    if (typeof data.coordinates === "string") {
      data.coordinates = JSON.parse(data.coordinates);
    }

    return (
      <GeoJSON
        key={"building-" + index}
        data={data}
        style={outlineGreenStyle}
      />
    );
  });
};
