import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

const Tariff = ({ site, setSite, siteCalculations, errors }) => {
  return (
    <Card body className="mt-2">
      <Form.Label>
        <strong>Tariff</strong>
      </Form.Label>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="marketTariff">
            <Form.Label>Market tariff</Form.Label>
            <Form.Control
              type="text"
              value={site.marketTariff}
              onChange={(e) => setSite({ marketTariff: e.target.value })}
              isInvalid={errors["marketTariff"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["marketTariff"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="tenantTariff">
            <Form.Label>Tenant tariff</Form.Label>
            <Form.Control
              type="text"
              value={site.tenantTariff}
              onChange={(e) => setSite({ tenantTariff: e.target.value })}
              isInvalid={errors["tenantTariff"]}
              data-testid={"tenant-tariff"}
            />
            <Form.Control.Feedback type="invalid">
              {errors["tenantTariff"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="spillExportTariff">
            <Form.Label>Spill export tariff</Form.Label>
            <Form.Control
              type="text"
              value={site.inrangeExportTariff}
              onChange={(e) => setSite({ inrangeExportTariff: e.target.value })}
              isInvalid={errors["inrangeExportTariff"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["inrangeExportTariff"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="blendedExportTariff">
            <Form.Label>Blended export tariff</Form.Label>
            <Form.Control
              type="text"
              value={siteCalculations.blendedExportTariff || 0}
              disabled
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="leaseRevenueShare">
            <Form.Label>Lease revenue share</Form.Label>
            <Form.Control
              type="text"
              value={site.landlordLeaseRevenueShare}
              onChange={(e) =>
                setSite({
                  landlordLeaseRevenueShare: e.target.value,
                })
              }
              isInvalid={errors["landlordLeaseRevenueShare"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["landlordLeaseRevenueShare"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="licenseRevenueShare">
            <Form.Label>License revenue share</Form.Label>
            <Form.Control
              type="text"
              value={site.landlordLicenseRevenueShare}
              onChange={(e) =>
                setSite({
                  landlordLicenseRevenueShare: e.target.value,
                })
              }
              isInvalid={errors["landlordLicenseRevenueShare"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["landlordLicenseRevenueShare"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="ooLicenseRevenueShare">
            <Form.Label>Owner occupier license revenue share</Form.Label>
            <Form.Control
              type="text"
              value={site.ownerOccupierLicenseRevenueShare}
              onChange={(e) =>
                setSite({
                  ownerOccupierLicenseRevenueShare: e.target.value,
                })
              }
              isInvalid={errors["ownerOccupierLicenseRevenueShare"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["ownerOccupierLicenseRevenueShare"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="ooLicenseSaasTariff">
            <Form.Label>OwnOcc license saas tariff rate</Form.Label>
            <Form.Control
              type="text"
              value={site.ownerOccupierLicenseInRangeSaasTariffRate}
              onChange={(e) =>
                setSite({
                  ownerOccupierLicenseInRangeSaasTariffRate: e.target.value,
                })
              }
              isInvalid={errors["ownerOccupierLicenseInRangeSaasTariffRate"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["ownerOccupierLicenseInRangeSaasTariffRate"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
    </Card>
  );
};

export default Tariff;
