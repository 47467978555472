import axiosConfig from "./axiosConfig";

const getPVDataList = async (authToken) => {
  const { data } = await axiosConfig.get(`/reports/pv-design/site`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
  return data;
};

export default getPVDataList;
