import { currencySymbol } from "@inrange/theme-components/formatting";
import { useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";

const twoDecimalPlaceString = (cost) => {
  if (typeof cost === "string") {
    // String values have been entered locally, so don't format these
    return cost;
  }
  return parseFloat(cost).toFixed(2);
};

const Cost = ({ site, setSite, errors, currencyCode, initialCost }) => {
  const onBlurHandler = (e) => {
    const { value } = e.target;
    e.target.value = isNaN(value) ? value : twoDecimalPlaceString(value);
  };

  const onChangeHandler = (e) => {
    const { value, id } = e.target;
    const [costInputName, costInputKey] = id.split(".");
    setSite({
      [costInputName]: {
        ...site[costInputName],
        [costInputKey]: value,
      },
    });
  };

  return (
    <CostView
      site={site}
      errors={errors}
      currencyCode={currencyCode}
      initialCost={initialCost}
      onBlurHandler={onBlurHandler}
      onChangeHandler={onChangeHandler}
      setSite={setSite}
    />
  );
};

const CostView = ({
  site,
  errors,
  currencyCode,
  initialCost,
  onBlurHandler,
  onChangeHandler,
  setSite,
}) => {
  const [displayCostInflationRates, setDisplayCostInflationRates] =
    useState(false);

  const range = (start, end) => {
    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  };

  const costInflationRatesView = (
    <>
      <Row>
        {range(2, 25).map((year) => {
          return (
            <Col sm={3} key={year}>
              <Form.Group
                className="mb-3"
                controlId={`costInflationRates${year - 1}`}
              >
                <Form.Label>Year {year} inflation (%)</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={
                    site.costInputsEconomic.costInflationRates[year - 1]
                  }
                  onChange={(e) => {
                    const newCostInflationRates =
                      site.costInputsEconomic.costInflationRates;
                    newCostInflationRates[year - 1] = e.target.value;
                    setSite({
                      costInputsEconomic: {
                        ...site.costInputsEconomic,
                        costInflationRates: newCostInflationRates,
                      },
                    });
                  }}
                  isInvalid={
                    errors[`costInputsEconomic.costInflationRates.${year - 1}`]
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {errors[`costInputsEconomic.costInflationRates.${year - 1}`]}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          );
        })}
      </Row>
    </>
  );

  const showCostInflationRatesLink = (
    <Col style={{ paddingTop: "40px" }}>
      <a
        onClick={() => setDisplayCostInflationRates(true)}
        style={{
          cursor: "pointer",
          color: "rgb(13, 110, 253)",
        }}
      >
        Show cost inflation rates
      </a>
    </Col>
  );

  return (
    <Card body className="mt-2">
      <Form.Label>
        <strong>Costs and rates</strong>
      </Form.Label>
      <Row>
        <Col sm={3}>
          <Form.Group className="mb-3" controlId="costInputsPv.panelCostPerKWp">
            <Form.Label>Panel cost per kWp</Form.Label>
            <Form.Control
              type="text"
              value={twoDecimalPlaceString(site.costInputsPv.panelCostPerKWp)}
              onChange={(e) => onChangeHandler(e)}
              onBlur={(e) => onBlurHandler(e)}
              isInvalid={errors["costInputsPv.panelCostPerKWp"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputsPv.panelCostPerKWp"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group
            className="mb-3"
            controlId="costInputsPv.mountingCostPerKWp"
          >
            <Form.Label>Mount. cost per kWp</Form.Label>
            <Form.Control
              type="text"
              value={twoDecimalPlaceString(
                site.costInputsPv.mountingCostPerKWp
              )}
              onChange={(e) => onChangeHandler(e)}
              onBlur={(e) => onBlurHandler(e)}
              isInvalid={errors["costInputsPv.mountingCostPerKWp"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputsPv.mountingCostPerKWp"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group
            className="mb-3"
            controlId="costInputsPv.inverterCostPerKWp"
          >
            <Form.Label>Inverter cost per kWp</Form.Label>
            <Form.Control
              type="text"
              value={twoDecimalPlaceString(
                site.costInputsPv.inverterCostPerKWp
              )}
              onChange={(e) => onChangeHandler(e)}
              onBlur={(e) => onBlurHandler(e)}
              isInvalid={errors["costInputsPv.inverterCostPerKWp"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputsPv.inverterCostPerKWp"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group
            className="mb-3"
            controlId="costInputsPv.electricalCostPerKWp"
          >
            <Form.Label>Elec. cost per kWp</Form.Label>
            <Form.Control
              type="text"
              value={twoDecimalPlaceString(
                site.costInputsPv.electricalCostPerKWp
              )}
              onChange={(e) => onChangeHandler(e)}
              onBlur={(e) => onBlurHandler(e)}
              isInvalid={errors["costInputsPv.electricalCostPerKWp"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputsPv.electricalCostPerKWp"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={3}>
          <Form.Group className="mb-3" controlId="costInputsPv.bosCostPerKWp">
            <Form.Label>BOS cost per kWp</Form.Label>
            <Form.Control
              type="text"
              value={twoDecimalPlaceString(site.costInputsPv.bosCostPerKWp)}
              onBlur={(e) => onBlurHandler(e)}
              onChange={(e) => onChangeHandler(e)}
              isInvalid={errors["costInputsPv.bosCostPerKWp"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputsPv.bosCostPerKWp"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group className="mb-3" controlId="costInputsPv.miscCostPerKWp">
            <Form.Label>Misc cost per kWp</Form.Label>
            <Form.Control
              type="text"
              value={twoDecimalPlaceString(site.costInputsPv.miscCostPerKWp)}
              onChange={(e) => onChangeHandler(e)}
              onBlur={(e) => onBlurHandler(e)}
              isInvalid={errors["costInputsPv.miscCostPerKWp"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputsPv.miscCostPerKWp"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group
            className="mb-3"
            controlId="costInputsPv.installationCostPerKWp"
          >
            <Form.Label>Install cost per kWp</Form.Label>
            <Form.Control
              type="text"
              value={twoDecimalPlaceString(
                site.costInputsPv.installationCostPerKWp
              )}
              onChange={(e) => onChangeHandler(e)}
              onBlur={(e) => onBlurHandler(e)}
              isInvalid={errors["costInputsPv.installationCostPerKWp"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputsPv.installationCostPerKWp"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group className="mb-3" controlId="initialCostPerKWp">
            <Form.Label>Upfront cost per kWp</Form.Label>
            <Form.Control
              disabled
              type="text"
              value={twoDecimalPlaceString(initialCost || 0)}
              onBlur={(e) => onBlurHandler(e)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={3}>
          <Form.Group
            className="mb-3"
            controlId="costInputsPv.maintenanceCostPerKWp"
          >
            <Form.Label>O&M cost per kWp</Form.Label>
            <Form.Control
              type="text"
              value={twoDecimalPlaceString(
                site.costInputsPv.maintenanceCostPerKWp
              )}
              onChange={(e) => onChangeHandler(e)}
              isInvalid={errors["costInputsPv.maintenanceCostPerKWp"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputsPv.maintenanceCostPerKWp"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group
            className="mb-3"
            controlId="costInputsPv.insuranceCostPerKWp"
          >
            <Form.Label>Insurance cost per kWp</Form.Label>
            <Form.Control
              type="text"
              value={twoDecimalPlaceString(
                site.costInputsPv.insuranceCostPerKWp
              )}
              onChange={(e) => onChangeHandler(e)}
              isInvalid={errors["costInputsPv.insuranceCostPerKWp"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputsPv.insuranceCostPerKWp"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group
            className="mb-3"
            controlId="costInputsGrid.gridConnectionCost"
          >
            <Form.Label>
              Grid connection cost ({currencySymbol(currencyCode)})
            </Form.Label>
            <Form.Control
              type="text"
              value={twoDecimalPlaceString(
                site.costInputsGrid.gridConnectionCost
              )}
              onChange={(e) => onChangeHandler(e)}
              onBlur={(e) => onBlurHandler(e)}
              isInvalid={errors["costInputsGrid.gridConnectionCost"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputsGrid.gridConnectionCost"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={3}>
          <Form.Group
            className="mb-3"
            controlId="costInputsPv.replacementCapexYear"
          >
            <Form.Label>PV replacement (years)</Form.Label>
            <Form.Control
              type="text"
              value={site.costInputsPv.replacementCapexYear}
              onChange={(e) => onChangeHandler(e)}
              onBlur={(e) => onBlurHandler(e)}
              isInvalid={errors["costInputsPv.replacementCapexYear"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputsPv.replacementCapexYear"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group
            className="mb-3"
            controlId="costInputsPv.replacementCapexRate"
          >
            <Form.Label>PV replacement rate (%)</Form.Label>
            <Form.Control
              type="text"
              value={site.costInputsPv.replacementCapexRate}
              onChange={(e) => onChangeHandler(e)}
              onBlur={(e) => onBlurHandler(e)}
              isInvalid={errors["costInputsPv.replacementCapexRate"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputsPv.replacementCapexRate"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group className="mb-3" controlId="energyPriceInflationRate">
            <Form.Label>Energy inflation (%)</Form.Label>
            <Form.Control
              type="text"
              value={site.energyPriceInflationRate}
              onChange={(e) =>
                setSite({ energyPriceInflationRate: e.target.value })
              }
              onBlur={(e) => onBlurHandler(e)}
              isInvalid={errors.energyPriceInflationRate}
            />
            <Form.Control.Feedback type="invalid">
              {errors.energyPriceInflationRate}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        {!displayCostInflationRates && showCostInflationRatesLink}
      </Row>
      {displayCostInflationRates && costInflationRatesView}
    </Card>
  );
};

export default Cost;
