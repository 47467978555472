import toNearestTen from "./toNearestTen";

/*
  formatCurrency(1000) => "£1,000"
  formatCurrency(1000, "USD") => "$1,000"
  formatCurrency(1000, "GBP", 2) => "£1,000.00"
  formatCurrency(123_456) => "£123,000"
  formatCurrency(1_000_000, "GBP", 0, true) => "£1 million"
  formatCurrency(1_234_567, "GBP", 1, true) => "£1.2 million"
*/
const formatCurrency = (
  value: number,
  currency: string = "GBP",
  maximumFractionDigits: number = 0,
  abbreviate: boolean = true,
  showPlusMinus: boolean = false
): string => {
  let suffix = "";

  if (abbreviate) {
    if (value > 100_000) {
      value = Math.round(value / 1000) * 1000;
    }

    if (value > 1_000_000) {
      value = value / 1_000_000;
      suffix = " million";
      maximumFractionDigits = maximumFractionDigits ?? 1;
    }
  }

  const currencyFormatter = new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency,
    minimumFractionDigits: maximumFractionDigits,
    maximumFractionDigits,
  });

  const valuePrefix = value < 0 ? "-" : value > 0 ? "+" : "";
  return (
    (showPlusMinus ? valuePrefix : "") +
    currencyFormatter.format(value) +
    suffix
  );
};

export const formatCurrencyToNearestTen = (
  value: number,
  currency: string = "GBP",
  maximumFractionDigits: number = 0,
  abbreviate: boolean = true
): string => {
  return formatCurrency(
    toNearestTen(value),
    currency,
    maximumFractionDigits,
    abbreviate
  );
};

export default formatCurrency;
