import { Modal, ModalView } from "@inrange/theme-components";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useNavigate } from "react-router-dom";
import Loading from "../Loading";

import {
  DEFAULT_DEMAND_COEFFICIENTS,
  DEFAULT_DEMAND_PROFILES,
  defaultSite,
  generateId,
  useSite,
} from "@inrange/building-manager-api-client";
import {
  SiteCalculationsContext,
  SiteCalculationsProvider,
  SitePreview,
} from "@inrange/shared-components";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import isSiteOwnerOccupied from "../../utils/isSiteOwnerOccupied";
import { useSetDefaultsOnCurrencyChange } from "./siteEditHooks";
import ActionButtons from "./siteSections/ActionButtons";
import Battery from "./siteSections/Battery";
import Capex from "./siteSections/Capex";
import Cost from "./siteSections/Cost";
import DealTerms from "./siteSections/DealTerms";
import Demand from "./siteSections/Demand";
import DnoNetwork from "./siteSections/DnoNetwork";
import FinancialReturns from "./siteSections/FinancialReturns";
import Generation from "./siteSections/Generation";
import SiteGeo from "./siteSections/SiteGeo/SiteGeo";
import SiteInformation from "./siteSections/SiteInformation";
import Tariff from "./siteSections/Tariff";

const SiteNew = () => {
  document.title = "New site";
  const { fetchSiteValues, createSite } = useSite({ app: "admin" });

  const params = new URL(document.location).searchParams;

  const lat = parseFloat(params.get("lat"));
  const lon = parseFloat(params.get("lon"));
  const bkey = params.get("bkey");
  const address = params.get("address");
  const buildingProfile = params.get("profile");

  // previewSite contains a complete view of the new site which is being constructed
  const [previewSite, setPreviewSite] = useState({});
  useEffect(() => {
    if (fetchSiteValues.isSuccess) {
      const newSite = defaultSite(
        fetchSiteValues.data.site,
        fetchSiteValues.data.options,
        generateId(),
        lat,
        lon,
        bkey,
        address,
        buildingProfile
      );
      setPreviewSite(newSite);
    }
  }, [
    fetchSiteValues.data,
    fetchSiteValues.isSuccess,
    lat,
    lon,
    bkey,
    address,
    buildingProfile,
  ]);

  // Save related state
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [hasUnsavedBuildings, setHasUnsavedBuildings] = useState(false);

  // Site bools
  const [isOwnerOccupied, setIsOwnerOccupied] = useState(false);

  // Site attribute trackers
  const [newBuildings, setNewBuildings] = useState([]);
  const [newOwnerships, setNewOwnerships] = useState({});

  useEffect(() => {
    setIsOwnerOccupied(isSiteOwnerOccupied(Object.values(newOwnerships)));
  }, [newOwnerships]);

  const navigate = useNavigate();

  // useCallback ensures this function value is consistent and hence
  // can be used in a useEffect dependency array with no issues
  const setSiteState = useCallback(
    (update) => {
      setPreviewSite((prevPreviewSite) => ({ ...prevPreviewSite, ...update }));
    },
    [setPreviewSite]
  );

  if (!fetchSiteValues.isLoading && fetchSiteValues.isError) {
    return <div>Error loading the site.</div>;
  }

  if (Object.keys(previewSite).length === 0 || fetchSiteValues.isLoading) {
    return <Loading label="Loading site data..." />;
  }

  const setBuildingsForPreview = (buildings) => {
    const previewBuildings = buildings.map((b) => {
      return {
        id: b.key,
        surfaceAreaSqM: b.surface_area_sqm,
        siteID: previewSite.id,
        geometry: b.geometry,
        updatedAt: Math.floor(Date.now() / 1000),
        polygonType: b.polygonType,
        sourcePolygonType: b.sourcePolygonType,
        sourceGeometry: b.sourceGeometry,
      };
    });
    setSiteState({ buildings: previewBuildings });
    setNewBuildings(buildings);
  };

  const onBuildingProfileChange = (newProfile) => {
    setSiteState({
      buildingProfile: newProfile,
      demandCoefficientKWhPerM2: DEFAULT_DEMAND_COEFFICIENTS[newProfile],
      profileShape: DEFAULT_DEMAND_PROFILES[newProfile],
    });
  };

  const onSaveClick = () => {
    setSaveDisabled(true);

    const nowEpochSeconds = Math.floor(Date.now() / 1000);
    if (
      previewSite.commerciallyOperationalDateEpochSeconds &&
      previewSite.commerciallyOperationalDateEpochSeconds < nowEpochSeconds &&
      previewSite.operationalStatus !== "operational"
    ) {
      setShowSaveModal(true);
      return;
    }

    // remove empty mpan pairs
    if (previewSite.siteMPANs.length > 1) {
      const updatedSiteMPANs = previewSite.siteMPANs.filter(
        (mpan) => mpan.importMPAN || mpan.exportMPAN
      );
      previewSite.siteMPANs = updatedSiteMPANs.length
        ? updatedSiteMPANs
        : [{ importMPAN: null, exportMPAN: null }];
    }

    createNewSite();
  };

  const createNewSite = async () => {
    setSaveDisabled(true);
    const sitePayload = {
      ...previewSite,
      buildings: newBuildings,
    };
    const payload = { site: sitePayload };
    createSite.mutate(payload);
  };

  if (createSite.isError) {
    setSaveDisabled(false);
    console.error(`Error creating site - ${createSite.error.message}`);
  }

  if (createSite.isSuccess) {
    const response = createSite.data;
    navigate(`/site/${response.site_id}/edit`);
  }

  const setOwnerships = ({
    ownershipId,
    selectedID,
    selectedType,
    selectedName,
  }) => {
    if (selectedType === "" || !selectedID) return;
    const updatedNewOwnerships = {
      ...newOwnerships,
      [ownershipId]: {
        orgID: selectedID,
        ownership: selectedType,
        name: selectedName,
      },
    };
    setNewOwnerships(updatedNewOwnerships);

    const updatedOwnerships = Object.values(updatedNewOwnerships);
    setSiteState({ siteOwnerships: updatedOwnerships });
  };

  const setNewSiteName = (siteName, updatedOwnerships) => {
    if (!siteName && !previewSite.name) return;
    const firstLandlordName = updatedOwnerships.find(
      (ownership) => ownership.ownership === "landlord"
    )?.name;
    const firstOtherName = updatedOwnerships.find(
      (ownership) => ownership.ownership !== "landlord"
    )?.name;
    const siteNamePrefix =
      siteName.split(" \u1806")[0] || previewSite.name.split(" \u1806")[0];
    const firstLandlordSuffix = firstLandlordName
      ? ` \u1806 ${firstLandlordName}`
      : "";
    const firstOtherSuffix = firstOtherName ? ` \u1806 ${firstOtherName}` : "";
    const newSiteName = `${siteNamePrefix}${firstLandlordSuffix}${firstOtherSuffix}`;
    setSiteState({ name: newSiteName });
  };

  const handleDiscardClick = () => {
    window.location.reload();
  };

  return (
    <SiteCalculationsProvider
      site={previewSite}
      setSaveDisabled={setSaveDisabled}
      app="admin"
    >
      <SitePreview previewSite={previewSite} originalSite={{}}>
        <SiteNewView
          site={previewSite}
          setSite={setSiteState}
          saveDisabled={saveDisabled}
          hasUnsavedBuildings={hasUnsavedBuildings}
          setHasUnsavedBuildings={setHasUnsavedBuildings}
          onSaveClick={onSaveClick}
          onBuildingProfileChange={onBuildingProfileChange}
          setBuildingsForPreview={setBuildingsForPreview}
          ownerships={newOwnerships}
          setOwnerships={setOwnerships}
          handleDiscardClick={handleDiscardClick}
          siteNewURLBuildingID={previewSite.siteNewURLBuildingID}
          setNewSiteName={setNewSiteName}
          isOwnerOccupied={isOwnerOccupied}
          setSaveDisabled={setSaveDisabled}
          showSaveModal={showSaveModal}
          setShowSaveModal={setShowSaveModal}
          createNewSite={createNewSite}
        />
      </SitePreview>
    </SiteCalculationsProvider>
  );
};

export default SiteNew;

const SiteNewView = ({
  site,
  setSite,
  saveDisabled,
  hasUnsavedBuildings,
  setHasUnsavedBuildings,
  onSaveClick,
  onBuildingProfileChange,
  setBuildingsForPreview,
  ownerships,
  setOwnerships,
  handleDiscardClick,
  siteNewURLBuildingID,
  setNewSiteName,
  isOwnerOccupied,
  setSaveDisabled,
  showSaveModal,
  setShowSaveModal,
  createNewSite,
}) => {
  const { errors, siteCalculations } = useContext(SiteCalculationsContext);

  // Use a ref to the installed capacity so that the following useEffect is only triggered when
  // kwpPerSqm or totalBuildingArea changes, but that the user can still manually edit installed
  // capacity if they want to.
  //
  // We use a UseEffect rather than hooking into the setSiteBuildings click handler as this might be
  // clicked before kwpPerSqm has been loaded from the backend.
  const installedCapacityRef = useRef();
  installedCapacityRef.current = site.installedCapacity;
  const kwpPerSqm = siteCalculations.halfHourlyGeneration?.kwpPerSqm || 0;
  useEffect(() => {
    const totalArea = site.buildings
      .map((b) => b.surfaceAreaSqM)
      .reduce((sum, value) => sum + value, 0);
    const installedCapacity = totalArea * 0.35 * kwpPerSqm;
    if (installedCapacityRef.current !== installedCapacity) {
      setSite({ installedCapacity: installedCapacity });
    }
  }, [setSite, kwpPerSqm, site.buildings]);

  useSetDefaultsOnCurrencyChange(site, setSite, siteCalculations.currencyCode);

  return (
    <Container fluid style={{ padding: "12px 12px 0px 12px" }}>
      <Row>
        <Col>
          <SiteGeo
            site={site}
            setSite={setSite}
            setBuildingsForPreview={setBuildingsForPreview}
            siteNewURLBuildingID={siteNewURLBuildingID}
            ownerships={ownerships}
            setNewSiteName={setNewSiteName}
            mapHeight="600px"
            setHasUnsavedBuildings={setHasUnsavedBuildings}
          />
          <Demand
            site={site}
            setSite={setSite}
            errors={errors}
            siteCalculations={siteCalculations}
            onBuildingProfileChange={onBuildingProfileChange}
          />
          <Generation
            site={site}
            setSite={setSite}
            siteCalculations={siteCalculations}
            errors={errors}
          />
          <Battery
            site={site}
            setSite={setSite}
            siteCalculations={siteCalculations}
            errors={errors}
            currencyCode={siteCalculations.currencyCode}
            isOwnerOccupied={isOwnerOccupied}
            setSaveDisabled={setSaveDisabled}
          />
        </Col>
        <Col>
          <SiteInformation
            site={site}
            offtakerOnlySite={
              siteCalculations.energyFlowAnnual.generation === 0
            }
            setSite={setSite}
            ownerships={ownerships}
            setOwnerships={setOwnerships}
            onOwnerRemoved={() => {}}
            isNewSite={true}
            setNewSiteName={setNewSiteName}
            onBuildingProfileChange={onBuildingProfileChange}
            errors={errors}
            allowCopy={false}
          />
          <DealTerms
            site={site}
            setSite={setSite}
            leaseLengthMonths={siteCalculations.leaseLengthMonths}
            errors={errors}
          />
          <Cost
            site={site}
            setSite={setSite}
            errors={errors}
            currencyCode={siteCalculations.currencyCode}
            initialCost={siteCalculations.projectCosts?.initialCostPerKWp}
          />
          <Tariff
            site={site}
            setSite={setSite}
            siteCalculations={siteCalculations}
            errors={errors}
          />
          <Capex
            siteCalculations={siteCalculations}
            currencyCode={siteCalculations.currencyCode}
          />
          <FinancialReturns
            siteCalculations={siteCalculations}
            currencyCode={siteCalculations.currencyCode}
            isOwnerOccupied={isOwnerOccupied}
          />
          <DnoNetwork
            site={site}
            setSite={setSite}
            siteCalculations={siteCalculations}
            errors={errors}
          />
        </Col>
      </Row>
      <Row>
        <ActionButtons
          handleDeleteClick={() => {}}
          site={site}
          handleSaveClick={onSaveClick}
          saveDisabled={saveDisabled || hasUnsavedBuildings}
          handleDiscardClick={handleDiscardClick}
          errors={errors}
        />
      </Row>
      {showSaveModal ? (
        <Modal>
          <ModalView
            title="You've set an operational date in the past on a site that is not operational. Do you want to continue?"
            fontWeight="500"
            fontSize="16px"
            minHeight="450px"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "10px",
                marginTop: "30px",
              }}
            >
              <Button
                variant="secondary"
                onClick={() => {
                  setShowSaveModal(false);
                  setSaveDisabled(false);
                }}
                style={{ width: "160px" }}
              >
                No, go back
              </Button>
              <Button
                variant="success"
                onClick={() => {
                  createNewSite();
                  setShowSaveModal(false);
                }}
                style={{ width: "160px" }}
              >
                Yes, save anyway
              </Button>
            </div>
          </ModalView>
        </Modal>
      ) : null}
    </Container>
  );
};
