import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { currencyValue } from "../siteSections/utils";

const Capex = ({ siteCalculations, currencyCode }) => {
  return (
    <Card body className="mt-2">
      <Form.Label>
        <strong>Capex</strong>
      </Form.Label>
      <Card.Text className="text-secondary">
        The values marked with an asterisk (*) contribute to the hardware cost.
      </Card.Text>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Total project cost</Form.Label>
            <Form.Control
              disabled
              value={currencyValue(
                siteCalculations.projectCosts?.total,
                currencyCode
              )}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Upfront</Form.Label>
            <Form.Control
              disabled
              value={currencyValue(
                siteCalculations.projectCosts?.initialInvestment,
                currencyCode
              )}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Hardware</Form.Label>
            <Form.Control
              disabled
              value={currencyValue(
                siteCalculations.projectCosts?.hardware,
                currencyCode
              )}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Installation</Form.Label>
            <Form.Control
              disabled
              value={currencyValue(
                siteCalculations.projectCosts?.installation,
                currencyCode
              )}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Replacement</Form.Label>
            <Form.Control
              disabled
              value={currencyValue(
                siteCalculations.projectCosts?.replacementCapex,
                currencyCode
              )}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Panels *</Form.Label>
            <Form.Control
              disabled
              value={currencyValue(
                siteCalculations.projectCosts?.panel,
                currencyCode
              )}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Mounting *</Form.Label>
            <Form.Control
              disabled
              value={currencyValue(
                siteCalculations.projectCosts?.mounting,
                currencyCode
              )}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Inverters *</Form.Label>
            <Form.Control
              disabled
              value={currencyValue(
                siteCalculations.projectCosts?.inverter,
                currencyCode
              )}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Electrical *</Form.Label>
            <Form.Control
              disabled
              value={currencyValue(
                siteCalculations.projectCosts?.electrical,
                currencyCode
              )}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>BOS *</Form.Label>
            <Form.Control
              disabled
              value={currencyValue(
                siteCalculations.projectCosts?.bos,
                currencyCode
              )}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Misc *</Form.Label>
            <Form.Control
              disabled
              value={currencyValue(
                siteCalculations.projectCosts?.misc,
                currencyCode
              )}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>O&M (Y1)</Form.Label>
            <Form.Control
              disabled
              value={currencyValue(
                siteCalculations.projectCosts?.maintenanceYearOne,
                currencyCode
              )}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>O&M (Lifetime)</Form.Label>
            <Form.Control
              disabled
              value={currencyValue(
                siteCalculations.projectCosts?.maintenance,
                currencyCode
              )}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Insurance (Y1)</Form.Label>
            <Form.Control
              disabled
              value={currencyValue(
                siteCalculations.projectCosts?.insuranceYearOne,
                currencyCode
              )}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Insurance (LT)</Form.Label>
            <Form.Control
              disabled
              value={currencyValue(
                siteCalculations.projectCosts?.insurance,
                currencyCode
              )}
            />
          </Form.Group>
        </Col>
      </Row>
    </Card>
  );
};

export default Capex;
