export const DEFAULT_DEMAND_COEFFICIENTS = {
  dataCenter: 1146.521032,
  distribution: 48.32361877,
  manufacturing: 423.0662188,
  retail: 83.75,
  retailRefrigerated: 255.2293484,
  noDemand: 0,
  other: "",
};

export const DEFAULT_DEMAND_PROFILES = {
  dataCenter: "flat",
  distribution: "standard",
  manufacturing: "standard",
  retail: "standard",
  retailRefrigerated: "standard",
  noDemand: "standard",
  other: "standard",
};

const isEmptyObjectOrArray = (value) => {
  if (value !== null && typeof value === "object") {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return Object.keys(value).length === 0;
    }
  }
  return false;
};

const defaultSiteIgnoreKeys = new Set([
  "createdBy",
  "createdAt",
  "updatedAt",
  "id",
  "halfHourlyDemand",
]);

export const getDefaultsPvSystem = (
  pvSystems,
  currencyCode,
  pvInverterBrand,
  pvPanelName
) => {
  return (
    pvSystems[`${currencyCode}#${pvInverterBrand}#${pvPanelName}`] ||
    pvSystems[`${currencyCode}#${pvInverterBrand}`]
  );
};

export const getDefaultsCostInputsGrid = (costInputsGrids, currencyCode) => {
  return costInputsGrids[currencyCode];
};

export const getDefaultsTariffs = (tariffsDatas, currencyCode) => {
  return tariffsDatas[currencyCode];
};

export const defaultSite = (
  defaultSite,
  siteOptions,
  siteId,
  latitude,
  longitude,
  buildingKey,
  address,
  buildingProfile
) => {
  let name = "";
  if (address) {
    name = address.split(", ").slice(0, 3).join(", ");
  }

  const defaultSiteClean = Object.fromEntries(
    Object.entries(defaultSite).filter(
      ([key, value]) =>
        value !== null &&
        value !== "" &&
        !defaultSiteIgnoreKeys.has(key) &&
        !isEmptyObjectOrArray(value)
    )
  );

  const site = {
    ...defaultSiteClean,
    name: name,
    address: address || "",
    buildings: [],
    id: siteId,
    generationLossFactors: getDefaultsPvSystem(
      siteOptions.pvSystems,
      "GBP",
      defaultSiteClean.pvInverterBrand,
      defaultSiteClean.pvPanelName
    ).generationLossFactors,
  };

  if (latitude) {
    site.latitude = latitude;
  }
  if (longitude) {
    site.longitude = longitude;
  }
  if (buildingKey) {
    site.siteNewURLBuildingID = buildingKey;
  }
  if (buildingProfile) {
    site.buildingProfile = buildingProfile;
    site.profileShape = DEFAULT_DEMAND_PROFILES[buildingProfile];
    site.demandCoefficientKWhPerM2 =
      DEFAULT_DEMAND_COEFFICIENTS[buildingProfile];
  }
  return site;
};
