import { useSite } from "@inrange/building-manager-api-client";
import { buildSitePayload } from "@inrange/shared-components";
import { Modal, ModalView } from "@inrange/theme-components";
import { fractionalCurrencySymbol } from "@inrange/theme-components/formatting";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Loading from "../../Loading";
import SdmMatchImpactDescription from "./SdmMatchImpactDescription";
import { numString2dp } from "./utils";

const ModalWraper = ({ children }) => {
  return (
    <Modal>
      <ModalView
        title={"Apply match?"}
        fontWeight="500"
        fontSize="20px"
        titlePadding="0"
      >
        {children}
      </ModalView>
    </Modal>
  );
};

const SdmAddMatchModal = ({
  site,
  selectedAddOffer,
  setSelectedAddOffer,
  siteAddMatch,
}) => {
  const [errors, setErrors] = useState({});
  const { fetchSite: fetchSiteBuyer, previewSite: previewSiteBuyer } = useSite({
    siteId: selectedAddOffer.buyerId,
    enableNonEssentialQueries: false,
    app: "admin",
  });
  const { fetchSite: fetchSiteSeller, previewSite: previewSiteSeller } =
    useSite({
      siteId: selectedAddOffer.sellerId,
      enableNonEssentialQueries: false,
      app: "admin",
    });
  const [ppaContractType, setPpaContractType] = useState(
    selectedAddOffer.ppaContractType
  );
  const [ppaLength, setPpaLength] = useState(selectedAddOffer.ppaLength);
  const [ppaIndex, setPpaIndex] = useState(selectedAddOffer.ppaIndex);
  const [energyFlowInputsFileVersionId, setEnergyFlowInputsFileVersionId] =
    useState(selectedAddOffer.energyFlowInputsFileVersionId);

  const previewSiteBuyerRef = useRef();
  const previewSiteSellerRef = useRef();
  previewSiteBuyerRef.current = previewSiteBuyer;
  previewSiteSellerRef.current = previewSiteSeller;

  const energyFlowInputsFileVersionIdRef = useRef();
  energyFlowInputsFileVersionIdRef.current = energyFlowInputsFileVersionId;

  // Trigger preview site updates when the selected offer or PPA details change
  useEffect(() => {
    if (
      !fetchSiteBuyer.isSuccess ||
      !fetchSiteSeller.isSuccess ||
      fetchSiteBuyer.isFetching ||
      fetchSiteSeller.isFetching
    ) {
      return;
    }
    const newOffer = {
      ...selectedAddOffer,
      volume: selectedAddOffer.volume,
      ppaContractType,
      ppaLength,
      ppaIndex,
      energyFlowInputsFileVersionId: energyFlowInputsFileVersionIdRef.current,
    };
    const buyerSiteWithNewMatch = buildSitePayload({
      ...fetchSiteBuyer.data.site,
      sdmMatches: [...fetchSiteBuyer.data.site.sdmMatches, newOffer],
    });
    const sellerSiteWithNewMatch = buildSitePayload({
      ...fetchSiteSeller.data.site,
      sdmMatches: [...fetchSiteSeller.data.site.sdmMatches, newOffer],
    });

    // Debounce so we don't recalculate immediately on keypress
    const debounceWaitMillis = 300;
    const timeoutId = setTimeout(() => {
      previewSiteBuyerRef.current.mutate({ site: buyerSiteWithNewMatch });
      previewSiteSellerRef.current.mutate({ site: sellerSiteWithNewMatch });
    }, debounceWaitMillis);

    return () => clearTimeout(timeoutId);
  }, [
    fetchSiteBuyer.isSuccess,
    fetchSiteBuyer.isFetching,
    fetchSiteBuyer.data,
    fetchSiteSeller.isSuccess,
    fetchSiteSeller.isFetching,
    fetchSiteSeller.data,
    selectedAddOffer,
    ppaContractType,
    ppaLength,
    ppaIndex,
  ]);

  // Persist the computed energyFlowInputsFileVersionId value, and parse out errors
  const errorsCount = Object.keys(errors).length;
  useEffect(() => {
    if (previewSiteBuyer.isError) {
      const invalidStates =
        previewSiteBuyer.error.response?.data?.invalid_states ?? [];
      const updatedErrors = {};
      invalidStates.forEach((error) => {
        // the error response inside invalidStates can look like this:
        //   {
        //       "loc": [
        //           "costInputs",
        //           "panelRate"
        //       ],
        //       "msg": "Must be a number.",
        //       "type": "type_error.float"
        //   }
        // we can therefore join the loc array to get the path to the error
        // i.e. in this case, it would be "costInputs.panelRate"
        updatedErrors[error.loc.join(".")] = error.msg;
      });
      setErrors(updatedErrors);
    } else if (errorsCount > 0) {
      setErrors({});
    }
    if (!previewSiteBuyer.isSuccess) {
      return;
    }
    const computedMatches = previewSiteBuyer.data.site.sdmMatches;
    if (computedMatches.length === 0) {
      return;
    }
    const computedFileVersionId =
      computedMatches[computedMatches.length - 1].energyFlowInputsFileVersionId;
    if (computedFileVersionId !== energyFlowInputsFileVersionId) {
      setEnergyFlowInputsFileVersionId(computedFileVersionId);
    }
  }, [
    setErrors,
    errorsCount,
    setEnergyFlowInputsFileVersionId,
    energyFlowInputsFileVersionId,
    previewSiteBuyer.isError,
    previewSiteBuyer.error,
    previewSiteBuyer.isSuccess,
    previewSiteBuyer.data,
  ]);

  if (fetchSiteBuyer.isLoading || fetchSiteSeller.isLoading) {
    return (
      <ModalWraper>
        <Loading height="100px" label="Loading match sites data..." />
      </ModalWraper>
    );
  }

  if (fetchSiteBuyer.isError || fetchSiteSeller.isError) {
    return (
      <ModalWraper>
        <div>Error loading the match sites.</div>
        <Button
          variant="secondary"
          size="sm"
          onClick={() => {
            setSelectedAddOffer(undefined);
          }}
        >
          Close
        </Button>
      </ModalWraper>
    );
  }

  return (
    <ModalWraper>
      <div className="py-3">
        {selectedAddOffer.buyerId === site.id && (
          <>
            From buyer {fetchSiteBuyer.data.site.name} to seller{" "}
            {fetchSiteSeller.data.site.name}.
          </>
        )}
        {selectedAddOffer.sellerId === site.id && (
          <>
            From seller {fetchSiteSeller.data.site.name} to buyer{" "}
            {fetchSiteBuyer.data.site.name}.
          </>
        )}
      </div>
      <Container style={{ padding: 0 }}>
        <Row>
          <Col sm={6}>
            <Form.Group controlId="sdmOfferVolume">
              <Form.Label>Amount (kWh)</Form.Label>
              <Form.Control
                type="text"
                value={numString2dp(selectedAddOffer.volume)}
                disabled
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group controlId="sdmOfferTariff">
              <Form.Label>
                {selectedAddOffer.buyerId === fetchSiteBuyer.data.site.id
                  ? "Buy"
                  : "Sell"}{" "}
                price (
                {fractionalCurrencySymbol(
                  fetchSiteBuyer.data.site.currencyCode
                )}
                /kWh)
              </Form.Label>
              <Form.Control
                type="text"
                value={(selectedAddOffer.tariff * 100.0).toFixed(2)}
                disabled
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm={6}>
            <Form.Group controlId="sdmPpaContractType">
              <Form.Label>PPA type</Form.Label>
              <Form.Select
                value={ppaContractType}
                onChange={(e) => setPpaContractType(e.target.value)}
                isInvalid={
                  errors[
                    `sdmMatches.${fetchSiteBuyer.data.site.sdmMatches.length}.ppaContractType`
                  ]
                }
              >
                <option value={"sleeved"}>Sleeved PPA</option>
                <option value={"vppa"}>VPPA</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {
                  errors[
                    `sdmMatches.${fetchSiteBuyer.data.site.sdmMatches.length}.ppaContractType`
                  ]
                }
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={3}>
            <Form.Group controlId="sdmPpaLength">
              <Form.Label>PPA length</Form.Label>
              <Form.Control
                type="text"
                value={ppaLength}
                onChange={(e) => setPpaLength(e.target.value)}
                isInvalid={
                  errors[
                    `sdmMatches.${fetchSiteBuyer.data.site.sdmMatches.length}.ppaLength`
                  ]
                }
              />
              <Form.Control.Feedback type="invalid">
                {
                  errors[
                    `sdmMatches.${fetchSiteBuyer.data.site.sdmMatches.length}.ppaLength`
                  ]
                }
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={3}>
            <Form.Group controlId="sdmPpaIndex">
              <Form.Label>PPA index</Form.Label>
              <Form.Control
                type="text"
                value={ppaIndex}
                onChange={(e) => setPpaIndex(e.target.value)}
                isInvalid={
                  errors[
                    `sdmMatches.${fetchSiteBuyer.data.site.sdmMatches.length}.ppaIndex`
                  ]
                }
              />
              <Form.Control.Feedback type="invalid">
                {
                  errors[
                    `sdmMatches.${fetchSiteBuyer.data.site.sdmMatches.length}.ppaIndex`
                  ]
                }
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Container>
      <div className="py-3">
        {(previewSiteBuyer.isLoading || previewSiteSeller.isLoading) && (
          <Loading label="Computing match impact..." height="80px" />
        )}
        {(previewSiteBuyer.isError || previewSiteSeller.isError) && (
          <>Error loading the match impact.</>
        )}
        {previewSiteBuyer.isSuccess && previewSiteSeller.isSuccess && (
          <SdmMatchImpactDescription
            site={site}
            selectedOffer={selectedAddOffer}
            isOfferBeingAdded={true}
            fetchSiteBuyer={fetchSiteBuyer}
            previewSiteBuyer={previewSiteBuyer}
            fetchSiteSeller={fetchSiteSeller}
            previewSiteSeller={previewSiteSeller}
          />
        )}
      </div>
      <div
        style={{
          display: "flex",
          gap: 24,
          justifyContent: "center",
        }}
      >
        <Button
          variant="secondary"
          size="sm"
          onClick={() => {
            setSelectedAddOffer(undefined);
          }}
        >
          No, go back
        </Button>
        <Button
          variant="success"
          size="sm"
          onClick={() => {
            siteAddMatch.mutate({
              ...selectedAddOffer,
              volume: selectedAddOffer.volume,
              ppaContractType,
              ppaLength,
              ppaIndex,
            });
            setSelectedAddOffer(undefined);
          }}
          disabled={
            previewSiteBuyer.isLoading ||
            previewSiteSeller.isLoading ||
            !previewSiteBuyer.isSuccess ||
            !previewSiteSeller.isSuccess
          }
        >
          Yes, apply
        </Button>
      </div>
    </ModalWraper>
  );
};

export default SdmAddMatchModal;
