import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useQuery } from "react-query";
import getBuildingsInRegionList from "../http/getBuildingsInRegionList";

const queryOptions = (opts) => {
  return {
    cacheTime: 1000 * 60 * 60, // 1 hour
    staleTime: 1000 * 60 * 60 * 24, // 24 hours
    refetchOnWindowFocus: false,
    retry: 3,
    ...opts,
  };
};

function getBounds(mapBounds, isSiteList) {
  if (mapBounds === undefined) return { north: 0, south: 0, west: 0, east: 0 };

  if (!isSiteList) {
    const boundsDistance = mapBounds
      .getNorthEast()
      .distanceTo(mapBounds.getSouthWest());
    // we want to pad the bounds so that we get all buildings that are near the edge of the map
    // to ensure that they don't appear as available
    // we choose 1500 because it is unlikely that a building is larger than 1500m
    const padRatio = 1500 / boundsDistance;
    mapBounds = mapBounds.pad(padRatio);
  }

  return {
    north: mapBounds.getNorth(),
    south: mapBounds.getSouth(),
    west: mapBounds.getWest(),
    east: mapBounds.getEast(),
  };
}

const useBuildingsInRegionList = (mapBounds, isSiteList, testOrgId) => {
  const { north, south, west, east } = getBounds(mapBounds, isSiteList);

  let mapSizeMeters = 100_000;
  if (mapBounds !== undefined)
    mapSizeMeters =
      mapBounds.getNorthEast().distanceTo(mapBounds.getSouthWest()) || 100_000;

  const { getToken } = useKindeAuth();
  const buildingData = useQuery(
    ["building", north, south, west, east],
    async () => {
      return getBuildingsInRegionList(
        await getToken(),
        north,
        south,
        west,
        east,
        testOrgId
      );
    },
    queryOptions({
      enabled: !!mapBounds && (mapSizeMeters < 7000 || isSiteList),
    })
  );

  return buildingData || {};
};

export default useBuildingsInRegionList;
