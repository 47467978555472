import axiosConfig from "./axiosConfig";

const postSiteCopy = async (authToken, siteId, copyBody) => {
  const { data } = await axiosConfig.post(`/site/${siteId}/copy`, copyBody, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
  return data;
};

export default postSiteCopy;
