import React from "react";
import styled from "styled-components";

export interface TabButtonProps {
  label: string;
  active: boolean;
  onClick: () => void;
  count?: number | string;
}

interface TabButtonsProps {
  tab1: TabButtonProps;
  tab2?: TabButtonProps;
  tab3: TabButtonProps;
  [key: string]: any; // To allow additional props
}

const TabButtons: React.FC<TabButtonsProps> = ({
  tab1,
  tab2,
  tab3,
  ...props
}) => {
  return (
    <TabContainer {...props}>
      <Tab1 {...props} $active={tab1.active} onClick={tab1.onClick}>
        <span data-testid={`tab-button-${tab1.label}`}>
          {tab1.label} {tab1.count !== undefined && `(${tab1.count})`}
        </span>
      </Tab1>
      {tab2 && (
        <Tab2 {...props} $active={tab2.active} onClick={tab2.onClick}>
          <span data-testid={`tab-button-${tab2.label}`}>
            {tab2.label} {tab2.count !== undefined && `(${tab2.count})`}
          </span>
        </Tab2>
      )}
      <Tab3 {...props} $active={tab3.active} onClick={tab3.onClick}>
        <span data-testid={`tab-button-${tab3.label}`}>
          {tab3.label} {tab3.count !== undefined && `(${tab3.count})`}
        </span>
      </Tab3>
    </TabContainer>
  );
};

const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: fit-content;
`;

interface StyledTabProps {
  $active: boolean;
  disabled?: boolean;
}

const Tab1 = styled.button<StyledTabProps>`
  display: flex;
  align-items: center;
  ${({ $active }) => ($active ? `color:white` : `color:#00022f`)};
  ${({ $active }) => ($active ? `background:#00022f` : "background: white")};
  border-radius: 8px 0px 0px 8px;
  border: 1px solid rgba(243, 243, 243, 0.95);
  height: 44px;
  padding: 16px;
  cursor: pointer;
  span {
    width: 100%;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
  }
  ${({ disabled }) =>
    disabled && {
      opacity: 0.5,
      cursor: "not-allowed",
    }}
`;

const Tab2 = styled.button<StyledTabProps>`
  display: flex;
  align-items: center;
  ${({ $active }) => ($active ? `color:white` : `color:#00022f`)};
  ${({ $active }) => ($active ? `background:#00022f` : "background: white")};
  border-radius: 0px 0px 0px 0px;
  border: 1px solid rgba(243, 243, 243, 0.95);
  height: 44px;
  padding: 16px;
  cursor: pointer;
  span {
    width: 100%;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
  }
  ${({ disabled }) =>
    disabled && {
      opacity: 0.5,
      cursor: "not-allowed",
    }}
`;

const Tab3 = styled.button<StyledTabProps>`
  display: flex;
  align-items: center;
  ${({ $active }) => ($active ? `color:white` : `color:#00022f`)};
  ${({ $active }) => ($active ? `background:#00022f` : "background: white")};
  border-radius: 0px 8px 8px 0px;
  border: 1px solid rgba(243, 243, 243, 0.95);
  height: 44px;
  padding: 16px;
  cursor: pointer;
  span {
    width: 100%;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
  }
  ${({ disabled }) =>
    disabled && {
      opacity: 0.5,
      cursor: "not-allowed",
    }}
`;

export default TabButtons;
