/* eslint-disable react-refresh/only-export-components */
import area from "@turf/area";
import booleanIntersects from "@turf/boolean-intersects";
import { lineString, polygon } from "@turf/helpers";
import length from "@turf/length";
import { transformTranslate } from "@turf/transform-translate";
import {
  Feature,
  GeometryCollection,
  LineString,
  Polygon,
  Position,
} from "geojson";
import * as L from "leaflet";
import "leaflet-editable";
import "leaflet.path.drag";
import "leaflet/dist/leaflet.css";
import { useEffect } from "react";
import { v4 } from "uuid";
import { ObjectGeometry } from "../PVSystem/PVSystem";
import {
  getPolyLineLayerGroup,
  updateLatLngs,
} from "./GeoMultiSelectedCustomObjects";
import { EditableLayer, EditableLayerEvent } from "./PVCustomObjectsMap";

const objectWithColorStyle = (color: string): L.PathOptions => ({
  fillOpacity: 0.5,
  color: color,
  weight: 2,
  opacity: 1,
});

// yellow

export const STYLE_AUTODETECTED_BRIGHT = objectWithColorStyle("#ffff02");

export const STYLE_AUTODETECTED_DARK = objectWithColorStyle("#f2c333");

// blue

export const STYLE_SKYLIGHT_BRIGHT = objectWithColorStyle("#0000ff");

export const STYLE_SKYLIGHT_DARK = objectWithColorStyle("#3d85c6");

// red

export const STYLE_HVAC_BRIGHT = objectWithColorStyle("#f50e01");

export const STYLE_HVAC_DARK = objectWithColorStyle("#cc0a00");

// orange

export const STYLE_OTHER_BRIGHT = objectWithColorStyle("#f89903");

export const STYLE_OTHER_DARK = objectWithColorStyle("#e69139");

// purple

export const STYLE_SOLARPANEL_BRIGHT = objectWithColorStyle("#9900ff");

export const STYLE_SOLARPANEL_DARK = objectWithColorStyle("#684ea8");

// green

export const STYLE_ROOFEDGE_BRIGHT = objectWithColorStyle("#59ff00");

export const STYLE_ROOFEDGE_DARK = objectWithColorStyle("#82ce61");

export const getStyle = (type: string, selected: boolean): L.PathOptions => {
  switch (type) {
    case "autodetected":
      return selected ? STYLE_AUTODETECTED_BRIGHT : STYLE_AUTODETECTED_DARK;
    case "skylight":
      return selected ? STYLE_SKYLIGHT_BRIGHT : STYLE_SKYLIGHT_DARK;
    case "hvac":
      return selected ? STYLE_HVAC_BRIGHT : STYLE_HVAC_DARK;
    case "solarpanel":
      return selected ? STYLE_SOLARPANEL_BRIGHT : STYLE_SOLARPANEL_DARK;
    case "roofedge":
      return selected ? STYLE_ROOFEDGE_BRIGHT : STYLE_ROOFEDGE_DARK;
    default:
      return selected ? STYLE_OTHER_BRIGHT : STYLE_OTHER_DARK;
  }
};

export const outlineGreenStyle: L.PathOptions = {
  color: "green",
  fillOpacity: 0,
  weight: 4,
  opacity: 1,
  interactive: false,
};

export const GMAPS_API_KEY = import.meta.env.VITE_GMAPS_API_KEY;
export const testMode = !import.meta.env.PROD && import.meta.env.VITE_TEST_MODE;

const polygonRingPointsToCoords = (
  polygonRingPoints: L.LatLng[]
): number[][] => {
  return polygonRingPoints.map((point) => [point.lng, point.lat]);
};
const lineStringPointsToCoords = (lineStringPoints: L.LatLng[]): number[][] => {
  return lineStringPoints.map((point) => [point.lng, point.lat]);
};

export const layerToCoordinates = (
  layer: L.Layer
): number[][] | number[][][] => {
  const geom = (layer as L.Polyline).getLatLngs() as L.LatLng[] | L.LatLng[][];
  if (Array.isArray(geom[0])) {
    // Polygon
    return (geom as L.LatLng[][]).map(polygonRingPointsToCoords);
  } else {
    // LineString
    return lineStringPointsToCoords(geom as L.LatLng[]);
  }
};

export const layerToPolygonCoordinates = (layer: L.Layer): number[][][] => {
  const geom = (layer as L.Polyline).getLatLngs() as L.LatLng[][];
  // Polygon
  return (geom as L.LatLng[][]).map(polygonRingPointsToCoords);
};

export const coordinatesToMultiPolygonGeoJson = (
  coordinates: (Position[] | Position[][])[]
): GeometryCollection => {
  return {
    type: "GeometryCollection",
    geometries: coordinates.map((coords) => {
      if (Array.isArray(coords[0][0])) {
        return {
          type: "Polygon",
          coordinates: coords as Position[][],
        };
      }
      return {
        type: "LineString",
        coordinates: coords as Position[],
      };
    }),
  };
};

export const coordinatesToPolygonGeoJson = (
  coordinates: number[][][]
): Polygon => {
  return {
    type: "Polygon",
    coordinates: coordinates,
  };
};

export const coordinatesToLineStringGeoJson = (
  coordinates: number[][]
): LineString => {
  return {
    type: "LineString",
    coordinates: coordinates,
  };
};

export const getClosedCoordinates = (
  coordinates: number[][][]
): number[][][] => {
  // If coordinates is a number[][] then it is a LineString, and we just want to return it unchanged
  const firstCoordinate = coordinates[0][0];
  const lastCoordinate = coordinates[0][coordinates[0].length - 1];
  if (
    firstCoordinate[0] === lastCoordinate[0] &&
    firstCoordinate[1] === lastCoordinate[1]
  ) {
    return filterDuplicateCoordinates(coordinates);
  }
  const closedCoordinates = [...coordinates];
  closedCoordinates[0].push(closedCoordinates[0][0]);
  return closedCoordinates;
};

/*
There was previously a bug in getClosedCoordinates(...) which resulted in many duplicate
coordinates being added to the polygon. This function filters out the duplicates.
*/
export const filterDuplicateCoordinates = (
  coordinates: number[][][]
): number[][][] => {
  const result: number[][] = [];
  let lastCoordinates: number[] | undefined = undefined;
  for (const coord of coordinates[0]) {
    if (
      lastCoordinates === undefined ||
      !(lastCoordinates[0] === coord[0] && lastCoordinates[1] === coord[1])
    ) {
      result.push(coord);
    }
    lastCoordinates = coord;
  }
  return [result];
};

export const setSelectedObjectLayerState = (
  map: L.Map,
  selectedObjectIDs: string[],
  isEditable: boolean
) => {
  if (selectedObjectIDs.length === 1) {
    map.eachLayer((layer: EditableLayer) => {
      if (layer.options.id === selectedObjectIDs[0]) {
        if (isEditable) {
          layer.options.draggable = false;
          layer.options.editable = true;
        } else {
          layer.options.draggable = true;
          layer.options.editable = false;
        }
      }
    });
  }
};

interface CustomRectangle extends L.Rectangle {
  _latlngs: L.LatLng[][];
}

interface CustomPolyline extends L.Polyline {
  _latlngs: L.LatLng[];
}

interface CustomEditable extends L.Editable {
  _dragSelectionLayerStart?: L.LatLng;
  _dragSelectionLayer?: CustomRectangle | CustomPolyline;
}

interface CustomMap {
  spacePressed?: boolean;
  lastDragLatLong?: L.LatLng;
}

export const useEventListeners = (
  map: L.Map | null,
  deleteCustomObject: (id: string) => void,
  newCustomObjects: Record<string, Feature<Polygon> | Feature<LineString>>,
  updateNewCustomObjects: (
    id: string,
    geoJson: ObjectGeometry,
    properties?: { [name: string]: any }
  ) => void,
  selectedObjectIDs: string[],
  setSelectedObjectIDs: (newSelectedObjectIDs: string[]) => void,
  drawMode: string,
  selectionTool: string,
  pushNewCustomObjectsHistory: () => void,
  popNewCustomObjectsHistory: () => void,
  onClickPolygon: (event: L.LeafletMouseEvent) => void,
  showObjects: boolean,
  setShowObjects: (showObjects: boolean) => void
) => {
  useEffect(() => {
    if (!map) {
      return;
    }

    // Keyboard actions
    const deleteSelectedObjects = () => {
      pushNewCustomObjectsHistory();
      for (const id of selectedObjectIDs) {
        deleteCustomObject(id);
      }
      setSelectedObjectIDs([]);
    };

    const duplicateSelectedObjects = () => {
      const newSelectedIds: string[] = [];
      pushNewCustomObjectsHistory();
      for (const selectedObjectID of selectedObjectIDs) {
        const customObject = newCustomObjects[selectedObjectID];
        const newId = v4();
        if (customObject.geometry.type === "LineString") {
          const selectedLineCoords = coordinatesToLineStringGeoJson(
            customObject.geometry.coordinates
          );
          const duplicatedLine = transformTranslate(
            selectedLineCoords,
            5,
            135,
            {
              units: "meters",
            }
          );
          updateNewCustomObjects(newId, duplicatedLine, {
            type:
              customObject.properties!.type === "autodetected"
                ? "other"
                : customObject.properties!.type,
            source: "manual",
          });
        } else {
          const selectedPolygonCoords = coordinatesToPolygonGeoJson(
            customObject.geometry.coordinates as Position[][]
          );
          const duplicatedPolygon = transformTranslate(
            selectedPolygonCoords,
            5,
            135,
            {
              units: "meters",
            }
          );
          updateNewCustomObjects(newId, duplicatedPolygon, {
            type:
              customObject.properties!.type === "autodetected"
                ? "other"
                : customObject.properties!.type,
            source: "manual",
          });
        }
        newSelectedIds.push(newId);
      }
      setSelectedObjectLayerState(map, selectedObjectIDs, false);
      setSelectedObjectIDs(newSelectedIds);
    };

    // Key bindings
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Shift") {
        map.editTools.stopDrawing();
        // set cursor to pointer
        map.getContainer().style.cursor = "default";
      }
      if (event.key === "Escape") {
        map.editTools.stopDrawing();
      }

      if (event.key === "Delete") {
        deleteSelectedObjects();
      }

      if (event.key === "Backspace") {
        deleteSelectedObjects();
      }

      if (
        event.key === "d" &&
        ((navigator.platform.match("Mac") && event.metaKey) || event.ctrlKey)
      ) {
        event.preventDefault();
        duplicateSelectedObjects();
      }

      if (
        event.key === "z" &&
        ((navigator.platform.match("Mac") && event.metaKey) || event.ctrlKey)
      ) {
        event.preventDefault();
        popNewCustomObjectsHistory();
      }

      if (event.key === " " || event.key === "Spacebar") {
        event.preventDefault();
        map.dragging.disable();
        map.editTools.stopDrawing();
        (map.editTools as unknown as CustomMap).spacePressed = true;
      }

      if (
        event.key === "." &&
        ((navigator.platform.match("Mac") && event.metaKey) || event.ctrlKey)
      ) {
        event.preventDefault();
        const newShowObjects = !showObjects;
        if (newShowObjects) {
          map.on("click", onMapClick);
        } else {
          map.editTools.stopDrawing();
          map.off("click", onMapClick);
          map.getContainer().style.cursor = "crosshair";
        }
        setShowObjects(newShowObjects);
      }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === "Shift") {
        // set cursor to crosshair
        map.getContainer().style.cursor = "crosshair";
      }

      if (event.key === " " || event.key === "Spacebar") {
        map.dragging.enable();
        (map.editTools as unknown as CustomMap).spacePressed = false;
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    // Space drag

    const updateMouseMoveSpaceDrag = (event: L.LeafletMouseEvent) => {
      if (!(map.editTools as unknown as CustomMap).spacePressed) {
        map.removeEventListener("mousemove", updateMouseMoveSpaceDrag);
      } else {
        const lastDragLatLong = (map.editTools as unknown as CustomMap)
          .lastDragLatLong;
        if (lastDragLatLong !== undefined) {
          const deltaLat = event.latlng.lat - lastDragLatLong.lat;
          const deltaLng = event.latlng.lng - lastDragLatLong.lng;
          map.eachLayer((layer: EditableLayer) => {
            const layer_polyline = layer;
            if (
              layer.options.id &&
              selectedObjectIDs.includes(layer.options.id) &&
              layer.dragging &&
              layer_polyline instanceof L.Polyline
            ) {
              // Handle dragging of a single selected object
              layer_polyline.disableEdit();
              layer.dragging.enable();
              layer_polyline.setLatLngs(
                updateLatLngs(layer_polyline.getLatLngs(), deltaLat, deltaLng)
              );
            } else if (layer.options.ids && layer instanceof L.Polyline) {
              layer.setLatLngs(
                updateLatLngs(layer.getLatLngs(), deltaLat, deltaLng)
              );
            }
          });
        }
        (map.editTools as unknown as CustomMap).lastDragLatLong = new L.LatLng(
          event.latlng.lat,
          event.latlng.lng
        );
      }
    };

    // Shift click drag selection

    const updateMouseMoveDrawSelectionShape = (event: L.LeafletMouseEvent) => {
      if (!event.originalEvent.shiftKey) {
        map.removeEventListener("mousemove", updateMouseMoveDrawSelectionShape);
      } else {
        map.getContainer().style.cursor = "crosshair";
        const editTools = map.editTools as CustomEditable;
        if (editTools._dragSelectionLayerStart) {
          if (editTools._dragSelectionLayerStart.equals(event.latlng)) {
            return;
          }
          const bounds = L.latLngBounds(
            editTools._dragSelectionLayerStart,
            event.latlng
          );
          const dragSelectionLayer = editTools._dragSelectionLayer;
          if (dragSelectionLayer) {
            if (selectionTool === "rectangle") {
              (dragSelectionLayer as CustomRectangle).setBounds(bounds);
            } else {
              dragSelectionLayer.setLatLngs([
                editTools._dragSelectionLayerStart,
                event.latlng,
              ]);
            }
          } else if (editTools._dragSelectionLayerStart) {
            if (selectionTool === "rectangle") {
              const dragSelectionLayer = L.rectangle(bounds, {
                color: "#0000ff",
                weight: 1,
              });
              const selectionPoly = polygon(
                getClosedCoordinates(
                  layerToPolygonCoordinates(dragSelectionLayer)
                )
              );
              const selectionPolyAreaSqM = area(selectionPoly);
              if (selectionPolyAreaSqM > 1) {
                // Avoid creating a selection rectangle that is too small as this can happen
                // when the user is just trying to click while pressing shift
                dragSelectionLayer.addTo(map);
                editTools._dragSelectionLayer =
                  dragSelectionLayer as CustomRectangle;
              }
            } else {
              const dragSelectionLine = L.polyline(
                [editTools._dragSelectionLayerStart, event.latlng],
                {
                  color: "#0000ff",
                  weight: 1,
                }
              );
              const selectionLine = lineString([
                [
                  editTools._dragSelectionLayerStart.lat,
                  editTools._dragSelectionLayerStart.lng,
                ],
                [event.latlng.lat, event.latlng.lng],
              ]);
              const selectionLineLengthKm = length(selectionLine);
              if (selectionLineLengthKm > 1 / 1000) {
                // Avoid creating a selection line that is too small as this can happen
                // when the user is just trying to click while pressing shift
                dragSelectionLine.addTo(map);
                editTools._dragSelectionLayer =
                  dragSelectionLine as CustomPolyline;
              }
            }
          }
        }
      }
    };

    const handleMapMouseDown = (event: L.LeafletMouseEvent) => {
      const spacePressed = (map.editTools as unknown as CustomMap).spacePressed;
      if ((!event.originalEvent.shiftKey && !spacePressed) || !showObjects) {
        map.getContainer().style.cursor = "grabbing";
      } else if (spacePressed) {
        // Start space drag
        map.on("mousemove", updateMouseMoveSpaceDrag);
      } else if (!map.editTools.drawing()) {
        // Start shift click drag selection
        map.getContainer().style.cursor = "crosshair";
        const editTools = map.editTools as CustomEditable;
        editTools._dragSelectionLayerStart = event.latlng;
        map.on("mousemove", updateMouseMoveDrawSelectionShape);
      }
    };

    const handleMapMouseUp = (event: L.LeafletMouseEvent) => {
      map.removeEventListener("mousemove", updateMouseMoveSpaceDrag);
      map.removeEventListener("mousemove", updateMouseMoveDrawSelectionShape);
      (map.editTools as unknown as CustomMap).lastDragLatLong = undefined;

      const spacePressed = (map.editTools as unknown as CustomMap).spacePressed;
      if (!event.originalEvent.shiftKey && !spacePressed) {
        map.getContainer().style.cursor = "crosshair";
      }

      if (spacePressed) {
        const layers: EditableLayer[] = [];
        map.eachLayer((layer: EditableLayer) => {
          layers.push(layer);
        });
        for (const layer of layers) {
          if (
            layer.options.id &&
            selectedObjectIDs.includes(layer.options.id) &&
            layer.enableEdit
          ) {
            // Re-enable edit mode on single selected object and save the changes
            layer.enableEdit();
            onEditExistingEnd({ layer: layer } as EditableLayerEvent);
          } else if (layer.options.ids) {
            // Save changes to all selected objects - we only need to trigger this for one of the multi-object selection layers
            onEditExistingEnd({ layer: layer } as EditableLayerEvent);
            break;
          }
        }
      } else if (event.originalEvent.shiftKey) {
        // Finish shift click drag selection
        const editTools = map.editTools as CustomEditable;
        if (editTools._dragSelectionLayer) {
          map.removeLayer(editTools._dragSelectionLayer);
          let selectionObj = undefined;
          if (
            selectionTool === "rectangle" &&
            (editTools._dragSelectionLayer.getLatLngs() as L.LatLng[][])[0]
              .length >= 4
          ) {
            selectionObj = polygon(
              getClosedCoordinates(
                layerToPolygonCoordinates(editTools._dragSelectionLayer)
              )
            );
          } else if (selectionTool === "line") {
            selectionObj = lineString(
              layerToCoordinates(editTools._dragSelectionLayer) as number[][]
            );
          }
          if (selectionObj) {
            const newSelectedObjectsIDs: string[] = [];
            for (const customObject of Object.values(newCustomObjects)) {
              if (customObject.geometry.type === "LineString") {
                // Intersect with LineString
                const objLine = lineString(customObject.geometry.coordinates);
                if (booleanIntersects(selectionObj, objLine)) {
                  newSelectedObjectsIDs.push(customObject.properties!.id);
                }
              } else {
                // Intersect with Polygon
                const objCoords = getClosedCoordinates(
                  customObject.geometry.coordinates
                );
                const objPoly = polygon(objCoords);
                if (booleanIntersects(selectionObj, objPoly)) {
                  newSelectedObjectsIDs.push(customObject.properties!.id);
                }
              }
            }
            const newAndOldSelectedObjectIDs = [
              ...new Set([...selectedObjectIDs, ...newSelectedObjectsIDs]),
            ];
            setSelectedObjectLayerState(map, selectedObjectIDs, false);
            setSelectedObjectLayerState(map, newAndOldSelectedObjectIDs, true);
            setSelectedObjectIDs(newAndOldSelectedObjectIDs);
          }
          editTools._dragSelectionLayer = undefined;
          editTools._dragSelectionLayerStart = undefined;
        }
      }
    };

    map.on("mousedown", handleMapMouseDown);
    map.on("mouseup", handleMapMouseUp);

    // Drawing
    const onMapClick = (event: L.LeafletMouseEvent) => {
      /*
      classList: user clicked a polygon -> ["leaflet-interactive", "leaflet-path-draggable"]
      classList: user clicked the map -> ["leaflet-container", "leaflet-touch", "leaflet-retina",
                                          "leaflet-fade-anim", "leaflet-grab", "leaflet-touch-drag",
                                          leaflet-touch-zoom", "leaflet-editable-drawing" ]
      */
      if (
        (
          event.originalEvent.srcElement as unknown as HTMLElement
        ).classList.contains("leaflet-container") &&
        !event.originalEvent.shiftKey &&
        !(map.editTools as unknown as CustomMap).spacePressed &&
        showObjects
      ) {
        if (!map.editTools.drawing()) {
          if (drawMode === "roofedge") {
            map.editTools.startPolyline(event.latlng);
          } else {
            map.editTools.startPolygon(event.latlng);
          }
          (
            map.editTools as unknown as { forwardLineGuide: L.Polyline }
          ).forwardLineGuide.setStyle(getStyle(drawMode, true));
          setSelectedObjectLayerState(map, selectedObjectIDs, false);
          setSelectedObjectIDs([]);
        }
      }
    };

    const onDrawStart = (e) => {
      (e.layer as L.Polygon).setStyle(getStyle(drawMode, true));
    };

    const onDrawCommit = (event: EditableLayerEvent) => {
      const drawLayer = event.layer;

      // editable:drawing adds a Polygon layer, remove this, add the polygon to NewCustomObjects, and mark it as selected
      const layerCoordinates = layerToCoordinates(drawLayer);
      const map: L.Map = event.target._map || event.target;
      map.removeLayer(drawLayer);
      const newCustomObject = {
        id: v4(),
        coordinates:
          drawMode === "roofedge"
            ? layerCoordinates
            : getClosedCoordinates(layerCoordinates as number[][][]),
      };
      setSelectedObjectIDs([newCustomObject.id]);

      pushNewCustomObjectsHistory();

      // Record the new custom object into React state
      updateNewCustomObjects(
        newCustomObject.id,
        drawMode === "roofedge"
          ? coordinatesToLineStringGeoJson(
              newCustomObject.coordinates as number[][]
            )
          : coordinatesToPolygonGeoJson(
              newCustomObject.coordinates as number[][][]
            ),
        {
          type: drawMode,
          source: "manual",
        }
      );
    };

    const onEditExistingEnd = (e: EditableLayerEvent) => {
      const layer = e.layer || e.target;

      if (layer.options.id === undefined && layer.options.ids === undefined) {
        // This isn't one of our layers, return early
        return;
      }

      if (layer.options.id !== undefined) {
        // Updating a single polygon
        const geoJson = layer.toGeoJSON!();
        pushNewCustomObjectsHistory();
        updateNewCustomObjects(layer.options.id!, geoJson.geometry, {
          source: "manual",
        });
      } else if (layer instanceof L.Polyline) {
        // Updating a layer within our GeoJSONMultiSelectedCustomObjects. This must be a drag end, so need to update them all.
        pushNewCustomObjectsHistory();
        const newLatLngs = getPolyLineLayerGroup(layer)
          .getLayers()
          .map((subLayer) => {
            return layerToCoordinates(subLayer);
          });
        const ids = (layer as EditableLayer).options.ids!;
        for (const [index, id] of ids.entries()) {
          if (Array.isArray(newLatLngs[index][0][0])) {
            const closedCoordinates = getClosedCoordinates(
              newLatLngs[index] as number[][][]
            );
            updateNewCustomObjects(
              id,
              coordinatesToPolygonGeoJson(closedCoordinates),
              {
                source: "manual",
              }
            );
          } else {
            updateNewCustomObjects(
              id,
              coordinatesToLineStringGeoJson(newLatLngs[index] as number[][]),
              {
                source: "manual",
              }
            );
          }
        }
      }
    };

    const onVertexAltClick = (e: L.VertexEvent) => {
      if (e.layer.feature.geometry.type === "LineString") {
        const id = e.layer.options.id || e.layer.options.idsObjectId;
        const line = newCustomObjects[id] as Feature<LineString>;
        const vertexIndex = e.vertex.getIndex();
        if (
          vertexIndex === 0 ||
          vertexIndex === line.geometry.coordinates.length - 1
        ) {
          // Don't allow a line to be "split" at its start or end
          return;
        }
        pushNewCustomObjectsHistory();
        const linePart1 = {
          type: "LineString",
          coordinates: line.geometry.coordinates.slice(0, vertexIndex + 1),
        } as LineString;
        const linePart2 = {
          type: "LineString",
          coordinates: line.geometry.coordinates.slice(vertexIndex),
        } as LineString;
        const linePart1Id = v4();
        const linePart2Id = v4();
        updateNewCustomObjects(linePart1Id, linePart1, {
          ...line.properties!,
          isSplitLine: true,
        });
        updateNewCustomObjects(linePart2Id, linePart2, {
          ...line.properties!,
          isSplitLine: true,
        });
        deleteCustomObject(id);
        const newSelectedObjectIDs = [
          ...selectedObjectIDs.filter((selectedId) => selectedId !== id),
          linePart1Id,
          linePart2Id,
        ];
        setSelectedObjectIDs(newSelectedObjectIDs);
        (e as unknown as L.CancelableEvent).cancel();
      }
    };

    map.on("click", onMapClick);
    map.on("editable:drawing:start", onDrawStart);
    map.on("editable:drawing:commit", onDrawCommit);
    map.on("editable:vertex:deleted", onEditExistingEnd);
    map.on("editable:vertex:altclick", onVertexAltClick);

    // Dragging
    map.on("editable:vertex:dragend", onEditExistingEnd);
    map.on("editable:dragend", onEditExistingEnd);

    // Per layer events
    const onLayerDragStart = (e: EditableLayerEvent) => {
      // Mark the dragged layer as selected
      const layer = e.layer || e.target;
      setSelectedObjectLayerState(map, selectedObjectIDs, false);

      layer.options.draggable = true;
      layer.options.editable = false;
      setSelectedObjectIDs([e.target.options.id!]);
    };
    const onLayerDragEnd = (e: EditableLayerEvent) => {
      // Mark the dragged layer as editable
      map.eachLayer((layer: EditableLayer) => {
        if (layer.options.id === e.target.options.id) {
          if (layer.enableEdit && layer.editEnabled && !layer.editEnabled()) {
            layer.options.editable = true;
            layer.options.draggable = false;
            layer.enableEdit();
          }
        }
      });
      onEditExistingEnd(e);
    };
    const onLayerAdd = (e: EditableLayerEvent) => {
      const layer = e.layer;

      if (layer.options.id === undefined && layer.options.ids === undefined) {
        // This isn't one of our layers, return early
        return;
      }

      // Make sure the layer is editable and draggable if it should be
      if (
        layer.options.editable &&
        layer.dragging &&
        layer.enableEdit &&
        layer.editEnabled &&
        !layer.editEnabled()
      ) {
        layer.enableEdit();
        layer.dragging.enable();
      }
      // Make sure the layer is draggable if it should be
      if (
        layer.options.draggable &&
        layer.dragging &&
        layer.disableEdit &&
        layer.dragging.enable &&
        // Vertex's generated by leaflet editable get the same options as their parent layer,
        // but we do not want to add the same event handlers. The icon option is exclusive
        // to these vertices.
        !layer.options.icon
      ) {
        layer.disableEdit();
        layer.dragging.enable();
      }
      if (layer.options.draggable) {
        layer.on("click", onClickPolygon);
        if (layer.options.ids) {
          layer.on("dragend", onEditExistingEnd);
          return;
        }
        layer.on("dragstart", onLayerDragStart);
        layer.on("dragend", onLayerDragEnd);
      }
    };
    map.eachLayer((l) => onLayerAdd({ layer: l } as EditableLayerEvent));
    map.on("layeradd", onLayerAdd);

    // Cleanup function to remove the event listeners when the component unmounts
    return () => {
      // Key bindings
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);

      // Shift click drag selection
      map.off("mousedown", handleMapMouseDown);
      map.off("mouseup", handleMapMouseUp);
      map.off("mousemove", updateMouseMoveDrawSelectionShape);

      // Drawing
      map.off("click", onMapClick);
      map.off("editable:drawing:start", onDrawStart);
      map.off("editable:drawing:commit", onDrawCommit);
      map.off("editable:vertex:deleted", onEditExistingEnd);
      map.off(
        "editable:vertex:altclick",
        onVertexAltClick as unknown as L.LeafletEventHandlerFn
      );

      // Dragging
      map.off("editable:vertex:dragend", onEditExistingEnd);
      map.off("editable:dragend", onEditExistingEnd);

      // Per layer events
      map.off("layeradd", onLayerAdd);
      map.eachLayer((layer) => {
        layer.off("click", onClickPolygon);
        layer.off("dragstart", onLayerDragStart);
        layer.off("dragend", onLayerDragEnd);
        layer.off("dragend", onEditExistingEnd);
      });
    };
  }, [
    map,
    deleteCustomObject,
    updateNewCustomObjects,
    newCustomObjects,
    selectedObjectIDs,
    setSelectedObjectIDs,
    selectionTool,
    drawMode,
    pushNewCustomObjectsHistory,
    popNewCustomObjectsHistory,
    onClickPolygon,
    setShowObjects,
    showObjects,
  ]);
};
