/*
  formatPercentage(0.1234) => '12.3%'
  formatPercentage(0.1234, 2) => '12.34%'
*/
const formatPercentage = (
  percentage: number,
  precision: number = 1
): string => {
  return `${(percentage * 100).toFixed(precision)}%`;
};

export default formatPercentage;
