import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useQuery } from "react-query";
import getPVDataList from "../http/getPVDataList";

const usePvData = () => {
  const { getToken } = useKindeAuth();
  const fetchPVData = useQuery("pvDataList", async () => {
    return getPVDataList(await getToken());
  });

  return {
    fetchPVData,
  };
};

export default usePvData;
