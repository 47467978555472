import { getMapboxUrl } from "@inrange/building-manager-api-client";
import {
  Building,
  getBuildingCoordinates,
} from "@inrange/building-manager-api-client/models-site";
import { Feature, LineString, Polygon } from "geojson";
import L, {
  Editable,
  latLngBounds,
  LatLngBounds,
  Layer,
  LayerOptions,
  LeafletEvent,
  Map as LeafletMap,
  LeafletMouseEvent,
} from "leaflet";
import "leaflet-editable";
import "leaflet.path.drag";
import "leaflet/dist/leaflet.css";
import React, { useCallback, useEffect, useState } from "react";
import { MapContainer, Pane, PaneProps, TileLayer } from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import { ObjectGeometry } from "../PVSystem/PVSystem";
import { GeoBuildings } from "./GeoBuildings";
import { GeoCustomObjects } from "./GeoCustomObjects";
import { GeoMultiSelectedCustomObjects } from "./GeoMultiSelectedCustomObjects";
import { MapHook } from "./MapHook";
import {
  GMAPS_API_KEY,
  setSelectedObjectLayerState,
  testMode,
  useEventListeners,
} from "./pvcustomobjectmap-utils";

interface PVCustomObjectsMapProps {
  buildings: Building[];
  siteCenter: [number, number];
  newCustomObjects: Record<string, Feature<Polygon> | Feature<LineString>>;
  updateNewCustomObjects: (
    id: string,
    geoJson: ObjectGeometry,
    properties?: { [name: string]: any }
  ) => void;
  deleteCustomObject: (id: string) => void;
  selectedObjectIDs: string[];
  setSelectedObjectIDs: (selectedObjectIDs: string[]) => void;
  drawMode: string;
  selectionTool: string;
  pushNewCustomObjectsHistory: () => void;
  popNewCustomObjectsHistory: () => string[] | undefined;
}

type EditableLeafletMap = LeafletMap & {
  editTools: Editable & {
    _drawingEditor?: Layer & {
      _drawnLatLngs: unknown[];
    };
  };
};

export type EditableLayer = Layer & {
  enableEdit?: () => void;
  disableEdit?: () => void;
  editEnabled?: () => boolean;
  dragging?: {
    enable: () => void;
  };
  options: LayerOptions & {
    id?: string;
    ids?: string[];
    editable?: boolean;
    draggable?: boolean;
    icon?: unknown;
    onLayerAddDone?: boolean;
  };
  toGeoJSON?: () => Feature<Polygon>;
};

export interface EditableLayerEvent extends LeafletEvent {
  layer: EditableLayer;
  target: EditableLayer & { _map?: any };
}

const PVCustomObjectsMap: React.FC<PVCustomObjectsMapProps> = ({
  buildings,
  siteCenter,
  newCustomObjects,
  updateNewCustomObjects,
  deleteCustomObject,
  selectedObjectIDs,
  setSelectedObjectIDs,
  drawMode,
  selectionTool,
  pushNewCustomObjectsHistory,
  popNewCustomObjectsHistory,
}) => {
  const [map, setMap] = useState<LeafletMap | null>(null);
  const [tileLoadingLabel, setTileLoadingLabel] =
    useState<string>("tile-loading");
  const [showObjects, setShowObjects] = useState<boolean>(true);

  const popNewCustomObjectsHistoryHandleLeafletStateChange = useCallback(() => {
    const newSelectedObjectIds = popNewCustomObjectsHistory();
    if (newSelectedObjectIds && map) {
      // Selections have changed after a history reset, so reset the editable and draggable properties on all single
      // object layers
      map.eachLayer((layer: EditableLayer) => {
        if (layer.options.id) {
          if (
            newSelectedObjectIds.length === 1 &&
            newSelectedObjectIds.includes(layer.options.id)
          ) {
            layer.options.editable = true;
            layer.options.draggable = false;
          } else {
            layer.options.editable = false;
            layer.options.draggable = true;
          }
        }
      });
    }
  }, [map, popNewCustomObjectsHistory]);

  useEffect(() => {
    if (!map) return;

    // Clear any existing partial drawing
    map.editTools.stopDrawing();
  }, [map, drawMode]);

  useEffect(() => {
    if (!map) return;

    // Selected object IDs have changed, make sure that the rendered editable/draggable UX matches
    // the stored options.[editable|draggable] properties.
    map.eachLayer((layer: EditableLayer) => {
      if (layer.options.id) {
        if (
          selectedObjectIDs.length === 1 &&
          selectedObjectIDs.includes(layer.options.id) &&
          layer.enableEdit &&
          layer.options.editable
        ) {
          // Single selected object which should be editable
          layer.enableEdit();
        } else if (
          layer.disableEdit &&
          layer.dragging &&
          layer.options.draggable
        ) {
          // All other single objects are only draggable
          layer.options.draggable = true;
          layer.options.editable = false;
          layer.disableEdit();
          layer.dragging.enable();
        }
      }
    });
  }, [map, selectedObjectIDs]);

  const bounds: LatLngBounds = latLngBounds([siteCenter]);

  buildings.forEach((building) => {
    const coordinates = getBuildingCoordinates(building);
    coordinates[0].forEach((coord: number[]) => {
      bounds.extend([...coord].reverse() as [number, number]);
    });
  });

  const onClickPolygon = (event: LeafletMouseEvent) => {
    const layer: L.Polyline | L.Polygon = event.propagatedFrom || event.target;
    // @ts-expect-error - We have to get the map from the private _map property
    const map: LeafletMap = layer._map;

    // Click handler for when the shift key is not pressed
    if (!event.originalEvent.shiftKey) {
      // User clicked a polygon, so handle the click in the polygon instead and don't draw a new polygon
      map.editTools.stopDrawing();
      map.dragging.disable();

      if (layer.options["id"] !== undefined) {
        const editableLayer = layer as EditableLayer;
        editableLayer.options.draggable = false;
        editableLayer.options.editable = true;
        map.eachLayer((mapLayer: EditableLayer) => {
          if (
            mapLayer.options.id &&
            mapLayer.options.id !== editableLayer.options.id
          ) {
            mapLayer.options.draggable = true;
            mapLayer.options.editable = false;
          }
        });
        setSelectedObjectIDs([layer.options["id"]]);
      } else {
        setSelectedObjectIDs([layer.options["idsObjectId"]]);
      }
      return;
    } else {
      // Click handler for when the shift key *is* pressed
      const featureType = layer?.feature?.geometry?.type;

      if (featureType === "Polygon" || featureType === "LineString") {
        // user clicked a single object, which may be selected or de-selected
        const id = layer.options["id"];
        if (selectedObjectIDs.includes(id)) {
          // User clicked a selected object, need to deselect it
          const editableLayer = layer as EditableLayer;
          editableLayer.options.draggable = true;
          editableLayer.options.editable = false;
          setSelectedObjectIDs(
            selectedObjectIDs.filter(
              (selectedObjectsID) => selectedObjectsID !== id
            )
          );
        } else {
          // User clicked a de-selected object, need to select it
          const editableLayer = layer as EditableLayer;
          editableLayer.options.draggable = false;
          editableLayer.options.editable = true;
          setSelectedObjectIDs([...selectedObjectIDs, id]);
        }
      } else {
        // featureType === "GeometryCollection" -> user clicked a multi-selected object
        const newSelectedObjectsIDs = selectedObjectIDs.filter(
          (selectedObjectsID) =>
            selectedObjectsID !== layer.options["idsObjectId"]
        );
        setSelectedObjectIDs(newSelectedObjectsIDs);
      }
    }
  };

  useEventListeners(
    map,
    deleteCustomObject,
    newCustomObjects,
    updateNewCustomObjects,
    selectedObjectIDs,
    setSelectedObjectIDs,
    drawMode,
    selectionTool,
    pushNewCustomObjectsHistory,
    popNewCustomObjectsHistoryHandleLeafletStateChange,
    onClickPolygon,
    showObjects,
    setShowObjects
  );

  const onDrawMouseClicked = (event: LeafletEvent) => {
    // This gets triggered after the user has completed their first click, so this is where we can deselect any selected polygons
    const map: EditableLeafletMap = event.target._map || event.target;
    const drawingLayer = map.editTools._drawingEditor;
    if (drawingLayer && drawingLayer._drawnLatLngs.length > 0) {
      setSelectedObjectLayerState(map, selectedObjectIDs, false);
      setSelectedObjectIDs([]);
    }
    return;
  };

  const onDrawCancel = (event: EditableLayerEvent) => {
    const polygonLayer = event.layer;
    const map: LeafletMap = event.target._map || event.target;
    map.removeLayer(polygonLayer);
  };

  const onMapLoad = (event: LeafletEvent) => {
    const map: LeafletMap = event.target;

    // Drawing
    map.on("editable:drawing:clicked", onDrawMouseClicked);
    map.on("editable:drawing:cancel", onDrawCancel);
  };

  // The type of MapContainer.whenReady is wrong, so we have to do some casting to account for that
  return (
    <div data-testid={tileLoadingLabel} id="pv-custom-objects-map">
      <MapContainer
        center={siteCenter}
        style={{
          width: "100%",
          height: "calc(90vh - 250px)",
          margin: "0 0 10px",
          cursor: "crosshair",
        }}
        whenReady={onMapLoad as unknown as () => void}
        ref={setMap}
        editable={true}
        boxZoom={false}
      >
        <MapHook bounds={bounds} />
        {testMode ? (
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url={getMapboxUrl("satellite-v9")}
            eventHandlers={{
              loading: () => setTileLoadingLabel("tile-loading"),
              load: () => setTileLoadingLabel("tile-loaded"),
            }}
          />
        ) : (
          <ReactLeafletGoogleLayerFix
            apiKey={GMAPS_API_KEY}
            type={"satellite"}
          />
        )}
        {showObjects && (
          <GeoCustomObjects
            newCustomObjects={newCustomObjects}
            selectedObjectIDs={selectedObjectIDs}
            onClick={onClickPolygon}
          />
        )}
        {showObjects && (
          <GeoMultiSelectedCustomObjects
            newCustomObjects={newCustomObjects}
            selectedObjectIDs={selectedObjectIDs}
            onClick={onClickPolygon}
          />
        )}
        <PaneExtended zIndex={500} name="site-boundaries">
          <GeoBuildings buildings={buildings} />
        </PaneExtended>
      </MapContainer>
    </div>
  );
};

// We need this because ReactLeafletGoogleLayer doesn't declare the type parameter
const ReactLeafletGoogleLayerFix =
  ReactLeafletGoogleLayer as React.ComponentType<{
    apiKey: string;
    type: string;
  }>;

// We need this because Pane doesn't declare the zIndex parameter
const PaneExtended = Pane as React.ComponentType<
  PaneProps & {
    zIndex: number;
  }
>;

export default PVCustomObjectsMap;
