import { QueryClient } from "react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        console.log({ failureCount, error });
        if (error.response?.status === 404 || failureCount > 3) {
          return false;
        }
        return true;
      },
    },
  },
});

export default queryClient;
