import { Site } from "@inrange/building-manager-api-client/models-site";

const isEmptyObjectOrArray = (value: any): boolean => {
  if (value !== null && typeof value === "object") {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return Object.keys(value).length === 0;
    }
  }
  return false;
};

const defaultSiteIgnoreKeys = new Set<string>([
  "createdBy",
  "createdAt",
  "updatedAt",
  "halfHourlyDemand",
]);

export const buildSitePayload = (site: Partial<Site>): Partial<Site> => {
  const siteClean = Object.fromEntries(
    Object.entries(site).filter(
      ([key, value]) =>
        value !== null &&
        value !== "" &&
        !defaultSiteIgnoreKeys.has(key) &&
        !isEmptyObjectOrArray(value)
    )
  );

  const sitePayload: Partial<Site> = { ...siteClean };
  // These values are calculation results and should not be sent to the API
  delete sitePayload.dno;
  delete sitePayload.substationData;
  delete sitePayload.halfHourlyGeneration;
  delete sitePayload.halfHourlyDemand;
  delete sitePayload.energyFlowMonthly;
  delete sitePayload.energyFlowAnnual;
  delete sitePayload.energyFlowSummerDay;
  delete sitePayload.energyFlowWinterDay;
  delete sitePayload.projectCosts;
  delete sitePayload.financialModels;
  delete sitePayload.energyFlowMonthlyActuals;
  delete sitePayload.financialModelsMonthlyActuals;
  delete sitePayload.activeInvestmentModel;
  return sitePayload;
};
