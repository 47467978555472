import { Nav } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";

const SiteNav = ({ site }) => {
  const { siteId } = useParams();
  const location = useLocation();
  const buyMatches = site.sdmMatches.filter(
    (match) => match.buyerId === site.id
  );
  const sellMatches = site.sdmMatches.filter(
    (match) => match.sellerId === site.id
  );
  return (
    <Nav
      activeKey={location.pathname}
      expand="md"
      style={{ padding: "8px 12px", backgroundColor: "#d9d9d9" }}
    >
      <Nav.Item>
        <Nav.Link
          eventKey={`/site/${siteId}/edit`}
          as={Link}
          to={`/site/${siteId}/edit`}
        >
          Site Data
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          eventKey={`/site/${siteId}/sdm`}
          as={Link}
          to={`/site/${siteId}/sdm`}
        >
          SDM ({sellMatches.length} sell, {buyMatches.length} buy)
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
};

export default SiteNav;
