import { KindeRedirectContext } from "@inrange/auth";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../routes/Loading";

const LoginRedirect = () => {
  document.title = "Logging in...";
  const navigate = useNavigate();
  const { redirectUrl } = useContext(KindeRedirectContext);

  useEffect(() => {
    if (navigate && redirectUrl) {
      if (redirectUrl === "/login-redirect") {
        navigate("/", { replace: true });
      } else {
        navigate(redirectUrl, { replace: true });
      }
    }
  }, [navigate, redirectUrl]);

  return <Loading label="Logging in ..." />;
};

export default LoginRedirect;
