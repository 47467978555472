import {
  generateId,
  generateIdFromText,
  useOrganisation,
} from "@inrange/building-manager-api-client";
import { Icons } from "@inrange/theme-components/icons";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useNavigate } from "react-router-dom";
import { BUILDING_PROFILES } from "../sharedEnums";
import PVSummary from "./PVSummary";

const CreateOrg = ({
  modal = false,
  handleModalSubmit = () => {},
  handleModalCancel = () => {},
}) => {
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const e2eTestOrg = params.get("testOrgId") !== null;

  const orgTypeMap = {
    Landlord: { isTest: false, orgType: "Landlord" },
    Tenant: { isTest: false, orgType: "Tenant" },
    "Test Landlord": { isTest: true, orgType: "Landlord" },
    "Test Tenant": { isTest: true, orgType: "Tenant" },
  };
  const [newOrgName, setNewOrgName] = useState("");
  const [orgType, setOrgType] = useState("Tenant");
  const [buildingProfile, setBuildingProfile] = useState(null);

  const { createOrg } = useOrganisation();

  const buildPayload = ({ name, buildingProfile, orgType, isTest }) => {
    const id = e2eTestOrg ? generateId() : generateIdFromText(name);
    return {
      id,
      name,
      buildingProfile,
      orgType,
      isTest,
      isTemporary: e2eTestOrg,
    };
  };

  const handleFormSubmit = () => {
    const payload = buildPayload({
      name: newOrgName,
      buildingProfile,
      ...orgTypeMap[orgType],
    });
    createOrg.mutate({ organisation: payload });
  };

  const handleFormCancel = () => {
    handleModalCancel();
  };

  const disableSubmit =
    createOrg.isLoading || createOrg.isSuccess || newOrgName.length < 1;

  if (createOrg.isError)
    alert(
      `Error creating organisation ${newOrgName} - ${createOrg.error.message}`
    );

  if (createOrg.isSuccess) {
    const { organisation } = createOrg.data;
    const lowerOrgType = orgType.toLowerCase().replace("test ", "");
    if (modal) {
      handleModalSubmit(
        organisation.id,
        organisation.name,
        lowerOrgType,
        organisation.buildingProfile
      );
    } else {
      navigate(`/organisation/${organisation.id}/edit`);
    }
  }

  return (
    <CreateOrgForm
      newOrgName={newOrgName}
      setNewOrgName={setNewOrgName}
      orgType={orgType}
      setOrgType={setOrgType}
      buildingProfile={buildingProfile}
      setBuildingProfile={setBuildingProfile}
      orgTypes={Object.keys(orgTypeMap)}
      handleFormSubmit={handleFormSubmit}
      handleFormCancel={handleFormCancel}
      disableSubmit={disableSubmit}
      modal={modal}
    />
  );
};

const CreateOrgForm = ({
  newOrgName,
  setNewOrgName,
  orgType,
  setOrgType,
  buildingProfile,
  setBuildingProfile,
  orgTypes,
  handleFormSubmit,
  handleFormCancel,
  disableSubmit,
  modal,
}) => {
  return (
    <Row>
      {!modal && <h5>Create new organisation</h5>}
      <Col>
        <Form.Group className="mb-3">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            value={newOrgName}
            onChange={(e) => setNewOrgName(e.target.value)}
            data-testid="createOrgName"
          />
        </Form.Group>
      </Col>
      <Col>
        <Form.Group className="mb-3">
          <Form.Label>Organisation type</Form.Label>
          <Form.Select
            value={orgType}
            onChange={(e) => setOrgType(e.target.value)}
            data-testid="createOrgType"
          >
            <option hidden disabled value="">
              Select Type
            </option>
            {orgTypes.map((key) => (
              <option key={key} value={key}>
                {key}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Col>
      {(orgType === "Tenant" || orgType === "Test Tenant") && (
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Default building profile</Form.Label>
            <Form.Select
              value={buildingProfile ?? ""}
              onChange={(e) =>
                setBuildingProfile(
                  e.target.value === "" ? null : e.target.value
                )
              }
            >
              <option disabled value="">
                Select profile
              </option>
              {Object.entries(BUILDING_PROFILES).map(
                ([value, profileTitle], index) => (
                  <option value={value} key={index}>
                    {profileTitle}
                  </option>
                )
              )}
            </Form.Select>
          </Form.Group>
        </Col>
      )}
      <Col sm={2}>
        <div style={{ display: "flex", gap: "24px" }}>
          <Button
            variant="primary"
            onClick={handleFormSubmit}
            style={{ width: "108px", marginTop: 32 }}
            disabled={disableSubmit}
            data-testid="createOrgSubmit"
          >
            Create
          </Button>
          {modal && (
            <img
              style={{
                position: "absolute",
                top: 4,
                right: 4,
                cursor: "pointer",
              }}
              src={Icons.close}
              onClick={handleFormCancel}
            />
          )}
        </div>
      </Col>
      {!modal && (
        <Col xs={5} md={3}>
          <h6>PV Summary</h6>
          <PVSummary />
        </Col>
      )}
    </Row>
  );
};

export default CreateOrg;
