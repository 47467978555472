import { Panel } from "@inrange/building-manager-api-client/models-site";
import { PvaData } from "@inrange/inrange-data-api-client";
import React from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

const numString = (value: number): string => {
  return new Intl.NumberFormat("en-GB", {
    style: "decimal",
  }).format(value);
};

interface PvSystemDescriptionProps {
  pvPanelNameSelected: string;
  setPvPanelNameSelected: (name: string) => void;
  fetchSiteValues: {
    data?: {
      options: {
        pvPanels: { [key: string]: any };
      };
    };
  };
  pvPanel: Panel;
  pvaData?: PvaData;
  pvaInstalledCapacity: number;
}

const PvSystemDescription: React.FC<PvSystemDescriptionProps> = ({
  pvPanelNameSelected,
  setPvPanelNameSelected,
  fetchSiteValues,
  pvPanel,
  pvaData,
  pvaInstalledCapacity,
}) => {
  const totalMass = pvaData?.site_metrics?.total_mass?.toFixed(1) || "0";
  const netForce = pvaData?.site_metrics?.net_force || 0;
  const deadLoad = pvaData?.site_metrics?.dead_load || 0;
  const roofAllocation = pvaData?.site_metrics?.roof_allocation || 0;
  const panelPowerKwp = pvPanel.pnom / 1000;

  return (
    <>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="pvDesign-pvPanel">
            <Form.Label>Panel used</Form.Label>
            <Form.Select
              value={pvPanelNameSelected}
              onChange={(e) => setPvPanelNameSelected(e.target.value)}
            >
              {Object.entries(fetchSiteValues.data!.options.pvPanels).map(
                ([panelName, _], index) => (
                  <option key={index}>{panelName}</option>
                )
              )}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group
            className="mb-3"
            controlId="pvDesign-pvaInstalledCapacity"
          >
            <Form.Label>Installed capacity (kWp)</Form.Label>
            <Form.Control disabled value={pvaInstalledCapacity.toFixed(3)} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="pvDesign-roofUtilisation">
            <Form.Label>Roof utilisation (%)</Form.Label>
            <Form.Control disabled value={roofAllocation.toFixed(4)} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="pvDesign-panelCount">
            <Form.Label>Panel count</Form.Label>
            <Form.Control
              disabled
              value={
                pvaData?.site_metrics?.num_panels
                  ? numString(pvaData.site_metrics?.num_panels)
                  : "??"
              }
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="pvDesign-kwpPanel">
            <Form.Label>kWp per panel</Form.Label>
            <Form.Control disabled value={panelPowerKwp} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="pvDesign-totalMass">
            <Form.Label>Total panel mass (kg)</Form.Label>
            <Form.Control disabled value={totalMass} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="pvDesign-deadLoad">
            <Form.Label>
              Dead load (kN/m<sup>2</sup>)
            </Form.Label>
            <Form.Control disabled value={(deadLoad / 1000).toFixed(4)} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="pvDesign-netForce">
            <Form.Label>Net force (kN)</Form.Label>
            <Form.Control disabled value={(netForce / 1000).toFixed(4)} />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

export default PvSystemDescription;
