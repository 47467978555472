import { useSiteSdm } from "@inrange/building-manager-api-client";
import {
  formatCurrency,
  formatPercentage,
} from "@inrange/theme-components/formatting";
import { Icons } from "@inrange/theme-components/icons";
import { useState } from "react";
import { Link } from "react-router-dom";

import {
  reversed,
  sortBy,
  sortByAdditional,
} from "@inrange/calculations/utils";
import { Button, Card, Form, Table } from "react-bootstrap";
import isSiteOwnerOccupied from "../../../utils/isSiteOwnerOccupied";
import Loading from "../../Loading";
import { numString2dp } from "./utils";

const buyOfferLargeEnoughForThisSite = (sdm, minMarketplaceMatchSize) => {
  return sdm.buy.config.volume > minMarketplaceMatchSize;
};

const buyOfferLargeEnoughForOtherSite = (sdm) => {
  return sdm.buy.config.volume > sdm.dest.minMarketplaceMatchSize;
};

const sellOfferLargeEnoughForThisSite = (sdm, minMarketplaceMatchSize) => {
  return sdm.sell.config.volume > minMarketplaceMatchSize;
};

const sellOfferLargeEnoughForOtherSite = (sdm) => {
  return sdm.sell.config.volume > sdm.dest.minMarketplaceMatchSize;
};

const capitalize = (str) => {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const SdmOffers = ({ site, updateSite, setSelectedAddOffer }) => {
  const [minMarketplaceMatchSize, setMinMarketplaceMatchSize] = useState(
    site.minMarketplaceMatchSize
  );
  const [filterMatchDno, setFilterMatchDno] = useState(false);
  const [filterMatchingOrg, setFilterMatchingOrg] = useState(false);
  const [filterCustomerFacing, setFilterCustomerFacing] = useState(true);
  const [filterBiDirectionalMatches, setFilterBiDirectionalMatches] =
    useState(false);
  const [sortColumn, setSortColumn] = useState("sell");
  const [sortDirection, setSortDirection] = useState("desc");
  const { fetchSiteSdmList } = useSiteSdm({
    siteId: site.id,
    getAllResults: true,
    onlyLinkedOrgs: !import.meta.env.PROD && import.meta.env.VITE_TEST_MODE,
  });
  const isOwnerOccupied = isSiteOwnerOccupied(site.siteOwnerships);

  const saveSite = (siteUpdates) => {
    const update = {
      site: {
        ...siteUpdates,
        updateHubSpot: false,
      },
    };
    updateSite.mutate(update);
  };

  if (updateSite.isSuccess) {
    updateSite.reset();
    window.location.reload();
  }

  if (updateSite.isError) {
    alert(`Error updating site - ${updateSite.error.message}`);
    updateSite.reset();
  }

  if (fetchSiteSdmList.isFetching) {
    return <Loading height="10vh" label="Computing import/export matches..." />;
  }

  if (
    fetchSiteSdmList.isError ||
    (!fetchSiteSdmList.isLoading &&
      fetchSiteSdmList.data &&
      !fetchSiteSdmList.data.results)
  ) {
    return <div>Failed to compute import/export matches.</div>;
  }

  const sdmList = fetchSiteSdmList.data.results || [];

  const siteDno = site.dno.name;
  const annualExport = site.energyFlowAnnual.exported;
  const gridImport = site.energyFlowAnnual.gridImport;

  const filteredSdmMatches = sdmList.filter((sdm) => {
    if (filterMatchDno && sdm.dest.dno !== siteDno) {
      return null;
    }
    if (filterMatchingOrg && sdm.dest.from_additional_org) {
      return null;
    }
    if (filterCustomerFacing && sdm.dest.is_scenario) {
      return null;
    }
    if (filterBiDirectionalMatches) {
      if (
        !(
          buyOfferLargeEnoughForThisSite(sdm, minMarketplaceMatchSize) &&
          buyOfferLargeEnoughForOtherSite(sdm)
        ) &&
        !(
          sellOfferLargeEnoughForThisSite(sdm, minMarketplaceMatchSize) &&
          sellOfferLargeEnoughForOtherSite(sdm)
        )
      ) {
        return null;
      }
    }
    return sdm;
  });

  const sortFunction =
    sortColumn === "buy"
      ? sortBy((a) => a.buy.config.volume)
      : sortBy((a) => a.sell.financialModels.landlord.license.irr);
  const sortDirectionFunction =
    sortDirection === "asc" ? sortFunction : reversed(sortFunction);
  const sortDirectionSecondarySortByName = sortByAdditional(
    (a) => a.dest.name,
    sortDirectionFunction
  );
  const sortedSdmMatches = filteredSdmMatches.sort(
    sortDirectionSecondarySortByName
  );

  const sdmRows = sortedSdmMatches.map((sdmSite, index) => (
    <SdmRow
      site={site}
      sdmSite={sdmSite}
      key={index}
      annualExport={annualExport}
      gridImport={gridImport}
      isOwnerOccupied={isOwnerOccupied}
      setSelectedAddOffer={setSelectedAddOffer}
      minMarketplaceMatchSize={minMarketplaceMatchSize}
    />
  ));

  return (
    <Card body>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: "16px",
        }}
      >
        <h6 style={{ fontWeight: "bold" }}>
          Import/Export matches ({sdmRows.length})
        </h6>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "auto",
          }}
        >
          <Form.Check
            inline
            type="switch"
            label="Same exclusions as customer-facing"
            checked={filterCustomerFacing}
            onChange={() => setFilterCustomerFacing(!filterCustomerFacing)}
          />
          <Form.Check
            inline
            type="switch"
            label="Matching organisation"
            checked={filterMatchingOrg}
            onChange={() => setFilterMatchingOrg(!filterMatchingOrg)}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Form.Check
            inline
            type="switch"
            label="Bi-directional matches"
            checked={filterBiDirectionalMatches}
            onChange={() =>
              setFilterBiDirectionalMatches(!filterBiDirectionalMatches)
            }
          />
          <Form.Check
            inline
            type="switch"
            label="Matching DNO"
            checked={filterMatchDno}
            onChange={() => setFilterMatchDno(!filterMatchDno)}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Form.Group className="pb-2" controlId="matchThreshold">
            <Form.Label>Match threshold (kWh)</Form.Label>
            <Form.Control
              type="text"
              value={minMarketplaceMatchSize}
              onChange={(e) => {
                setMinMarketplaceMatchSize(e.target.value);
              }}
            />
          </Form.Group>
          <Button
            variant="primary"
            onClick={() => {
              saveSite({ minMarketplaceMatchSize: minMarketplaceMatchSize });
            }}
            disabled={site.minMarketplaceMatchSize === minMarketplaceMatchSize}
            style={{ padding: "0.375rem", width: "100%" }}
            size="sm"
          >
            Save threshold
          </Button>
        </div>
      </div>
      <Table bordered size="sm" style={{ margin: 0 }}>
        <thead>
          <tr style={{ verticalAlign: "top" }}>
            <th colSpan={2}></th>
            <th
              colSpan={5}
              onClick={() => {
                if (sortColumn === "sell") {
                  setSortDirection(sortDirection === "asc" ? "desc" : "asc");
                } else {
                  setSortDirection("desc");
                  setSortColumn("sell");
                }
              }}
            >
              Site to sell to:{" "}
              {sortColumn === "sell" && (
                <img
                  src={
                    sortDirection === "asc"
                      ? Icons.arrowsUpDownUp
                      : Icons.arrowsUpDownDown
                  }
                  alt=">"
                />
              )}
              {sortColumn !== "sell" && (
                <img src={Icons.arrowsUpDownNeither} alt=">" />
              )}
            </th>
            <th
              colSpan={5}
              onClick={() => {
                if (sortColumn === "buy") {
                  setSortDirection(sortDirection === "asc" ? "desc" : "asc");
                } else {
                  setSortDirection("desc");
                  setSortColumn("buy");
                }
              }}
            >
              Site to buy from:{" "}
              {sortColumn === "buy" && (
                <img
                  src={
                    sortDirection === "asc"
                      ? Icons.arrowsUpDownUp
                      : Icons.arrowsUpDownDown
                  }
                  alt=">"
                />
              )}
              {sortColumn !== "buy" && (
                <img src={Icons.arrowsUpDownNeither} alt=">" />
              )}
            </th>
          </tr>
          <tr style={{ verticalAlign: "top" }}>
            <th>Name, DNO/Substation</th>
            <th>Ownerships</th>
            <th>Amount</th>
            <th>Tariff</th>
            <th>IRR ({isOwnerOccupied ? "OO" : "LL"}, License)</th>
            <th>IRR Δ ({isOwnerOccupied ? "OO" : "LL"}, License)</th>
            <th></th>
            <th>Amount</th>
            <th>Proc. cost (y1)</th>
            <th>
              Savings (
              {isOwnerOccupied
                ? `OO, ${capitalize(site.activeInvestmentModel)}`
                : "Tenant"}
              , y1)
            </th>
            <th>
              Savings Δ (
              {isOwnerOccupied
                ? `OO, ${capitalize(site.activeInvestmentModel)}`
                : "Tenant"}
              , y1)
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>{sdmRows}</tbody>
      </Table>
    </Card>
  );
};
export default SdmOffers;

const SdmRow = ({
  site,
  sdmSite,
  index,
  gridImport,
  annualExport,
  isOwnerOccupied,
  setSelectedAddOffer,
  minMarketplaceMatchSize,
}) => {
  const sdmData = sdmSite;
  const destinationSite = sdmData.dest;
  const buyOffer = sdmData.buy;
  const sellOffer = sdmData.sell;

  const orgNames = destinationSite.orgs
    .sort()
    .map((name, index) => <div key={index}>• {name}</div>);

  const sellPerc =
    annualExport > 0 ? sellOffer.config.volume / annualExport : 0;
  const sellRemotePerc =
    destinationSite.gridImport > 0
      ? sellOffer.config.volume / destinationSite.gridImport
      : 0;
  const buyPerc = gridImport > 0 ? buyOffer.config.volume / gridImport : 0;
  const buyRemotePerc =
    destinationSite.exported > 0
      ? buyOffer.config.volume / destinationSite.exported
      : 0;

  const isSellOfferLargeEnoughForThisSite = sellOfferLargeEnoughForThisSite(
    sdmData,
    minMarketplaceMatchSize
  );
  const isSellOfferLargeEnoughForOtherSite =
    sellOfferLargeEnoughForOtherSite(sdmData);

  const isBuyOfferLargeEnoughForThisSite = buyOfferLargeEnoughForThisSite(
    sdmData,
    minMarketplaceMatchSize
  );
  const isBuyOfferLargeEnoughForOtherSite =
    buyOfferLargeEnoughForOtherSite(sdmData);

  return (
    <tr key={index}>
      <td>
        <Link
          to={`/site/${destinationSite.id}/sdm`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {destinationSite.name.length > 60
            ? destinationSite.name.substring(0, 60) + "..."
            : destinationSite.name}
        </Link>
        {destinationSite.is_scenario ? (
          <>
            <br />
            (Excluded from customer-facing SDM)
          </>
        ) : (
          ""
        )}
        <br />
        {destinationSite.dno} / {destinationSite.sub}
      </td>
      <td>{orgNames}</td>
      <td align="right">
        <div>{numString2dp(sellOffer.config.volume)} kWh</div>
        {sellOffer.config.volume > 0 && (
          <>
            <div
              style={{
                color: isSellOfferLargeEnoughForThisSite ? "#000" : "#F00",
              }}
            >
              {formatPercentage(sellPerc, 2)} of this site{"'"}s excess{" "}
              {!isSellOfferLargeEnoughForThisSite && (
                <div style={{ fontSize: 12, whiteSpace: "nowrap" }}>
                  (Less than: {numString2dp(minMarketplaceMatchSize)} kWh)
                </div>
              )}
            </div>
            <div
              style={{
                color: isSellOfferLargeEnoughForOtherSite ? "#000" : "#F00",
              }}
            >
              {formatPercentage(sellRemotePerc, 2)} of buyer{"'"}s unmet demand{" "}
              {!isSellOfferLargeEnoughForOtherSite && (
                <div style={{ fontSize: 12, whiteSpace: "nowrap" }}>
                  (Less than:{" "}
                  {numString2dp(destinationSite.minMarketplaceMatchSize)} kWh )
                </div>
              )}
            </div>
          </>
        )}
      </td>
      <td align="right">
        {sellOffer.config.volume === 0
          ? "N/A"
          : sellOffer.config.tariff.toFixed(4)}
      </td>
      {!isOwnerOccupied && (
        <>
          <td align="right">
            {sellOffer.config.volume === 0
              ? "N/A"
              : formatPercentage(
                  sellOffer.financialModels.landlord.license.irr,
                  2
                )}
          </td>
          <td align="right">
            {sellOffer.config.volume === 0
              ? "N/A"
              : "+" +
                formatPercentage(
                  sellOffer.financialModels.landlord.license.irr -
                    site.financialModels.landlord.license.irr,
                  2
                )}
          </td>
        </>
      )}
      {isOwnerOccupied && (
        <>
          <td align="right">
            {sellOffer.config.volume === 0
              ? "N/A"
              : formatPercentage(
                  sellOffer.financialModels.ownerOccupier.license.irr,
                  2
                )}
          </td>
          <td align="right">
            {sellOffer.config.volume === 0
              ? "N/A"
              : "+" +
                formatPercentage(
                  sellOffer.financialModels.ownerOccupier.license.irr -
                    site.financialModels.ownerOccupier.license.irr,
                  2
                )}
          </td>
        </>
      )}
      <td>
        <Button
          variant="primary"
          onClick={() => {
            setSelectedAddOffer(sellOffer.config);
          }}
          disabled={sellOffer.config.volume === 0}
          style={{ padding: "0.375rem 1rem", width: "100%" }}
          size="sm"
        >
          Sell
        </Button>
      </td>
      <td align="right">
        <div>{numString2dp(buyOffer.config.volume)} kWh</div>
        {buyOffer.config.volume > 0 && (
          <>
            <div
              style={{
                color: isBuyOfferLargeEnoughForThisSite ? "#000" : "#F00",
              }}
            >
              {formatPercentage(buyPerc, 2)} of this site{"'"}s unmet demand{" "}
              {!isBuyOfferLargeEnoughForThisSite && (
                <div style={{ fontSize: 12, whiteSpace: "nowrap" }}>
                  (Less than: {numString2dp(minMarketplaceMatchSize)} kWh )
                </div>
              )}
            </div>
            <div
              style={{
                color: isBuyOfferLargeEnoughForOtherSite ? "#000" : "#F00",
              }}
            >
              {formatPercentage(buyRemotePerc, 2)} of seller{"'"}s excess{" "}
              {!isBuyOfferLargeEnoughForOtherSite && (
                <div style={{ fontSize: 12, whiteSpace: "nowrap" }}>
                  (Less than:{" "}
                  {numString2dp(destinationSite.minMarketplaceMatchSize)} kWh )
                </div>
              )}
            </div>
          </>
        )}
      </td>
      <td align="right">
        {buyOffer.config.volume === 0
          ? "N/A"
          : formatCurrency(
              buyOffer.config.volume * buyOffer.config.tariff,
              site.currencyCode,
              2
            )}
      </td>
      {isOwnerOccupied && (
        <>
          <td align="right">
            {calculateSavings(buyOffer, site, "ownerOccupier")}
          </td>
          <td align="right">
            {calculateSavingsDelta(buyOffer, site, "ownerOccupier")}
          </td>
        </>
      )}
      {!isOwnerOccupied && (
        <>
          <td align="right">{calculateSavings(buyOffer, site, "tenant")}</td>
          <td align="right">
            {calculateSavingsDelta(buyOffer, site, "tenant")}
          </td>
        </>
      )}
      <td>
        <Button
          variant="primary"
          onClick={() => {
            setSelectedAddOffer(buyOffer.config);
          }}
          disabled={buyOffer.config.volume === 0}
          style={{ padding: "0.375rem 1rem", width: "100%" }}
          size="sm"
        >
          Buy
        </Button>
      </td>
    </tr>
  );
};

const calculateSavings = (buyOpportunity, site, ownership) => {
  const isTenant = ownership === "tenant";
  const savingsYearOne = isTenant
    ? buyOpportunity.financialModels[ownership].savings
    : buyOpportunity.financialModels[ownership][site.activeInvestmentModel]
        .savings;

  if (buyOpportunity.config.volume === 0) {
    return "N/A";
  } else {
    return formatCurrency(savingsYearOne, site.currencyCode, 2, false);
  }
};

const calculateSavingsDelta = (buyOpportunity, site, ownership) => {
  const isTenant = ownership === "tenant";
  const savingsYearOne = isTenant
    ? buyOpportunity.financialModels[ownership].savings
    : buyOpportunity.financialModels[ownership][site.activeInvestmentModel]
        .savings;

  if (buyOpportunity.config.volume === 0) {
    return "N/A";
  } else {
    return (
      "+" +
      formatCurrency(
        savingsYearOne -
          (isTenant
            ? site.financialModels[ownership].savings
            : site.financialModels[ownership][site.activeInvestmentModel]
                .savings),
        site.currencyCode,
        2,
        false
      )
    );
  }
};
