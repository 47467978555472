import { FC, useRef } from "react";
import { colors, colorsWithBattery } from ".";
import BarChart from "./BarChart";

import { ChartToolTip, ChartToolTipBody } from "../ChartTooltip";
import { NonOverflowTooltip } from "./NonOverflowTooltip";

import { BarDatum, BarTooltipProps } from "@nivo/bar";
import { formatUnitsToNearestTen } from "../../formatting/formatKiloUnits";
import TabButtons from "../buttons/TabButtons";
import { ChartData } from "./chart-types";
import {
  Chart,
  ChartHeader,
  ChartHeaderWithTabs,
  ChartLegend,
  ChartWrapper,
} from "./common";

interface YearDayEnergyChartProps {
  header?: string;
  chartData: ChartData[];
  barColors?: string[];
  legendKeys?: Record<string, string>;
  energyProcuredChartPeriod: "year" | "summer" | "winter";
  setEnergyProcuredChartPeriod: (period: "year" | "summer" | "winter") => void;
  height?: string;
  chartHeight?: string;
  isGeneratingSite: boolean;
  isBatterySite: boolean;
  $padding?: string;
  tooltipFontSize?: string;
}

const YearDayEnergyChart: FC<YearDayEnergyChartProps> = ({
  header,
  chartData,
  barColors,
  legendKeys,
  energyProcuredChartPeriod, // ["year", "summer", "winter"]
  setEnergyProcuredChartPeriod,
  height,
  chartHeight,
  isGeneratingSite,
  isBatterySite,
  $padding,
  tooltipFontSize,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  if (!barColors) {
    barColors = isBatterySite ? colorsWithBattery : colors;
  }
  if (!legendKeys) {
    legendKeys = Object.keys(chartData[0])
      .filter((key) => key !== "index")
      .reduce(
        (acc, key) => {
          acc[key] = key;
          return acc;
        },
        {} as Record<string, string>
      );
    if (!isGeneratingSite) {
      barColors = colors.slice(1);
      delete legendKeys["On-site behind the meter"];
    }
  }

  const ToolTipData: FC<{ data: BarDatum }> = ({ data }) => {
    return (
      <>
        {Object.keys(legendKeys).map((key, index) => (
          <ChartToolTipBody key={index} $fontSize={tooltipFontSize}>
            {legendKeys[key]}:{" "}
            <strong>{formatUnitsToNearestTen(data[key], "kWh")}</strong>
          </ChartToolTipBody>
        ))}
      </>
    );
  };

  const showToolTipFn = (e: BarTooltipProps<BarDatum>) => {
    const eventCoords = e as unknown as { x: number; y: number };
    return (
      <NonOverflowTooltip
        point={{ x: eventCoords.x, y: eventCoords.y }}
        containerSize={ref.current!.getBoundingClientRect()}
      >
        <ChartToolTip>
          <ToolTipData data={e.data} />
        </ChartToolTip>
      </NonOverflowTooltip>
    );
  };

  return (
    <ChartWrapper ref={ref} height={height} $padding={$padding}>
      <ChartHeaderWithTabs>
        <div>
          {header && (
            <>
              <ChartHeader>{header}</ChartHeader>
            </>
          )}
        </div>
        <TabButtons
          tab1={{
            label: "Year 1",
            active: energyProcuredChartPeriod === "year",
            onClick: () => setEnergyProcuredChartPeriod("year"),
          }}
          tab2={{
            label: "Daily (Summer)",
            active: energyProcuredChartPeriod === "summer",
            onClick: () => setEnergyProcuredChartPeriod("summer"),
          }}
          tab3={{
            label: "Daily (Winter)",
            active: energyProcuredChartPeriod === "winter",
            onClick: () => setEnergyProcuredChartPeriod("winter"),
          }}
        />
      </ChartHeaderWithTabs>
      <Chart $height={chartHeight}>
        {Object.keys(legendKeys).length > 1 && (
          <ChartLegend
            chartLegend={Object.keys(legendKeys).map((key, index) => ({
              label: legendKeys[key],
              color: barColors![index],
            }))}
          />
        )}
        <BarChart
          chartData={chartData as BarDatum[]}
          barColors={barColors}
          tooltipFn={showToolTipFn}
          keys={Object.keys(legendKeys)}
          legend={"kWh"}
          stacked={true}
          tickRotationProp={energyProcuredChartPeriod === "year" ? 0 : 90}
        />
      </Chart>
    </ChartWrapper>
  );
};

export default YearDayEnergyChart;
