import { useSite } from "@inrange/building-manager-api-client";
import {
  Modal,
  ModalView,
  firstNameFromEmail,
} from "@inrange/theme-components";
import { isEqual } from "lodash";
import { DateTime } from "luxon";
import { useMemo, useState } from "react";
import { Button, Table } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import ACTIVITY_LOG_MAP from "./ActivityLogMap";

const timeFormats = {
  full: {
    day: "numeric",
    year: "numeric",
    month: "short",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZone: "UTC",
    timeZoneName: "short",
  },
  date: {
    day: "numeric",
    year: "numeric",
    month: "short",
  },
};

const timeDetails = {
  hubspotUpdateTime: "full",
  pvDesignSystemLastUpdatedAt: "full",
  commerciallyOperationalDateEpochSeconds: "date",
  leaseLengthDateEpochSeconds: "date",
};

const testMode = !import.meta.env.PROD && import.meta.env.VITE_TEST_MODE;

const timeFromEpochString = (time, value = "") => {
  if (testMode) {
    return "(fixed-time-for-testing)";
  }
  const intTime = Number(time);
  const dateTime = DateTime.fromSeconds(intTime)
    .toUTC()
    .toLocaleString(timeFormats[timeDetails[value]] || timeFormats.full);
  return dateTime;
};

const formatSdmMatches = (siteId, formatFieldName, detail) => {
  const testMode = !import.meta.env.PROD && import.meta.env.VITE_TEST_MODE;
  if (detail.length === 0) {
    return "[]";
  }
  return (
    <ul>
      {detail.map((item, index) => {
        const isBuy = item["buyerId"] === siteId;
        if (testMode) {
          if (item["buyerId"]) {
            item["buyerId"] = "fixed-id-for-testing";
          }
          if (item["sellerId"]) {
            item["sellerId"] = "fixed-id-for-testing";
          }
        }
        return (
          <li key={index}>
            {isBuy ? "Buy match" : "Sell match"}
            <ul>
              {Object.entries(item)
                .sort(([a, _], [b, __]) => {
                  const aName = formatFieldName(`sdmMatches.${a}`);
                  const bName = formatFieldName(`sdmMatches.${b}`);
                  return aName.localeCompare(bName);
                })
                .map(([key, value]) => (
                  <li key={key}>
                    {formatFieldName(`sdmMatches.${key}`)}: {value}
                  </li>
                ))}
            </ul>
          </li>
        );
      })}
    </ul>
  );
};

const formatDetail = (siteId, formatFieldName, fieldName, detail) => {
  if (typeof detail === "boolean") {
    return String(detail);
  } else if (fieldName in timeDetails) {
    return detail ? timeFromEpochString(detail, fieldName) : "(no value)";
  } else if (Array.isArray(detail)) {
    if (fieldName === "sdmMatches") {
      return formatSdmMatches(siteId, formatFieldName, detail);
    } else {
      if (typeof detail[0] === "object") {
        return (
          <ol>
            {detail.map((item, index) => {
              return (
                <li key={index}>
                  {JSON.stringify(item, undefined, 1).replaceAll('"', "")}
                </li>
              );
            })}
          </ol>
        );
      }
      return `[${detail.join(", ")}]`;
    }
  }
  if (detail !== null && typeof detail === "object") {
    return JSON.stringify(detail, undefined, 1) || "(no value)";
  }
  return detail || "(no value)";
};

const removeUnchangedArrayItems = (detail) => {
  const beforeArray = detail.before || [];
  const afterArray = detail.after || [];
  // This implementation is O(n^2) but the arrays are expected to be small
  // A better implementation would hash the values and compare the hashes
  // but this would be slightly more complex code and not worth it for small arrays
  const commonItems = beforeArray.filter((item) =>
    afterArray.some((otherItem) => isEqual(item, otherItem))
  );
  detail.before = beforeArray.filter(
    (item) => !commonItems.some((otherItem) => isEqual(item, otherItem))
  );
  detail.after = afterArray.filter(
    (item) => !commonItems.some((otherItem) => isEqual(item, otherItem))
  );
};

const ActivityLog = ({ siteId, createdAt, createdByName, createdByEmail }) => {
  const [showModal, setShowModal] = useState(false);
  const [logItem, setLogItem] = useState({
    updatedByName: "",
    time: "",
    recalculated: {},
    userChange: {},
  });
  const { LOSS_FACTORS_BY_KEY, fetchSiteLog } = useSite({
    siteId,
    app: "admin",
  });
  const logData = fetchSiteLog.data.siteLog;

  useMemo(() => {
    for (const item of logData) {
      for (const [fieldName, detail] of Object.entries(item.userChange)) {
        if (fieldName === "sdmMatches") {
          removeUnchangedArrayItems(detail);
        }
      }
    }
  }, [logData]);

  const formatFieldName = (fieldName) => {
    if (fieldName.startsWith("generationLossFactors.")) {
      const key = fieldName.replace("generationLossFactors.", "");
      return LOSS_FACTORS_BY_KEY[key]?.label || fieldName;
    } else if (ACTIVITY_LOG_MAP[fieldName]) {
      return ACTIVITY_LOG_MAP[fieldName];
    } else if (fieldName.indexOf(".") !== -1) {
      // For older fields we only have the friendly name mapped to the last part
      // of the changed field name path because we didn't use to include the full path.
      // This code handles falling back to the last part of the path.
      const lastFieldNamePart = fieldName.split(".").slice(-1)[0];
      if (ACTIVITY_LOG_MAP[lastFieldNamePart]) {
        return ACTIVITY_LOG_MAP[lastFieldNamePart];
      }
    }
    // If a friendly name has not been provided, render the raw field path
    return fieldName;
  };

  const sortFields = ([a, _], [b, __]) => {
    if (
      a.startsWith("generationLossFactors.") &&
      b.startsWith("generationLossFactors.")
    ) {
      const keyA = a.replace("generationLossFactors.", "");
      const keyB = b.replace("generationLossFactors.", "");
      return LOSS_FACTORS_BY_KEY[keyA].index - LOSS_FACTORS_BY_KEY[keyB].index;
    } else {
      const aName = formatFieldName(a);
      const bName = formatFieldName(b);
      return aName.localeCompare(bName);
    }
  };

  const activityLogRows = logData.map((item, index) => (
    <tr
      key={index}
      onClick={() => {
        setLogItem(item), setShowModal(true);
      }}
    >
      <td>{item.updatedByName}</td>
      <td>{timeFromEpochString(item.time)}</td>
      <td>
        <div>
          {Object.entries(item.userChange)
            .sort(sortFields)
            .map(([fieldName, detail], index) => (
              <div key={index}>
                {formatFieldName(fieldName)}:
                {formatDetail(
                  siteId,
                  formatFieldName,
                  fieldName,
                  detail.before
                )}{" "}
                →{" "}
                {formatDetail(siteId, formatFieldName, fieldName, detail.after)}
              </div>
            ))}
        </div>
      </td>
    </tr>
  ));

  const userChangesLogDetailRows = Object.entries(logItem.userChange)
    .sort(sortFields)
    .map(([fieldName, detail], index) => (
      <ActivityDetailRow
        siteId={siteId}
        formatFieldName={formatFieldName}
        fieldName={fieldName}
        detail={detail}
        key={index}
      />
    ));

  const recalulatedLogDetailRows = Object.entries(logItem.recalculated)
    .sort(sortFields)
    .map(([fieldName, detail], index) => (
      <ActivityDetailRow
        fieldName={fieldName}
        formatFieldName={formatFieldName}
        detail={detail}
        key={index}
      />
    ));

  return (
    <Card body className="mt-2">
      <h6 style={{ fontWeight: "bold" }}>Activity log</h6>
      <Card.Text className="text-secondary">
        {`Created on ${timeFromEpochString(createdAt)} by ${
          createdByName ?? firstNameFromEmail(createdByEmail)
        }`}
      </Card.Text>
      <div style={{ maxHeight: 300, overflow: "scroll" }}>
        <Table striped bordered hover style={{ fontSize: "90%" }}>
          <thead>
            <tr>
              <th>User</th>
              <th>Last modified date</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>{activityLogRows}</tbody>
        </Table>
      </div>
      {showModal ? (
        <Modal>
          <ModalView
            title={
              logItem.updatedByName + " on " + timeFromEpochString(logItem.time)
            }
            fontWeight="500"
            fontSize="20px"
            minHeight="450px"
          >
            <hr></hr>
            <div
              style={{
                overflow: "auto",
              }}
            >
              <Table
                striped
                bordered
                hover
                style={{ fontSize: "90%", width: "651px" }}
              >
                <thead>
                  <tr>
                    <th onClick={() => setShowModal(true)}>Value</th>
                    <th>Before</th>
                    <th>After</th>
                  </tr>
                </thead>
                <tbody>{userChangesLogDetailRows}</tbody>
              </Table>
              {recalulatedLogDetailRows && (
                <>
                  <h6 style={{ fontWeight: "bold" }}>Recalculations</h6>
                  <Table
                    striped
                    bordered
                    hover
                    style={{
                      fontSize: "90%",
                      width: "651px",
                    }}
                  >
                    <thead>
                      <tr>
                        <th onClick={() => setShowModal(true)}>Value</th>
                        <th>Before</th>
                        <th>After</th>
                      </tr>
                    </thead>
                    <tbody>{recalulatedLogDetailRows}</tbody>
                  </Table>
                </>
              )}
            </div>
            <hr></hr>
            <div style={{ textAlign: "right" }}>
              <Button variant="primary" onClick={() => setShowModal(false)}>
                Close
              </Button>
            </div>
          </ModalView>
        </Modal>
      ) : null}
    </Card>
  );
};

const ActivityDetailRow = ({
  siteId,
  formatFieldName,
  fieldName,
  detail,
  index,
}) => {
  const detailBefore = formatDetail(
    siteId,
    formatFieldName,
    fieldName,
    detail.before
  );
  const detailAfter = formatDetail(
    siteId,
    formatFieldName,
    fieldName,
    detail.after
  );
  return (
    <tr key={index}>
      <td style={{ width: "40%" }}>{formatFieldName(fieldName)}</td>
      <td>{detailBefore}</td>
      <td>{detailAfter}</td>
    </tr>
  );
};

export default ActivityLog;
