import { mapboxKey } from "@inrange/building-manager-api-client";
import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import "./address.css";

const MapAddressBar = ({ initialValue, addressCallback }) => {
  const [suggestions, setSuggestions] = useState([]);
  const [input, setInput] = useState(initialValue || "");

  const suggestionClinkHandler = (suggestion) => {
    setInput(suggestion.place_name);
    setSuggestions([]);
    addressCallback(suggestion);
  };

  const mapbox_address_search = (input) => {
    return `https://api.mapbox.com/geocoding/v5/mapbox.places/${input}.json?access_token=${mapboxKey}&autocomplete=true`;
  };
  const searchAddress = (e) => {
    const input = e.target.value;
    setInput(input);
    fetch(mapbox_address_search(input))
      .then((res) => res.json())
      .then((data) => {
        setSuggestions(data?.features || []);
      });
  };

  useEffect(() => {
    if (!initialValue) return;
    setInput(initialValue);
  }, [initialValue]);

  return (
    <div className="s_wrap">
      <div className="s_content">
        <Form.Control
          placeholder="Enter an address"
          className="s_address"
          type="text"
          value={input}
          onChange={searchAddress}
        />
        <div className="s_list">
          {suggestions.map((suggestion) => (
            <div
              key={suggestion.id}
              className="s_row"
              onClick={() => {
                suggestionClinkHandler(suggestion);
              }}
            >
              {suggestion.place_name}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MapAddressBar;

// <MapAddressBar
//               addressCallback={addressCallback}
//               initialValue={site?.address}
//             />
