import Table from "react-bootstrap/Table";

import { Icons } from "@inrange/theme-components/icons";
import "bootstrap/dist/css/bootstrap.min.css";
import { FormEvent, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";

import {
  ColumnDef,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

interface TableData {
  [key: string]: unknown;
}

interface ColumnFilter {
  id: string;
  value: unknown;
}
type ColumnFiltersState = ColumnFilter[];

interface ListTableProps {
  columns: ColumnDef<TableData>[];
  loading: boolean;
  data: TableData[];
  title: string;
  columnFilters: ColumnFiltersState;
  customFilterElements: Array<JSX.Element>;
  sortingFns?: Record<string, (a: unknown, b: unknown) => number>;
}

const testMode = !import.meta.env.PROD && import.meta.env.VITE_TEST_MODE;

const ListTable = ({
  columns,
  loading,
  data,
  title,
  sortingFns,
  columnFilters,
  customFilterElements,
}: ListTableProps) => {
  const [sorting, setSorting] = useState<SortingState>([
    { id: columns[0].id ?? "", desc: false },
  ]);
  const [filtering, setFiltering] = useState("");

  const table = useReactTable({
    data,
    columns,
    sortingFns: sortingFns ?? {},
    state: {
      sorting,
      columnFilters,
      globalFilter: filtering,
    },
    enableSortingRemoval: false,
    onSortingChange: setSorting,
    onGlobalFilterChange: setFiltering,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const handleFormSubmit = (e: FormEvent) => {
    e.preventDefault();
  };

  return (
    <>
      <div>
        <Row style={{ marginBottom: 32, marginTop: 32 }}>
          <Col>
            <h3>
              {title} (
              {testMode
                ? "fixed-count-for-test"
                : loading
                  ? "..."
                  : table.getRowModel().rows.length}
              )
            </h3>
          </Col>
          <Col>{customFilterElements}</Col>
          <Col>
            <Form onSubmit={handleFormSubmit}>
              <Form.Control
                type="search"
                placeholder="Search"
                value={filtering ?? ""}
                onChange={(e) => setFiltering(String(e.target.value))}
              />
            </Form>
          </Col>
        </Row>
      </div>
      <Table striped bordered hover responsive>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  style={{ width: header.getSize() }}
                >
                  <div
                    {...{
                      style: header.column.getCanSort()
                        ? { cursor: "pointer", userSelect: "none" }
                        : {},
                      onClick: header.column.getToggleSortingHandler(),
                    }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    {header.column.getIsSorted() ? (
                      <img
                        alt="view"
                        src={
                          {
                            asc: Icons.sortAsc,
                            desc: Icons.sortDesc,
                          }[header.column.getIsSorted() as string]
                        }
                        style={{
                          width: "15px",
                          height: "15px",
                          marginLeft: "8px",
                          marginBottom: "2px",
                          cursor: "pointer",
                        }}
                      ></img>
                    ) : null}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default ListTable;
