import axiosConfig from "./axiosConfig";

const getSiteFileUploadUrl = async (
  authToken,
  siteId,
  siteFileType,
  contentType,
  fileName
) => {
  const { data } = await axiosConfig.post(
    `/site/${siteId}/file`,
    {
      fileName,
      contentType,
      siteFileType,
    },
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  );
  return data;
};

export default getSiteFileUploadUrl;
