import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useQuery } from "react-query";
import getSiteSdmList from "../http/getSiteSdmList";
import { BuyOfferEnergyFlows, SdmOffer } from "../models/site";

interface UseSiteSdmProps {
  siteId?: string;
  getAllResults?: boolean;
  onlyLinkedOrgs?: boolean;
  userOrgId: string;
}

const useSiteSdm = ({
  siteId,
  getAllResults,
  onlyLinkedOrgs = false,
  userOrgId,
}: UseSiteSdmProps) => {
  const { getToken, getClaim } = useKindeAuth();
  const kindeTokenOrgId = getClaim("external_org_id")?.value;
  const userOrgIdToUse =
    kindeTokenOrgId === "inrange.io" ? userOrgId : undefined;

  const fetchSiteSdmList = useQuery<{
    result: {
      buy?: SdmOffer;
      sell?: SdmOffer;
    };
    buyOfferEnergyFlows?: BuyOfferEnergyFlows;
  }>(
    ["site", siteId, "sdmList"],
    async () => {
      return getSiteSdmList(
        await getToken(),
        siteId,
        getAllResults,
        onlyLinkedOrgs,
        userOrgIdToUse
      );
    },
    { enabled: !!siteId }
  );

  const bestBuyOffer = fetchSiteSdmList.data?.result?.buy;
  const bestSellOffer = fetchSiteSdmList.data?.result?.sell;
  const buyOfferEnergyFlows = fetchSiteSdmList.data?.buyOfferEnergyFlows;

  return {
    fetchSiteSdmList,
    bestBuyOffer,
    bestSellOffer,
    buyOfferEnergyFlows,
  };
};

export default useSiteSdm;
