const getCustomerAppDomain = () => {
  const adminDomain = document.location.hostname;
  return adminDomain === "localhost"
    ? "http://localhost:3001"
    : "https://" +
        (adminDomain.endsWith(".inrange.dev")
          ? adminDomain.replace(/^admin-/, "customer-")
          : "app.inrange.io");
};

export default getCustomerAppDomain;
