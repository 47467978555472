import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { useSite } from "@inrange/building-manager-api-client";

const SiteDebug = () => {
  const [buyerSiteId, setBuyerSiteId] = useState("");
  const [sellerSiteId, setSellerSiteId] = useState("");
  const { siteId } = useParams();
  const { fetchSiteEnergyFlow, fetchSiteSdmEnergyFlow } = useSite({
    siteId,
    app: "admin",
  });

  return (
    <>
      <h4>Debug Actions for Site ID: {siteId}</h4>
      <Row className="mt-3">
        <Col sm={3} className="d-grid">
          <Button onClick={() => fetchSiteEnergyFlow.mutate(false)}>
            Download Site Energy Flow (HH)
          </Button>
        </Col>
        <Col sm={3} className="d-grid">
          <Button onClick={() => fetchSiteEnergyFlow.mutate(true)}>
            Download Site Energy Flow (Daily)
          </Button>
        </Col>
      </Row>
      <Row className="mt-3">
        <hr />
      </Row>
      <Row>
        <Col sm={3} className="d-grid">
          <Form.Group className="mb-3" controlId="buyerId">
            <Form.Label>Buyer ID</Form.Label>
            <Form.Control
              type="text"
              value={buyerSiteId}
              onChange={(e) => setBuyerSiteId(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col sm={3} className="d-grid">
          <Form.Group className="mb-3" controlId="buyerId">
            <Form.Label>Seller ID</Form.Label>
            <Form.Control
              type="text"
              value={sellerSiteId}
              onChange={(e) => setSellerSiteId(e.target.value)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={3} className="d-grid">
          <Button
            onClick={() =>
              fetchSiteSdmEnergyFlow.mutate({
                buyerSiteId,
                sellerSiteId,
                groupByDay: false,
              })
            }
          >
            Download SDM Energy Flow (HH)
          </Button>
        </Col>
        <Col sm={3} className="d-grid">
          <Button
            onClick={() =>
              fetchSiteSdmEnergyFlow.mutate({
                buyerSiteId,
                sellerSiteId,
                groupByDay: true,
              })
            }
          >
            Download SDM Energy Flow (Daily)
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default SiteDebug;
