import "leaflet/dist/leaflet.css";
import React from "react";
import styled from "styled-components";
import "./map.css";

import { getMapboxUrl } from "@inrange/building-manager-api-client";
import { Feature, LineString, Polygon } from "geojson";
import { useState } from "react";
import {
  GeoJSON,
  LayerGroup,
  LayersControl,
  MapContainer,
  TileLayer,
} from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";

import Tooltip from "../Tooltip";

const GMAPS_API_KEY = import.meta.env?.VITE_GMAPS_API_KEY;

export interface StyledGeoJson {
  key: string;
  geoJson: Feature | LineString | Polygon;
  color?: string;
  fillColor?: string;
  fillOpacity?: number;
  weight?: number;
}

export interface StyledGeoJsonGroup {
  geoJsons: StyledGeoJson[];
  name?: string;
  key: string;
}

interface MapProps {
  width: string;
  height: string;
  zoom: number;
  lat: number;
  lon: number;
  styledGeoJsonGroups: StyledGeoJsonGroup[];
  setMap: (map: any) => void;
  useGoogleMapsBaseLayer: boolean;
  tooltipText?: string;
}

const Map: React.FC<MapProps> = ({
  width,
  height,
  zoom,
  lat,
  lon,
  styledGeoJsonGroups,
  setMap,
  useGoogleMapsBaseLayer,
  tooltipText,
}) => {
  const [tileLoadingLabel, setTileLoadingLabel] = useState("tile-loading");
  const maxZoom = 18;

  // We need this because ReactLeafletGoogleLayer doesn't declare the type parameter
  const ReactLeafletGoogleLayerFix =
    ReactLeafletGoogleLayer as React.ComponentType<{
      apiKey: string;
      type: string;
    }>;
  const GeoJSONFix = GeoJSON as React.ComponentType<{
    data: any;
    color: string;
    weight: number;
    fillColor?: string;
    fillOpacity: number;
  }>;

  const renderStyledGeoJson = (styledGeoJson: StyledGeoJson) => {
    return (
      <GeoJSONFix
        key={styledGeoJson.key}
        data={styledGeoJson.geoJson}
        color={styledGeoJson.color || "#DBA507"}
        weight={styledGeoJson.weight || 2}
        fillColor={styledGeoJson.fillColor}
        fillOpacity={styledGeoJson.fillOpacity || 0}
      />
    );
  };

  const showLayersControl = styledGeoJsonGroups.every(
    (styledGeoJsonGroup) => styledGeoJsonGroup.name !== undefined
  );

  return (
    <MapWrapper width={width} height={height} data-testid={tileLoadingLabel}>
      {tooltipText && (
        <PvTooltip>
          <Tooltip text={tooltipText} position={"bottomLeft"} />
        </PvTooltip>
      )}
      <MapContainer
        center={[lat, lon]}
        zoom={zoom}
        scrollWheelZoom={false}
        ref={setMap}
      >
        {showLayersControl && (
          <LayersControl>
            <LayersControl.BaseLayer name="MapBox satellite">
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url={getMapboxUrl("satellite-v9")}
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="Google streets">
              <ReactLeafletGoogleLayerFix
                apiKey={GMAPS_API_KEY}
                type={"roadmap"}
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="Google satellite" checked>
              <ReactLeafletGoogleLayerFix
                apiKey={GMAPS_API_KEY}
                type={"satellite"}
              />
            </LayersControl.BaseLayer>
            {styledGeoJsonGroups.map((styledGeoJsonGroup) => {
              return (
                <LayersControl.Overlay
                  name={styledGeoJsonGroup.name!}
                  checked
                  key={styledGeoJsonGroup.key}
                >
                  <LayerGroup>
                    {styledGeoJsonGroup.geoJsons.map(renderStyledGeoJson)}
                  </LayerGroup>
                </LayersControl.Overlay>
              );
            })}
          </LayersControl>
        )}
        {!showLayersControl && (
          <>
            {useGoogleMapsBaseLayer ? (
              <ReactLeafletGoogleLayerFix
                apiKey={GMAPS_API_KEY}
                type={"satellite"}
              />
            ) : (
              <TileLayer
                attribution='&copy; <a href="https://www.mapbox.com/">MapBox</a>'
                url={getMapboxUrl("clemysf5c005s01o9u6mw58qc", "tom-inrange")}
                maxZoom={maxZoom}
                eventHandlers={{
                  loading: () => setTileLoadingLabel("tile-loading"),
                  load: () => setTileLoadingLabel("tile-loaded"),
                }}
              />
            )}
            {styledGeoJsonGroups.map((styledGeoJsonGroup) => {
              return (
                <>{styledGeoJsonGroup.geoJsons.map(renderStyledGeoJson)}</>
              );
            })}
          </>
        )}
      </MapContainer>
    </MapWrapper>
  );
};

export default Map;

const MapWrapper = styled.div<{ width: string; height: string }>`
  ${({ width, height }) => `
    width: ${width};
    height: ${height};
  `}
`;

const PvTooltip = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: -8px;
`;
