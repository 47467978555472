import styled from "styled-components";

interface GridProps {
  $cols: number;
  $colwidth: string;
  $gap?: string;
  $margin?: string;
  $padding?: string;
  $height?: string;
  $width?: string;
  $minWidth?: string;
}

const Grid = styled.div<GridProps>`
  display: grid;
  ${(props) => `
    grid-template-columns: ${Array(props.$cols)
      .fill(props.$colwidth)
      .join(" ")};
    gap: ${props.$gap || "8px"};
  `}
  ${(props) =>
    props.$margin &&
    `
    margin: ${props.$margin};
  `}
  ${(props) =>
    props.$padding &&
    `
    padding: ${props.$padding};
  `}
  ${(props) =>
    props.$height &&
    `
    height: ${props.$height};
  `}
  ${(props) =>
    props.$width &&
    `
    width: ${props.$width};
  `}
  ${(props) =>
    props.$minWidth &&
    `
    min-width: ${props.$minWidth};
  `}
`;

export default Grid;
