export const csvHeaders = [
  { label: "Org ID", key: "orgID" },
  { label: "Site Org Link", key: "siteOrgLinkType" },
  { label: "Scenario Site", key: "scenarioSite" },
  { label: "Country Code", key: "countryCode" },
  { label: "Site ID", key: "id" },
  { label: "Name", key: "name" },
  { label: "Building Area", key: "totalBuildingArea" },
  { label: "Annual Generation (kWh)", key: "generation" },
  { label: "Initial cost/kWp", key: "initialCostPerKWp" },
  { label: "Currency", key: "currencyCode" },
  { label: "Roof Utilisation", key: "roofUtilization" },
  { label: "Annual Demand (kWh)", key: "demand" },
  { label: "Demand Source", key: "source" },
  { label: "Total Export", key: "exported" },
  { label: "Total Import", key: "gridImport" },
  { label: "Tenant Tariff", key: "tenantTariff" },
  { label: "Market Tariff", key: "marketTariff" },
  { label: "InRange Export Tariff", key: "inrangeExportTariff" },
  { label: "Landlord IRR", key: "landlordIRR" },
  { label: "Landlord Payback", key: "landlordPaybackMonths" },
  { label: "Owner Occupier IRR", key: "ownerOccupierIRR" },
  { label: "Owner Occupier Payback", key: "ownerOccupierPaybackMonths" },
  { label: "Installed Capacity", key: "installedCapacity" },
  { label: "DNO", key: "dno" },
  { label: "Substation", key: "substation" },
  { label: "Generation Headroom", key: "generationHeadroomKva" },
  { label: "Annual Tenant Share", key: "annualTenantShare" },
  { label: "Monthly Tenant Share Jan", key: "monthlyTenantShareJan" },
  { label: "Monthly Tenant Share Feb", key: "monthlyTenantShareFeb" },
  { label: "Monthly Tenant Share Mar", key: "monthlyTenantShareMar" },
  { label: "Monthly Tenant Share Apr", key: "monthlyTenantShareApr" },
  { label: "Monthly Tenant Share May", key: "monthlyTenantShareMay" },
  { label: "Monthly Tenant Share Jun", key: "monthlyTenantShareJun" },
  { label: "Monthly Tenant Share Jul", key: "monthlyTenantShareJul" },
  { label: "Monthly Tenant Share Aug", key: "monthlyTenantShareAug" },
  { label: "Monthly Tenant Share Sep", key: "monthlyTenantShareSep" },
  { label: "Monthly Tenant Share Oct", key: "monthlyTenantShareOct" },
  { label: "Monthly Tenant Share Nov", key: "monthlyTenantShareNov" },
  { label: "Monthly Tenant Share Dec", key: "monthlyTenantShareDec" },
  { label: "Address", key: "address" },
  { label: "OO Org ID", key: "ooOrgId" },
  { label: "OO Org Name", key: "ooOrgName" },
  { label: "Tenant Org ID", key: "tenantOrgId" },
  { label: "Tenant Org Name", key: "tenantOrgName" },
  { label: "Landlord Org ID", key: "landlordOrgId" },
  { label: "Landlord Org Name", key: "landlordOrgName" },
];

const getSiteOwnership = (siteOwnershipByType) => {
  let ooOrg = siteOwnershipByType["ownerOccupier"] || "Unknown";
  let tenantOrg = siteOwnershipByType["tenant"] || "Unknown";
  let landlordOrg = siteOwnershipByType["landlord"] || "Unknown";

  if (ooOrg !== "Unknown") {
    tenantOrg = tenantOrg !== "Unknown" ? tenantOrg : "OO";
    landlordOrg = landlordOrg !== "Unknown" ? landlordOrg : "OO";
  }

  if (tenantOrg !== "Unknown" || landlordOrg !== "Unknown") {
    ooOrg = ooOrg !== "Unknown" ? ooOrg : "Non-OO";
  }

  return [
    ooOrg?.orgID || ooOrg,
    ooOrg?.name || ooOrg,
    tenantOrg?.orgID || tenantOrg,
    tenantOrg?.name || tenantOrg,
    landlordOrg?.orgID || landlordOrg,
    landlordOrg?.name || landlordOrg,
  ];
};

export const formatCsvRow = (siteData, orgID, siteOrgLinkType) => {
  siteData["orgID"] = orgID;

  const siteOwnershipByType = siteData.siteOwnerships.reduce(
    (acc, ownership) => {
      acc[ownership.ownership] = ownership;
      return acc;
    },
    {}
  );
  const [
    ooOrgId,
    ooOrgName,
    tenantOrgId,
    tenantOrgName,
    landlordOrgId,
    landlordOrgName,
  ] = getSiteOwnership(siteOwnershipByType);

  return {
    orgID: siteData.orgID,
    siteOrgLinkType: siteOrgLinkType,
    scenarioSite: siteData.isScenario,
    countryCode: siteData.countryCode,
    id: siteData.id,
    name: siteData.name,
    totalBuildingArea: siteData.totalBuildingArea,
    generation: siteData.energyFlowAnnual.generation,
    initialCostPerKWp: siteData.projectCosts.initialCostPerKWp,
    currencyCode: siteData.currencyCode,
    roofUtilization: siteData.halfHourlyGeneration.roofUtilization,
    demand: siteData.energyFlowAnnual.demand,
    source: siteData.halfHourlyDemand.source,
    exported: siteData.energyFlowAnnual.exported,
    gridImport: siteData.energyFlowAnnual.gridImport,
    tenantTariff: siteData.tenantTariff,
    marketTariff: siteData.marketTariff,
    inrangeExportTariff: siteData.inrangeExportTariff,
    landlordIRR: siteData.financialModels.landlord.license.irr,
    landlordPaybackMonths:
      siteData.financialModels.landlord.license.paybackMonths,
    ownerOccupierIRR: siteData.financialModels.ownerOccupier.license.irr,
    ownerOccupierPaybackMonths:
      siteData.financialModels.ownerOccupier.license.paybackMonths,
    installedCapacity: siteData.installedCapacity,
    dno: siteData.dno.name,
    substation: siteData.substationData.name,
    generationHeadroomKva: siteData.substationData.generationHeadroomKva,
    annualTenantShare: siteData.energyFlowAnnual.tenantEnergyShare,
    monthlyTenantShareJan: siteData.monthlyTenantEnergyShare[0],
    monthlyTenantShareFeb: siteData.monthlyTenantEnergyShare[1],
    monthlyTenantShareMar: siteData.monthlyTenantEnergyShare[2],
    monthlyTenantShareApr: siteData.monthlyTenantEnergyShare[3],
    monthlyTenantShareMay: siteData.monthlyTenantEnergyShare[4],
    monthlyTenantShareJun: siteData.monthlyTenantEnergyShare[5],
    monthlyTenantShareJul: siteData.monthlyTenantEnergyShare[6],
    monthlyTenantShareAug: siteData.monthlyTenantEnergyShare[7],
    monthlyTenantShareSep: siteData.monthlyTenantEnergyShare[8],
    monthlyTenantShareOct: siteData.monthlyTenantEnergyShare[9],
    monthlyTenantShareNov: siteData.monthlyTenantEnergyShare[10],
    monthlyTenantShareDec: siteData.monthlyTenantEnergyShare[11],
    address: siteData.address,
    ooOrgId,
    ooOrgName,
    tenantOrgId,
    tenantOrgName,
    landlordOrgId,
    landlordOrgName,
  };
};
