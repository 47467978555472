import React from "react";
import Button from "react-bootstrap/Button";

interface PvSystemSelectionControlsProps {
  anyDifference: boolean;
  designSelected: boolean;
  selectPvDesign: () => void;
  disableSelection: boolean;
  deselectPvDesign: () => void;
}

const PvSystemSelectionControls: React.FC<PvSystemSelectionControlsProps> = ({
  anyDifference,
  designSelected,
  selectPvDesign,
  disableSelection,
  deselectPvDesign,
}) => {
  return (
    <div className="mb-4 d-flex justify-content-between">
      <Button
        variant="success"
        onClick={selectPvDesign}
        disabled={disableSelection}
      >
        {anyDifference
          ? designSelected
            ? "Update design"
            : "Select design"
          : "Design selected"}
      </Button>
      <Button
        variant="success"
        onClick={deselectPvDesign}
        disabled={!designSelected}
      >
        Deselect design
      </Button>
    </div>
  );
};

export default PvSystemSelectionControls;
