import { Building } from "@inrange/building-manager-api-client/models-site";
import { Modal, ModalView } from "@inrange/theme-components";
import { Feature, GeoJsonProperties, LineString, Polygon } from "geojson";
import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  Row,
  ToggleButton,
} from "react-bootstrap";
import { ObjectGeometry } from "../PVSystem/PVSystem";
import PVCustomObjectsMap from "./PVCustomObjectsMap";

interface PVCustomObjectsModalProps {
  buildings: Building[];
  siteCenter: [number, number];
  customObjects: (Feature<Polygon> | Feature<LineString>)[] | undefined;
  setCustomObjects: (
    customObjects: (Feature<Polygon> | Feature<LineString>)[]
  ) => void;
  setShowModal: (show: boolean) => void;
  triggerCustomObjectsRebuild: (
    customObjects: (Feature<Polygon> | Feature<LineString>)[]
  ) => void;
}

const OBJECT_TYPE_LABELS = {
  skylight: "Skylight",
  hvac: "HVAC",
  roofedge: "Roof ridge",
  solarpanel: "Solar panel",
  other: "Other",
  autodetected: "Auto",
};

const PVCustomObjectsModal: React.FC<PVCustomObjectsModalProps> = ({
  buildings,
  siteCenter,
  customObjects,
  setCustomObjects,
  setShowModal,
  triggerCustomObjectsRebuild,
}) => {
  const [selectedObjectIDs, setSelectedObjectIDs] = useState<string[]>([]);
  const [drawMode, setDrawMode] = useState<string>("skylight");
  const [selectionTool, setSelectionTool] = useState<string>("rectangle");
  const [selectedType, setSetSelectedType] = useState<string>("select");
  const [newCustomObjects, setNewCustomObjects] = useState<{
    [key: string]: Feature<Polygon> | Feature<LineString>;
  }>(
    (customObjects || []).reduce(
      (acc, customObject) => {
        acc[customObject.properties!.id] = customObject;
        return acc;
      },
      {} as { [key: string]: Feature<Polygon> | Feature<LineString> }
    )
  );
  const [newCustomObjectsHistory, setNewCustomObjectsHistory] = useState<
    {
      state: {
        [key: string]: Feature<Polygon> | Feature<LineString>;
      };
      selected: string[];
    }[]
  >([]);
  const pushNewCustomObjectsHistory = () => {
    setNewCustomObjectsHistory((prevHistory) => {
      return [
        ...prevHistory.slice(-20, prevHistory.length),
        { state: { ...newCustomObjects }, selected: [...selectedObjectIDs] },
      ];
    });
  };
  const popNewCustomObjectsHistory = () => {
    if (newCustomObjectsHistory.length > 0) {
      const history = newCustomObjectsHistory.pop()!;
      setNewCustomObjects(history.state);
      setSelectedObjectIDs(history.selected);
      return history.selected;
    }
    return undefined;
  };

  const autodetectedObjectsCount = Object.values(newCustomObjects).filter(
    (obj) => obj.properties!.type === "autodetected"
  ).length;
  const customObjectsCount = Object.values(newCustomObjects).filter(
    (obj) => obj.properties!.type !== "autodetected"
  ).length;

  const onUseCustomObjects = () => {
    const customObjects = Object.values(newCustomObjects);
    setCustomObjects(customObjects);
    triggerCustomObjectsRebuild(customObjects);
    setShowModal(false);
  };

  const updateNewCustomObjects = (
    id: string,
    geometry: ObjectGeometry,
    properties: { [name: string]: any } = {}
  ) => {
    setNewCustomObjects((prevNewCustomObjects) => {
      const newCustomObjects = { ...prevNewCustomObjects };
      if (!newCustomObjects[id]) {
        const feature = {
          type: "Feature",
          geometry: geometry,
          properties: {
            ...properties,
            id: id,
          },
        } as Feature<Polygon> | Feature<LineString>;
        newCustomObjects[id] = feature;
      } else {
        const existingCustomObject = { ...newCustomObjects[id] };
        const oldGeometry = existingCustomObject.geometry;
        const oldSource = existingCustomObject.properties!.source;
        const newSource = properties.source;

        const updatedCustomObject = {
          ...existingCustomObject,
          geometry,
          properties: { ...existingCustomObject.properties, ...properties },
        };
        // Make sure manual edits to autodetected objects are counted as source = autodetected-edited
        if (
          newSource === "manual" &&
          (oldSource === "autodetected" || oldSource === "autodetected-edited")
        ) {
          updatedCustomObject.properties.source = "autodetected-edited";
          if (updatedCustomObject.properties.type === "autodetected") {
            // Mark manually edited autodetected objects as "other" type
            updatedCustomObject.properties.type = "other";
          }
        }
        // Store the original geometry in the properties for autodetected objects
        if (newSource === "manual" && oldSource === "autodetected") {
          updatedCustomObject.properties.originalGeometry = oldGeometry;
        }
        newCustomObjects[id] = updatedCustomObject as
          | Feature<Polygon, GeoJsonProperties>
          | Feature<LineString, GeoJsonProperties>;
      }
      return newCustomObjects;
    });
  };

  const deleteCustomObject = (id: string) => {
    setNewCustomObjects((prevNewCustomObjects) => {
      const newCustomObjects = { ...prevNewCustomObjects };
      delete newCustomObjects[id];
      return newCustomObjects;
    });
  };

  const onCancel = () => {
    setShowModal(false);
  };

  // Count the number of selected objects by type
  const selectedCountByType = useMemo(() => {
    return selectedObjectIDs.reduce(
      (acc, id) => {
        const object = newCustomObjects[id];
        if (object) {
          const type = object.properties!.type;
          if (!acc[type]) {
            acc[type] = 0;
          }
          acc[type]++;
        }
        return acc;
      },
      {} as { [key: string]: number }
    );
  }, [selectedObjectIDs, newCustomObjects]);

  const isSingleTypeSelected = Object.keys(selectedCountByType).length === 1;
  const singleSelectedType = isSingleTypeSelected
    ? Object.keys(selectedCountByType)[0]
    : undefined;

  // Set the selected type dropdown based on the selected objects
  useEffect(() => {
    if (
      isSingleTypeSelected &&
      singleSelectedType &&
      singleSelectedType !== "autodetected"
    ) {
      setSetSelectedType(singleSelectedType);
    } else {
      setSetSelectedType("select");
    }
  }, [isSingleTypeSelected, singleSelectedType]);

  // Generate a description of the selected objects
  const getSelectedDescription = () => {
    const descriptions: string[] = [];
    for (const type of [
      "skylight",
      "hvac",
      "roofedge",
      "solarpanel",
      "other",
      "autodetected",
    ]) {
      if (selectedCountByType[type]) {
        descriptions.push(
          `${selectedCountByType[type]} ${OBJECT_TYPE_LABELS[type]}`
        );
      }
    }
    if (descriptions.length > 0) {
      return descriptions.join(", ");
    }
    return "0";
  };

  const setSelectedType = () => {
    pushNewCustomObjectsHistory();
    for (const id of selectedObjectIDs) {
      updateNewCustomObjects(id, newCustomObjects[id].geometry, {
        type: selectedType,
      });
    }
  };

  return (
    <Modal>
      <ModalView width={"90%"}>
        <Row className="mb-2">
          <Col
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <div>Object type:</div>
              <ButtonGroup>
                <ToggleButton
                  id="object-skylight"
                  type="radio"
                  variant="inrange-skylight"
                  name="draw"
                  value={"skylight"}
                  checked={drawMode === "skylight"}
                  onChange={() => setDrawMode("skylight")}
                >
                  Skylight
                </ToggleButton>
                <ToggleButton
                  id="object-hvac"
                  type="radio"
                  variant="inrange-hvac"
                  name="draw"
                  value={"hvac"}
                  checked={drawMode === "hvac"}
                  onChange={() => setDrawMode("hvac")}
                >
                  HVAC
                </ToggleButton>
                <ToggleButton
                  id="object-roofedge"
                  type="radio"
                  variant="inrange-roofedge"
                  name="draw"
                  value={"roofedge"}
                  checked={drawMode === "roofedge"}
                  onChange={() => setDrawMode("roofedge")}
                >
                  Roof ridge
                </ToggleButton>
                <ToggleButton
                  id="object-solarpanel"
                  type="radio"
                  variant="inrange-solarpanel"
                  name="draw"
                  value={"solarpanel"}
                  checked={drawMode === "solarpanel"}
                  onChange={() => setDrawMode("solarpanel")}
                >
                  Solar panel
                </ToggleButton>
                <ToggleButton
                  id="object-other"
                  type="radio"
                  variant="inrange-other"
                  name="draw"
                  value={"other"}
                  checked={drawMode === "other"}
                  onChange={() => setDrawMode("other")}
                >
                  Other
                </ToggleButton>
              </ButtonGroup>
            </div>
            <div
              style={{
                marginLeft: "auto",
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <div>Selection tool:</div>
              <ButtonGroup>
                <ToggleButton
                  id="select-rectangle"
                  type="radio"
                  variant="primary"
                  name="select"
                  value={"rectangle"}
                  checked={selectionTool === "rectangle"}
                  onChange={() => setSelectionTool("rectangle")}
                >
                  Rectangle
                </ToggleButton>
                <ToggleButton
                  id="select-line"
                  type="radio"
                  variant="primary"
                  name="select"
                  value={"line"}
                  checked={selectionTool === "line"}
                  onChange={() => setSelectionTool("line")}
                >
                  Line
                </ToggleButton>
              </ButtonGroup>
            </div>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              alignItems: "center",
              gap: "16px",
            }}
          >
            <div>Selected: {getSelectedDescription()}</div>
            <Form.Group controlId="pvaObjectType">
              <Form.Select
                value={selectedType}
                onChange={(e) => setSetSelectedType(e.target.value)}
              >
                <option value={"select"}>Set to type</option>
                <option value={"skylight"}>Skylight</option>
                <option value={"hvac"}>HVAC</option>
                <option value={"roofedge"}>Roof ridge</option>
                <option value={"solarpanel"}>Solar panel</option>
                <option value={"other"}>Other</option>
              </Form.Select>
            </Form.Group>
            <Button
              variant="success"
              disabled={
                selectedType === "select" ||
                (isSingleTypeSelected && singleSelectedType === selectedType)
              }
              onClick={setSelectedType}
            >
              Apply
            </Button>
          </Col>
        </Row>
        <div>
          <PVCustomObjectsMap
            buildings={buildings}
            siteCenter={siteCenter}
            newCustomObjects={newCustomObjects}
            updateNewCustomObjects={updateNewCustomObjects}
            deleteCustomObject={deleteCustomObject}
            selectedObjectIDs={selectedObjectIDs}
            setSelectedObjectIDs={setSelectedObjectIDs}
            drawMode={drawMode}
            selectionTool={selectionTool}
            pushNewCustomObjectsHistory={pushNewCustomObjectsHistory}
            popNewCustomObjectsHistory={popNewCustomObjectsHistory}
          />
        </div>
        <Row>
          <Col>
            <Button
              variant="inrangesecondary"
              onClick={onCancel}
              style={{ width: "130px", height: "62px" }}
              data-testid="cancel-pv-custom-objects"
            >
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              variant="success"
              onClick={onUseCustomObjects}
              style={{ float: "right", width: "300px", height: "62px" }}
              data-testid="save-pv-custom-objects"
            >
              {`Use ${customObjectsCount} custom and ${autodetectedObjectsCount} auto objects, generate PV layout`}
            </Button>
          </Col>
        </Row>
      </ModalView>
    </Modal>
  );
};

export default PVCustomObjectsModal;
