/* eslint-disable react-refresh/only-export-components */
import { Feature, LineString, Polygon } from "geojson";
import * as L from "leaflet";
import "leaflet-editable";
import "leaflet.path.drag";
import "leaflet/dist/leaflet.css";
import React from "react";
import { GeoJSON, GeoJSONProps } from "react-leaflet";
import { getStyle } from "./pvcustomobjectmap-utils";

interface GeoCustomObjectsProps {
  newCustomObjects: Record<string, Feature<Polygon> | Feature<LineString>>;
  selectedObjectIDs: string[];
  onClick: (event: L.LeafletMouseEvent) => void;
}

const GeoJSONCustomObjects = GeoJSON as React.ComponentType<
  GeoJSONProps & {
    draggable: boolean;
    editable: boolean;
    id: string;
  }
>;

export const GeoCustomObjects: React.FC<GeoCustomObjectsProps> = ({
  newCustomObjects,
  selectedObjectIDs,
  onClick,
}) => {
  return Object.values(newCustomObjects)
    .filter(
      (customObject) =>
        selectedObjectIDs.length <= 1 ||
        !selectedObjectIDs.includes(customObject.properties!.id)
    )
    .map((customObject) => {
      const keyData = {
        id: customObject.properties!.id,
        coordinates: customObject.geometry.coordinates,
        source: customObject.properties!.source,
        type: customObject.properties!.type,
      };
      const isSingleSelectedObject =
        selectedObjectIDs.length === 1 &&
        selectedObjectIDs[0] === customObject.properties!.id;
      // Setting "key={"custom-object-" + customObject.properties!.id}" is critical to ensure that React knows the correct element to update
      // rather than simply using the array index which would cause React to update the wrong element.
      return (
        <GeoJSONCustomObjects
          key={JSON.stringify(keyData)}
          data={customObject}
          style={getStyle(
            customObject.properties!.type,
            isSingleSelectedObject
          )}
          eventHandlers={{
            click: onClick,
          }}
          draggable={isSingleSelectedObject ? false : true}
          editable={isSingleSelectedObject ? true : false}
          id={customObject.properties!.id}
        />
      );
    });
};
