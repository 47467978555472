import axiosConfig from "./axiosConfig";

const postReports = async (authToken) => {
  const { data } = await axiosConfig.post("/reports", undefined, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
  return data;
};

export default postReports;
