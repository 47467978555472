import axiosConfig from "./axiosConfig";

const getSiteList = async (authToken) => {
  const { data } = await axiosConfig.get("/site", {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
  return data;
};

export default getSiteList;
