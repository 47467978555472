import { Building } from "@inrange/building-manager-api-client/models-site";
import { Feature, LineString, Polygon } from "geojson";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

interface PvDesignControlsProps {
  setShowDebugData: (show: boolean) => void;
  showDebugData: boolean;
  setShowCustomObjectsModal: (show: boolean) => void;
  buildings: Building[];
  customObjects: (Feature<Polygon> | Feature<LineString>)[];
}

const PvDesignControls: React.FC<PvDesignControlsProps> = ({
  setShowDebugData,
  showDebugData,
  setShowCustomObjectsModal,
  buildings,
  customObjects,
}) => {
  const buildingCount = buildings.length;
  const customBuildingCount = buildings.filter(
    (b) => b.polygonType === "custom"
  ).length;
  const objectsCount = customObjects.length;
  const customObjectsCount = Object.values(customObjects).filter(
    (obj) => obj.properties!.type !== "autodetected"
  ).length;

  return (
    <Row style={{ alignItems: "center" }}>
      <Col>
        <Button
          variant="secondary"
          onClick={() => setShowDebugData(!showDebugData)}
          style={{ margin: "10px 0 5px 0" }}
        >
          {showDebugData ? "Hide" : "Show"} debug data
        </Button>
      </Col>
      <Col sm={4} md="auto">
        <Row
          style={{ display: "block", textAlign: "right" }}
          data-testid="custom-site-boundary-count"
        >
          {buildingCount} site boundaries ({customBuildingCount} custom)
        </Row>
        <Row
          style={{ display: "block", textAlign: "right" }}
          data-testid="custom-pv-object-count"
        >
          {objectsCount} objects ({customObjectsCount} custom)
        </Row>
      </Col>
      <Col sm={4} md="auto">
        <Button
          variant="success"
          onClick={() => setShowCustomObjectsModal(true)}
          style={{ margin: "10px 0 5px 0", float: "right" }}
          data-testid="customise-pv-custom-objects"
        >
          Edit objects
        </Button>
      </Col>
    </Row>
  );
};

export default PvDesignControls;
