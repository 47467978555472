import "leaflet/dist/leaflet.css";

export const MapStylesTest = {
  streets: "streets-v11",
  satellite: "satellite-v9",
};

export const MapStylesProd = {
  streets: "roadmap",
  satellite: "satellite",
};
