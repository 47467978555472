export const OFFTAKER_ONLY_STATUS_OPTIONS = {
  notStarted: "Not started",
  contractSigned: "Contract signed",
  operational: "Operational",
};

export const STATUS_OPTIONS = {
  notStarted: "Not started",
  contractSigned: "Contract signed",
  loaPending: "LOA pending",
  gridAppPendingSurveyInProgress: "Grid app pending; Survey in progress",
  gridAppPendingSurveyCompleted: "Grid app pending; Survey completed",
  constructionPrelimsInProgress: "Construction prelims in progress",
  installationRoofWorks: "Installation - roof works",
  installationElectrical: "Installation - electrical",
  witnessTestingInProgress: "Witness testing in progress",
  waitingOnDnoWitnessTest: "Waiting on DNO - Witness test",
  operational: "Operational",
};

export const STATUS_WEIGHTS = {
  notStarted: 0,
  contractSigned: 1,
  loaPending: 2,
  gridAppPendingSurveyInProgress: 3,
  gridAppPendingSurveyCompleted: 4,
  constructionPrelimsInProgress: 5,
  installationRoofWorks: 6,
  installationElectrical: 7,
  witnessTestingInProgress: 8,
  waitingOnDnoWitnessTest: 9,
  operational: 10,
};
