const BUILDING_PROFILES = {
  dataCenter: "Data center",
  distribution: "Distribution",
  manufacturing: "Manufacturing",
  retail: "Retail",
  retailRefrigerated: "Retail refrigerated",
  noDemand: "No demand",
  other: "Other",
};

export { BUILDING_PROFILES };
