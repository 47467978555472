import {
  createContext as _createContext,
  useContext as _useContext,
} from "react";

// https://stackoverflow.com/questions/61333188/react-typescript-avoid-context-default-value

export function useContextTS<T>(context: React.Context<T>) {
  const value = _useContext(context);
  if (value === undefined) throw new Error("context must be within provider");
  return value;
}

export function createContextTS<T>(value: T | undefined = undefined) {
  return _createContext<T | undefined>(value);
}
