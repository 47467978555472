import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import getReports from "../http/getReports";
import postReports from "../http/postReports";

const useReports = (refetchInterval) => {
  const queryClient = useQueryClient();
  const { getToken } = useKindeAuth();

  const fetchReports = useQuery(
    "reports",
    async () => {
      return getReports(await getToken());
    },
    { refetchInterval: refetchInterval }
  );

  const invokeReports = useMutation(async () => postReports(await getToken()), {
    onSuccess: () => {
      queryClient.invalidateQueries("reports");
    },
  });

  return {
    invokeReports,
    fetchReports,
  };
};

export default useReports;
