import { useOrganisationList } from "@inrange/building-manager-api-client";
import { Modal, ModalView } from "@inrange/theme-components";
import { useSearchParams } from "react-router-dom";

import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import CreateOrg from "../../../org/CreateOrg";
import { BUILDING_PROFILES } from "../../../sharedEnums";
import { SITE_ORG_LABELS } from "../enums";

const calcOrgType = (newSelectedID, organisations) => {
  const org = organisations.find((o) => o.id === newSelectedID);
  const orgIsLandlord = org?.orgType === "Landlord";
  const orgIsTenant = org?.orgType === "Tenant";
  return orgIsLandlord ? "landlord" : orgIsTenant ? "tenant" : "";
};

const AddOwnership = ({
  site,
  ownerships,
  setOwnerships,
  setNewSiteName,
  onBuildingProfileChange,
  error,
}) => {
  const { fetchOrganisations } = useOrganisationList();

  const refetchOrganisations = async () => {
    await fetchOrganisations.refetch();
  };

  const organisations = fetchOrganisations.data?.organisations.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });

  return (
    <AddOwnershipView
      site={site}
      organisations={organisations}
      ownerships={ownerships}
      setOwnerships={setOwnerships}
      setNewSiteName={setNewSiteName}
      error={error}
      refetchOrganisations={refetchOrganisations}
      onBuildingProfileChange={onBuildingProfileChange}
    />
  );
};

const AddOwnershipView = ({
  site,
  organisations,
  ownerships,
  setOwnerships,
  setNewSiteName,
  onBuildingProfileChange,
  error,
  refetchOrganisations,
}) => {
  const [ownershipCount, setOwnershipCount] = useState(1);

  const ownershipSelectors = [...Array(ownershipCount)].map((_, i) => {
    return (
      <OwnershipSelection
        site={site}
        key={i}
        ownershipId={i}
        error={error}
        organisations={organisations}
        ownerships={ownerships}
        setOwnerships={setOwnerships}
        setNewSiteName={setNewSiteName}
        onBuildingProfileChange={onBuildingProfileChange}
        refetchOrganisations={refetchOrganisations}
      />
    );
  });

  const incrementSelectors = () => {
    setOwnershipCount(ownershipCount + 1);
  };

  return (
    <Row style={{ display: "flex", gap: 10, marginTop: 5, padding: 10 }}>
      <Col>
        <Row>
          <Form.Label>Add site to an organisation</Form.Label>
        </Row>
        {ownershipSelectors}
        <Row>
          <Form.Group className="mb-3">
            <Button variant="success" onClick={incrementSelectors}>
              Another ownership
            </Button>
          </Form.Group>
          <Form.Label style={{ color: "#DC3543" }}>{error}</Form.Label>
        </Row>
      </Col>
    </Row>
  );
};

export default AddOwnership;

const OwnershipSelection = ({
  site,
  ownershipId,
  error,
  organisations,
  ownerships,
  onBuildingProfileChange,
  setOwnerships,
  setNewSiteName,
  refetchOrganisations,
}) => {
  const [searchParams, _setSearchParams] = useSearchParams();
  const orgId = searchParams.get("org");
  const [paramOwnershipConfigured, setParamOwnershipConfigured] =
    useState(false);
  const [selectedID, setSelectedID] = useState(orgId);
  const [selectedName, setSelectedName] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedBuildingProfile, setSelectedBuildingProfile] = useState("");
  const [showNewOrgModal, setShowNewOrgModal] = useState(false);
  const [showBuildingProfileModal, setShowBuildingProfileModal] =
    useState(false);

  useEffect(() => {
    if (orgId && organisations && !paramOwnershipConfigured) {
      const selectedOrg = organisations.find((o) => o.id === orgId);
      const orgType = calcOrgType(orgId, organisations);
      const selectedName = selectedOrg?.name;
      setSelectedType(orgType);
      setSelectedName(selectedName);
      setOwnerships({
        ownershipId,
        selectedID: orgId,
        selectedType: orgType,
        selectedName,
      });
      setParamOwnershipConfigured(true);
    }
  }, [
    orgId,
    organisations,
    paramOwnershipConfigured,
    setOwnerships,
    ownershipId,
  ]);

  const setSiteName = (newSelectedID, newSelectedName, newSelectedType) => {
    if (!newSelectedType) return;
    const ownershipsArray = Object.values(ownerships);
    ownershipsArray[ownershipId] = {
      orgID: newSelectedID,
      name: newSelectedName,
      ownership: newSelectedType,
    };
    setNewSiteName(site.name, ownershipsArray);
  };

  const onOwnershipChange = (
    newSelectedID,
    newSelectedName,
    orgType,
    newBuildingProfile
  ) => {
    if (newSelectedID === "new") {
      setShowNewOrgModal(true);
      return;
    }
    if (!orgType) {
      orgType = calcOrgType(newSelectedID, organisations);
    }

    if (orgType === "tenant") {
      if (!newBuildingProfile) {
        newBuildingProfile = organisations.find(
          (o) => o.id === newSelectedID
        ).buildingProfile;
      }
      if (newBuildingProfile && newBuildingProfile !== site.buildingProfile) {
        setSelectedBuildingProfile(newBuildingProfile);
        setShowBuildingProfileModal(true);
      }
    }

    setSelectedID(newSelectedID);
    setSelectedType(orgType);
    setSelectedName(newSelectedName);
    setOwnerships({
      ownershipId,
      selectedID: newSelectedID,
      selectedType: orgType,
      selectedName: newSelectedName,
    });
    setSiteName(newSelectedID, newSelectedName, selectedType);
  };

  return (
    <Row>
      <Col>
        <Form.Group className="mb-3">
          <Form.Select
            value={selectedID ?? ""}
            onChange={(e) => {
              const newSelectedID = e.target.value;
              const newSelectedName =
                e.target.options[e.target.selectedIndex].text;
              onOwnershipChange(newSelectedID, newSelectedName);
            }}
            onFocus={refetchOrganisations}
            data-testid={`orgSelect-${ownershipId}`}
          >
            <option disabled value="">
              Select Organisation
            </option>
            <option value="new">Create new</option>
            {(organisations || []).map((o) => (
              <option key={o.id} value={o.id}>
                {o.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group className="mb-3">
          <Form.Select
            value={selectedType ?? ""}
            onChange={(e) => {
              const newSelectedType = e.target.value;
              setSelectedType(newSelectedType);
              setOwnerships({
                ownershipId,
                selectedID,
                selectedType: newSelectedType,
                selectedName,
              });
              setSiteName(selectedID, selectedName, newSelectedType);
            }}
            isInvalid={
              error && ["landlord", "ownerOccupier"].includes(selectedType)
            }
            data-testid={`orgType-${ownershipId}`}
          >
            <option disabled value="">
              Select Status
            </option>
            {Object.keys(SITE_ORG_LABELS).map((key) => (
              <option key={key} value={key}>
                {SITE_ORG_LABELS[key]}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        {showNewOrgModal ? (
          <Modal>
            <ModalView
              title="Create new organisation"
              fontWeight="500"
              fontSize="20px"
              minHeight="450px"
              width="1000px"
            >
              <CreateOrg
                modal={true}
                handleModalCancel={() => setShowNewOrgModal(false)}
                handleModalSubmit={async (
                  orgId,
                  orgName,
                  orgType,
                  buildingProfile
                ) => {
                  await refetchOrganisations();
                  onOwnershipChange(orgId, orgName, orgType, buildingProfile);
                  setShowNewOrgModal(false);
                }}
              />
            </ModalView>
          </Modal>
        ) : null}
        {showBuildingProfileModal ? (
          <Modal>
            <ModalView
              title={`Update building profile from ${
                BUILDING_PROFILES[site.buildingProfile]
              } to ${BUILDING_PROFILES[selectedBuildingProfile]}?`}
              fontWeight="500"
              fontSize="20px"
              centerTitle={true}
              minHeight="450px"
              width="600px"
            >
              <Row style={{ marginTop: "15px", justifyContent: "center" }}>
                <Col md={4}>
                  <Button
                    variant="success"
                    onClick={() => {
                      onBuildingProfileChange(selectedBuildingProfile);
                      setShowBuildingProfileModal(false);
                    }}
                    style={{ float: "right" }}
                  >
                    Yes, update
                  </Button>
                </Col>
                <Col md={4}>
                  <Button
                    variant="secondary"
                    onClick={() => setShowBuildingProfileModal(false)}
                  >
                    No, don&apos;t update
                  </Button>
                </Col>
              </Row>
            </ModalView>
          </Modal>
        ) : null}
      </Col>
    </Row>
  );
};
