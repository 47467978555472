import { PvaData } from "@inrange/inrange-data-api-client";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import React from "react";
import Button from "react-bootstrap/Button";

interface PvDebugDataProps {
  pvaData?: PvaData;
  rebuildDesign: (options: {
    rebuildDebugImages?: boolean;
    forceRerun?: boolean;
    forceRoofFetch?: boolean;
    disableRoofObjectIntersection?: boolean;
  }) => Promise<void>;
}

const PvDebugData: React.FC<PvDebugDataProps> = ({
  pvaData,
  rebuildDesign,
}) => {
  const { getBooleanFlag } = useKindeAuth();

  return (
    <>
      {pvaData?.task && (
        <>
          <div>
            Stats from currently running task:
            <ul>
              <li>
                Task Start Time:{" "}
                {pvaData.task.started_at?.replace(/\.\d+.*/, "")}
              </li>
              <li>Task Status: {pvaData.task.status}</li>
            </ul>
          </div>
        </>
      )}
      {pvaData?.task_metrics && (
        <>
          <div>
            Stats from last completed task:
            <ul>
              <li>
                Start Time:{" "}
                {pvaData.task_metrics.start_time?.replace(/\.\d+.*/, "")}
              </li>
              <li>
                Run Time:{" "}
                {pvaData.task_metrics.run_time > 300
                  ? `${(pvaData.task_metrics.run_time / 60).toFixed(1)} minutes`
                  : `${pvaData.task_metrics.run_time.toFixed(0)} seconds`}
              </li>
              <li>
                Task succeeded: {`${pvaData.task_metrics.task_run_success}`}
              </li>
              <li>
                PV design available after task completed:{" "}
                {`${pvaData.task_metrics.pv_design_available}`}
              </li>
              {Object.entries(pvaData.task_metrics.inputs?.options || {})
                .length > 0 && (
                <li>
                  Options:
                  <ul>
                    {Object.entries(pvaData.task_metrics.inputs.options).map(
                      ([key, value]) => (
                        <li key={key}>{`${key}: ${value}`}</li>
                      )
                    )}
                  </ul>
                </li>
              )}
            </ul>
          </div>
        </>
      )}
      {pvaData?.debug &&
        Object.keys(pvaData.debug)
          .filter(
            (building_id) => Object.keys(pvaData.debug![building_id]).length > 0
          )
          .map((building_id, index) => {
            return (
              <div key={index}>
                <div>Debug images for building: {building_id}</div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div style={{ flex: 1 }}>
                    <div>
                      Panels before roof object intersection:{" "}
                      {
                        pvaData[building_id]
                          ?.num_panels_before_object_intersection
                      }
                    </div>
                    <div>
                      {pvaData.debug![building_id]
                        .building_with_objects_image && (
                        <img
                          src={
                            pvaData.debug![building_id]
                              .building_with_objects_image
                          }
                          alt="building"
                          width="100%"
                        />
                      )}
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div>
                      Panels after roof object intersection:{" "}
                      {
                        pvaData[building_id]
                          ?.num_panels_after_object_intersection
                      }
                    </div>
                    <div>
                      {pvaData.debug![building_id]
                        .building_with_panels_with_objects_image && (
                        <img
                          src={
                            pvaData.debug![building_id]
                              .building_with_panels_with_objects_image
                          }
                          alt="building_with_objects"
                          width="100%"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      {!pvaData?.task && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "5px",
          }}
        >
          <Button
            variant="danger"
            onClick={async () =>
              await rebuildDesign({
                rebuildDebugImages: true,
              })
            }
          >
            Recreate debug images
          </Button>
          <Button
            variant="danger"
            onClick={async () =>
              await rebuildDesign({
                forceRerun: true,
              })
            }
          >
            Rebuild
          </Button>
          <Button
            variant="danger"
            onClick={async () =>
              await rebuildDesign({
                forceRerun: true,
                disableRoofObjectIntersection: true,
              })
            }
          >
            Rebuild ignoring roof objects
          </Button>
          {getBooleanFlag("pv-design-force-rerun", false) && (
            <Button
              variant="danger"
              onClick={async () =>
                await rebuildDesign({
                  forceRerun: true,
                  forceRoofFetch: true,
                })
              }
            >
              Rebuild with new satellite images
            </Button>
          )}
        </div>
      )}
    </>
  );
};

export default PvDebugData;
