import { fractionalCurrencySymbol } from "@inrange/theme-components/formatting";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { numString2dp } from "./utils";

const SdmAppliedMatches = ({ site, setSelectedDeleteOffer }) => {
  const buyMatches = site.sdmMatches.filter(
    (match) => match.buyerId === site.id
  );
  const sellMatches = site.sdmMatches.filter(
    (match) => match.sellerId === site.id
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        gap: "8px",
      }}
    >
      <Card body className="mb-2" style={{ flex: "1" }}>
        <h6
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <span style={{ fontWeight: "bold" }}>
            Sell matches ({sellMatches.length})
          </span>
          <span>
            Excess supply remaining:{" "}
            {numString2dp(site.energyFlowAnnual.exported)} kWh
          </span>
        </h6>
        {sellMatches.length === 0 && <div>No sell matches yet.</div>}
        {sellMatches.length > 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
            }}
          >
            {sellMatches.map((sellMatch) => (
              <div
                key={sellMatch.buyerId}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Link to={`/site/${sellMatch.buyerId}/sdm`}>
                    {sellMatch.buyerName}
                  </Link>
                  <br />
                  {numString2dp(sellMatch.volume)} kWh at{" "}
                  {(sellMatch.tariff * 100.0).toFixed(2)}{" "}
                  {fractionalCurrencySymbol(site.currencyCode)}/kWh
                  <br />
                  PPA Type:{" "}
                  {sellMatch.ppaContractType === "sleeved" ? "Sleeved" : "VPPA"}
                  , Length: {sellMatch.ppaLength}, Index: {sellMatch.ppaIndex}
                </div>
                <div>
                  <a
                    onClick={() => setSelectedDeleteOffer(sellMatch)}
                    className="text-danger"
                    style={{
                      cursor: "pointer",
                      textDecoration: "none",
                    }}
                  >
                    Delete match
                  </a>
                </div>
              </div>
            ))}
          </div>
        )}
      </Card>
      <Card body className="mb-2" style={{ flex: "1" }}>
        <h6
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <span style={{ fontWeight: "bold" }}>
            Buy matches ({buyMatches.length})
          </span>
          <span>
            Unmet demand remaining:{" "}
            {numString2dp(site.energyFlowAnnual.gridImport)} kWh
          </span>
        </h6>
        {buyMatches.length === 0 && <div>No buy matches yet.</div>}
        {buyMatches.length > 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
            }}
          >
            {buyMatches.map((buyMatch) => (
              <div
                key={buyMatch.sellerId}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Link to={`/site/${buyMatch.sellerId}/sdm`}>
                    {buyMatch.sellerName}
                  </Link>
                  <br />
                  {numString2dp(buyMatch.volume)} kWh at{" "}
                  {(buyMatch.tariff * 100.0).toFixed(2)}{" "}
                  {fractionalCurrencySymbol(site.currencyCode)}/kWh
                  <br />
                  PPA Type:{" "}
                  {buyMatch.ppaContractType === "sleeved" ? "Sleeved" : "VPPA"},
                  Length: {buyMatch.ppaLength}, Index: {buyMatch.ppaIndex}
                </div>
                <div>
                  <a
                    onClick={() => setSelectedDeleteOffer(buyMatch)}
                    className="text-danger"
                    style={{
                      cursor: "pointer",
                      textDecoration: "none",
                    }}
                  >
                    Delete match
                  </a>
                </div>
              </div>
            ))}
          </div>
        )}
      </Card>
    </div>
  );
};
export default SdmAppliedMatches;
