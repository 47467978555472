import { useState } from "react";
import { Button, Card, Modal, Row } from "react-bootstrap";

const epochToDateString = (epoch) => {
  const date = new Date(epoch * 1000); // Convert to milliseconds

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  };

  return date.toLocaleString("en-US", options);
};

const ActionButtons = ({
  site,
  handleDeleteClick,
  handleDiscardClick,
  handleSaveClick,
  handleUpdateHubSpotClick,
  saveDisabled,
  errors,
}) => {
  const errorsWithoutGenerationDemandChanges = { ...errors };
  const blockedGenerationDemandChanges =
    errorsWithoutGenerationDemandChanges["site.sdmInputsDataHash"];
  delete errorsWithoutGenerationDemandChanges["site.sdmInputsDataHash"];

  return (
    <>
      <Row
        style={{
          backgroundColor: "white",
          margin: "8px 0px 0px",
          padding: "0 12px",
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 0.3fr 1.5fr 1.5fr",
            gap: "10px 32px",
            padding: "20px 0px 20px",
            justifyContent: "center",
            alignItems: "stretch",
          }}
        >
          <DeleteButton site={site} handleDeleteClick={handleDeleteClick} />
          <Button
            variant="inrangesecondary"
            onClick={handleDiscardClick}
            disabled={!site?.id}
          >
            Discard changes
          </Button>
          <div></div> {/* This empty div will take up the remaining space */}
          <Button
            variant="success"
            onClick={handleSaveClick}
            disabled={
              saveDisabled ||
              Object.keys(errorsWithoutGenerationDemandChanges).length > 0
            }
          >
            Save site
          </Button>
          <Button
            variant="success"
            onClick={handleUpdateHubSpotClick}
            disabled={
              saveDisabled ||
              Object.keys(errorsWithoutGenerationDemandChanges).length > 0
            }
          >
            Save & update HubSpot
          </Button>
          {Object.keys(errors).length > 0 ? (
            <Card.Text
              className="text-danger"
              style={{
                fontSize: "14px",
                gridColumn: "4 / 5",
                gridRow: "2 / 2",
              }}
            >
              {Object.keys(errorsWithoutGenerationDemandChanges).length > 0 && (
                <p>
                  The form has validation errors. Please address the errors
                  marked on the page.
                </p>
              )}
              {blockedGenerationDemandChanges && (
                <p>{blockedGenerationDemandChanges}</p>
              )}
            </Card.Text>
          ) : null}
          {handleUpdateHubSpotClick ? (
            <Card.Text
              className="text-secondary"
              style={{
                fontSize: "14px",
                gridColumn: "5 / 5",
                gridRow: "2 / 2",
              }}
            >
              {!site.hubspotSite?.hubspotUpdateTime && (
                <>Site not saved to HubSpot</>
              )}
              {site.hubspotSite?.hubspotUpdateTime > 0 &&
                site.hubspotSite?.hubspotUpdateTime > site.updatedAt && (
                  <>Site in sync with HubSpot</>
                )}
              {site.hubspotSite?.hubspotUpdateTime > 0 &&
                site.hubspotSite?.hubspotUpdateTime <= site.updatedAt && (
                  <>
                    <span>Site out of sync with HubSpot </span>
                    <span style={{ display: "inline-block" }}>
                      (last sync{" "}
                      {epochToDateString(site.hubspotSite?.hubspotUpdateTime)})
                    </span>
                  </>
                )}
            </Card.Text>
          ) : null}
        </div>
      </Row>
    </>
  );
};

export default ActionButtons;

const DeleteButton = ({ site, handleDeleteClick }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleCancelDelete = () => setShowDeleteModal(false);
  return (
    <>
      <Modal
        show={showDeleteModal}
        onHide={handleCancelDelete}
        style={{ position: "relative", top: "-200px" }}
      >
        <Modal.Body>
          Are you sure you want to delete this site? Deleting it will result in
          the permanent loss of all data and cannot be undone
          <div
            style={{
              display: "flex",
              gap: 24,
              justifyContent: "center",
              marginTop: 8,
            }}
          >
            <Button variant="secondary" size="sm" onClick={handleCancelDelete}>
              Cancel
            </Button>
            <Button variant="danger" size="sm" onClick={handleDeleteClick}>
              Delete site
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Button
        variant="danger"
        onClick={() => setShowDeleteModal(true)}
        disabled={!site?.id}
      >
        Delete site
      </Button>
    </>
  );
};
