import { BarDatum } from "@nivo/bar";
import React, { useRef } from "react";
import { colors } from ".";
import { formatUnitsToNearestTen } from "../../formatting/formatKiloUnits";
import {
  ChartToolTip,
  ChartToolTipBody,
  ChartToolTipHeader,
} from "../ChartTooltip";
import BarChart from "./BarChart";
import { ChartData } from "./chart-types";
import {
  Chart,
  ChartHeader,
  ChartHeaderWithTabs,
  ChartLegend,
  ChartWrapper,
} from "./common";
import { NonOverflowTooltip } from "./NonOverflowTooltip";

interface ToolTipDataProps {
  data: BarDatum;
}

interface PeakOffPeakChartProps {
  chartData: ChartData[];
  totalOnSiteGeneration: number;
  isGeneratingSite: boolean;
  height?: string;
}

const ToolTipData: React.FC<ToolTipDataProps> = ({ data }) => {
  return (
    <>
      <ChartToolTipBody>
        Peak - from on-site:{" "}
        <strong>
          {formatUnitsToNearestTen(
            data["Peak hour energy supplied from on-site generation"] as number,
            "kWh"
          )}
        </strong>
      </ChartToolTipBody>
      <ChartToolTipBody>
        Peak - from InRange Network:{" "}
        <strong>
          {formatUnitsToNearestTen(
            data["Peak hour energy supplied from InRange Network"] as number,
            "kWh"
          )}
        </strong>
      </ChartToolTipBody>
      <ChartToolTipBody>
        Peak - from other suppliers:{" "}
        <strong>
          {formatUnitsToNearestTen(
            data["Peak hour energy not supplied from InRange"] as number,
            "kWh"
          )}
        </strong>
      </ChartToolTipBody>
      <ChartToolTipBody>
        Off-peak - from other suppliers:{" "}
        <strong>
          {formatUnitsToNearestTen(
            data["Off-Peak hour energy not supplied from InRange"] as number,
            "kWh"
          )}
        </strong>
      </ChartToolTipBody>
    </>
  );
};

const energyProcuredChartLegend = [
  {
    label: "Peak hour energy supplied from on-site generation",
    color: colors[0],
  },
  {
    label: "Peak hour energy supplied from InRange Network",
    color: colors[1],
  },
  {
    label: "Peak hour energy supplied from other suppliers",
    color: colors[2],
  },
  {
    label: "Off-peak hour energy supplied from other suppliers",
    color: colors[3],
  },
];

const keys = [
  "Peak hour energy supplied from on-site generation",
  "Peak hour energy supplied from InRange Network",
  "Peak hour energy not supplied from InRange",
  "Off-Peak hour energy not supplied from InRange",
];

const PeakOffPeakChart: React.FC<PeakOffPeakChartProps> = ({
  chartData,
  totalOnSiteGeneration,
  isGeneratingSite,
  ...props
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const chartKeys = isGeneratingSite ? keys : keys.slice(1);
  const chartLegend = isGeneratingSite
    ? energyProcuredChartLegend
    : energyProcuredChartLegend.slice(1);
  const chartColors = isGeneratingSite ? colors : colors.slice(1);
  const subHeader = isGeneratingSite ? (
    <ChartHeader>
      {formatUnitsToNearestTen(totalOnSiteGeneration, "kWh")}
      <label>Total on-site generation by this site in year 1</label>
    </ChartHeader>
  ) : null;

  const showPeakOffPeakTooltip = (e: { data: BarDatum }): JSX.Element => {
    const eventCoords = e as unknown as { x: number; y: number };
    return (
      <NonOverflowTooltip
        point={{ x: eventCoords.x, y: eventCoords.y }}
        containerSize={ref.current!.getBoundingClientRect()}
      >
        <ChartToolTip>
          <ChartToolTipHeader>{e.data.index}</ChartToolTipHeader>
          <ToolTipData data={e.data} />
        </ChartToolTip>
      </NonOverflowTooltip>
    );
  };

  return (
    <ChartWrapper ref={ref} height={props.height}>
      <ChartHeaderWithTabs>
        <div>
          <ChartHeader>Energy supply by hours and source</ChartHeader>
          {subHeader}
        </div>
      </ChartHeaderWithTabs>
      <Chart>
        <ChartLegend chartLegend={chartLegend} />
        <BarChart
          chartData={chartData as BarDatum[]}
          tooltipFn={showPeakOffPeakTooltip}
          keys={chartKeys}
          barColors={chartColors}
        />
      </Chart>
    </ChartWrapper>
  );
};

export default PeakOffPeakChart;
