import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import getSiteList from "../http/getSiteList";
import postSite from "../http/postSite";

const useSiteList = () => {
  const queryClient = useQueryClient();
  const { getToken } = useKindeAuth();

  const fetchSites = useQuery("sites", async () => {
    return getSiteList(await getToken());
  });

  const createSite = useMutation(
    async (site) => postSite(await getToken(), site),
    {
      onSuccess: (addedSite) => {
        queryClient.setQueryData("sites", (currentSites) => [
          ...currentSites,
          addedSite,
        ]);
      },
    }
  );

  return {
    createSite,
    fetchSites,
  };
};

export default useSiteList;
