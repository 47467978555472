import React from "react";
import styled from "styled-components";

interface PvSystemWarningProps {
  noPvSystemSelected: boolean;
  hhDataUploaded: boolean;
  isGeneratingSite: boolean;
  name: string;
  onLinkClick: () => void;
}

const PvSystemWarning: React.FC<PvSystemWarningProps> = ({
  noPvSystemSelected,
  hhDataUploaded,
  isGeneratingSite,
  name,
  onLinkClick,
}) => {
  if (!noPvSystemSelected && hhDataUploaded) {
    return null;
  }

  const headerText = `Site ${
    noPvSystemSelected ? "data" : "demand"
  } is based on an initial assessment. ${
    isGeneratingSite && noPvSystemSelected
      ? "We’ll contact you when a full analysis is complete."
      : ""
  }${hhDataUploaded ? "" : " For best results, "}`;
  const hrefHHData = `mailto:support@inrange.io?subject=I've attached my half hourly demand as a CSV for site "${name}"`;

  return (
    <>
      <PvSystemWarningParagraph>
        {headerText}
        {hhDataUploaded ? null : (
          <>
            <PvSystemWarningLink href={hrefHHData} onClick={onLinkClick}>
              send us your HH demand data
            </PvSystemWarningLink>
            <span>.</span>
          </>
        )}
      </PvSystemWarningParagraph>
    </>
  );
};

export default PvSystemWarning;

const PvSystemWarningParagraph = styled.p`
  font-size: 14px;
`;

const PvSystemWarningLink = styled.a`
  color: ${({ theme }) => theme.colors.greyFaint};
`;
