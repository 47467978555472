import React from "react";
import styled from "styled-components";
import { formatUnitsToNearestTen } from "../../formatting/formatKiloUnits";
import Tooltip from "../Tooltip";

interface EnergyDemandSidebarProps {
  behindMeter: number;
  inrangeNetwork: number;
  grid: number;
  isGeneratingSite: boolean;
  isBatterySite: boolean;
}

const EnergyDemandSidebar: React.FC<EnergyDemandSidebarProps> = ({
  behindMeter,
  inrangeNetwork,
  grid,
  isGeneratingSite,
  isBatterySite,
}) => {
  return (
    <SideBar>
      <MetricTooltip>
        <Tooltip
          text={`The energy demands of this site can be met with solar energy generated ${
            isGeneratingSite ? "on-site with your InRange installation, " : ""
          }by solar energy aggregated from the larger InRange network, or by other suppliers if the demand is greater than both methods can supply.`}
        />
      </MetricTooltip>
      <SideBarHeader>Energy demand</SideBarHeader>
      <SideBarSubHeader>
        The amount of energy consumed by your site annually
        {isBatterySite && " including increase from battery usage"}
      </SideBarSubHeader>

      {isGeneratingSite && (
        <EnergyDemandMetric
          label={`Energy consumed behind the meter, from on-site generation${
            isBatterySite ? " and battery" : ""
          }`}
          value={behindMeter}
        />
      )}
      <EnergyDemandMetric
        label="Energy aggregated from the InRange network"
        value={inrangeNetwork}
      />
      <EnergyDemandMetric
        label="Energy procured from other suppliers"
        value={grid}
      />
    </SideBar>
  );
};

export default EnergyDemandSidebar;

interface EnergyDemandMetricProps {
  value: number;
  label: string;
}

const EnergyDemandMetric: React.FC<EnergyDemandMetricProps> = ({
  value,
  label,
}) => {
  return (
    <EnergyDemandMetricWrapper>
      <EnergyDemandMetricValue>
        {formatUnitsToNearestTen(value, "kWh")}
      </EnergyDemandMetricValue>
      <EnergyDemandMetricLabel>{label}</EnergyDemandMetricLabel>
    </EnergyDemandMetricWrapper>
  );
};

const MetricTooltip = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const SideBarHeader = styled.h1`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #00022f;
  margin: 0;
  padding: 0;
`;

const SideBarSubHeader = styled.p`
  margin: 0 0 40px;
  padding: 0;
  font-size: 14px;
`;

const SideBar = styled.div`
  padding: 16px;
  border-radius: 8px;
  width: 470px;
  margin-left: 16px;
  background: rgb(255, 255, 255);
`;

const EnergyDemandMetricWrapper = styled.div`
  margin: 20px 0;
`;
const EnergyDemandMetricValue = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 32px;
`;
const EnergyDemandMetricLabel = styled.div`
  margin: 0;
  padding: 0;
`;
