import axios from "axios";

const putSiteFile = async (siteFileUploadUrl, contentType, siteFile) => {
  const { data } = await axios.put(siteFileUploadUrl, siteFile, {
    headers: {
      "Content-Type": contentType,
    },
  });
  return data;
};

export default putSiteFile;
