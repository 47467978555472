import { BarDatum, BarTooltipProps } from "@nivo/bar";
import React, { useRef } from "react";
import styled from "styled-components";
import { currencySymbol } from "../../formatting/currency-utils";
import formatCurrency from "../../formatting/formatCurrency";
import { formatUnits } from "../../formatting/formatKiloUnits";
import {
  ChartToolTip,
  ChartToolTipBody,
  ChartToolTipHeader,
} from "../ChartTooltip";
import Tooltip from "../Tooltip";
import BarChart from "./BarChart";
import { ActualsChartData } from "./chart-types";
import { Chart, ChartHeader, ChartLegend, ChartWrapper } from "./common";
import { NonOverflowTooltip } from "./NonOverflowTooltip";

const operationalColors = ["#00022F", "#2779A7", "#2A2C76", "#9EC4E8"];

interface OperationalSiteChartProps {
  header: string;
  chartData: ActualsChartData[];
  currency: boolean;
  currencyCode?: string;
  exported: boolean;
  toolTipText: string;
  height?: string;
}

const OperationalSiteChart: React.FC<OperationalSiteChartProps> = ({
  header,
  chartData,
  currency,
  currencyCode,
  exported,
  toolTipText,
  ...props
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const keys = [
    "On-site behind the meter",
    exported ? "Exported to network" : "From InRange network",
    "Forecasted on-site",
    "Forecasted network",
  ];
  const chartLegend = [
    {
      label: keys[0],
      color: operationalColors[0],
    },
    {
      label: keys[1],
      color: operationalColors[1],
    },
    {
      label: keys[2],
      color: operationalColors[2],
    },
    {
      label: keys[3],
      color: operationalColors[3],
    },
  ];

  const showOperationalSiteChartTooltip = (
    e: BarTooltipProps<BarDatum>,
    currency: boolean,
    currencyCode: string | undefined,
    exported: boolean
  ) => {
    const eventCoords = e as unknown as { x: number; y: number };
    const actualsExist = e.data["firstDay"] !== undefined;
    const headerText = actualsExist
      ? `${formatTooltipDate(
          e.data["firstDay"].toString()
        )} - ${formatTooltipDate(e.data["lastDay"].toString())}`
      : e.data["index"];
    return (
      <NonOverflowTooltip
        point={{ x: eventCoords.x, y: eventCoords.y }}
        containerSize={ref.current!.getBoundingClientRect()}
      >
        <ChartToolTip>
          <ChartToolTipHeader>{headerText}</ChartToolTipHeader>
          <MonthlyTooltip
            data={e.data}
            currency={currency}
            currencyCode={currencyCode}
            exported={exported}
            actualsExist={actualsExist}
          />
        </ChartToolTip>
      </NonOverflowTooltip>
    );
  };

  return (
    <ChartWrapper ref={ref} height={props.height} $padding={"15px 0 0 0"}>
      <OperationalChartHeader>
        <ChartHeader>{header}</ChartHeader>
        <Tooltip text={toolTipText} position={"bottomLeft"} />
      </OperationalChartHeader>
      <Chart>
        <ChartLegend chartLegend={chartLegend} />
        <BarChart
          chartData={chartData as BarDatum[]}
          tooltipFn={(e) =>
            showOperationalSiteChartTooltip(e, currency, currencyCode, exported)
          }
          keys={keys}
          paddingProp={0.3}
          tickRotationProp={0}
          barColors={operationalColors}
          stacked={true}
          legend={currency ? currencySymbol(currencyCode!) : "kWh"}
        />
      </Chart>
    </ChartWrapper>
  );
};

export default OperationalSiteChart;

// we want the dates in the tooltip to look like Jan 13 2023 - Jan 31 2023
// so we create this function
const formatTooltipDate = (dateString: string): string => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-GB", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
};

interface MonthlyTooltipProps {
  data: BarDatum;
  currency: boolean;
  currencyCode?: string;
  exported: boolean;
  actualsExist: boolean;
}

const MonthlyTooltip: React.FC<MonthlyTooltipProps> = ({
  data,
  currency,
  currencyCode,
  exported,
  actualsExist,
}) => {
  const formatValue = (key: string, fixedDecimalPlaces?: number) => {
    const value = data[key] !== undefined ? data[key] : 0;
    return (
      <strong>
        {currency
          ? formatCurrency(value as number, currencyCode, 2)
          : formatUnits(value as number, "kWh", fixedDecimalPlaces)}
      </strong>
    );
  };
  const networkKey = exported ? "Exported to network" : "From InRange network";

  return actualsExist ? (
    <>
      <ChartToolTipBody>
        {"On-site behind the meter: "}
        <strong>{formatValue("On-site behind the meter", 2)}</strong>
      </ChartToolTipBody>
      <ChartToolTipBody>
        {`${networkKey}: `}
        <strong>{formatValue(networkKey, 2)}</strong>
      </ChartToolTipBody>
    </>
  ) : (
    <>
      <ChartToolTipBody>
        {"Forecasted on-site: "}
        <strong>{formatValue("Forecasted on-site")}</strong>
      </ChartToolTipBody>
      <ChartToolTipBody>
        {"Forecasted network: "}
        <strong>{formatValue("Forecasted network")}</strong>
      </ChartToolTipBody>
    </>
  );
};

const OperationalChartHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 16px;
`;
