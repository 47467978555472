import { useSite } from "@inrange/building-manager-api-client";
import {
  PartialSite,
  Site,
  SiteCalculations,
} from "@inrange/building-manager-api-client/models-site";
import React, { ReactNode, useEffect, useState } from "react";
import { createContextTS } from "./context-utils";

interface SiteCalculationsProviderProps {
  userOrgId: string;
  app: "admin" | "customer";
  site: PartialSite;
  setSaveDisabled?: (disabled: boolean) => void;
  children: ReactNode;
}

interface SiteCalculationsContextProps {
  errors: Record<string, string>;
  siteCalculations: SiteCalculations;
  setSiteCalculations: React.Dispatch<React.SetStateAction<SiteCalculations>>;
  updateCalculations: (site: Partial<Site>) => void;
  isCalculating: boolean;
}

const SiteCalculationsContext = createContextTS<SiteCalculationsContextProps>();

const SiteCalculationsProvider: React.FC<SiteCalculationsProviderProps> = ({
  userOrgId,
  app,
  site,
  setSaveDisabled,
  children,
}) => {
  const { previewSite } = useSite({ userOrgId, app });

  // >> Location linked data
  const defaultCountryCode = site.countryCode || "GBR";
  const defaultCurrencyCode = site.currencyCode || "GBP";
  const defaultDno = site.dno || {};
  const defaultSubstationData = site.substationData || {};
  // >> Buildings
  const defaultTotalBuildingArea = site.totalBuildingArea || 0;
  // >> Demand
  const defaultTenantAnnualDemand = site.tenantAnnualDemandKwh || 0;
  // >> Generation
  const defaultPVPanelCount = site.pvPanelCount || 0;
  // >> Battery
  const defaultCostInputsBattery = site.costInputsBattery || {};
  // >> Energy flow inputs
  const defaultHalfHourlyGeneration = site.halfHourlyGeneration || {};
  // >> Energy flow outputs
  const defaultEnergyFlowAnnual = site.energyFlowAnnual || { generation: 0 };
  const defaultMonthlyTenantEnergyShare =
    site.monthlyTenantEnergyShare || Array(12).fill(0);
  // >> Deal and contract attributes
  const defaultLeaseLengthMonths = site.leaseLengthMonths;
  // >> Financial modeling - outputs
  const defaultProjectCosts = site.projectCosts || {};
  const defaultFinancialModels = site.financialModels || {};

  const [invalidStates, setInvalidStates] = useState<any[]>([]);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [hasSdmInputsDataHashError, setHasSdmInputsDataHashError] =
    useState(false);
  const [showSdmInputsDataHashError, setShowSdmInputsDataHashError] =
    useState(true);
  const [siteCalculations, setSiteCalculations] = useState<SiteCalculations>({
    // >> Location linked data
    countryCode: defaultCountryCode,
    currencyCode: defaultCurrencyCode,
    dno: defaultDno,
    substationData: defaultSubstationData,
    // >> Buildings
    totalBuildingArea: defaultTotalBuildingArea,
    // >> Demand
    tenantAnnualDemandKwh: defaultTenantAnnualDemand,
    // >> Generation
    pvPanelCount: defaultPVPanelCount,
    // >> Battery
    costInputsBattery: defaultCostInputsBattery,
    // >> Energy flow inputs
    halfHourlyGeneration: defaultHalfHourlyGeneration,
    // >> Energy flow outputs
    energyFlowAnnual: defaultEnergyFlowAnnual,
    monthlyTenantEnergyShare: defaultMonthlyTenantEnergyShare,
    // >> Tariffs
    blendedExportTariff: site.blendedExportTariff || 0,
    // >> Deal and contract attributes
    leaseLengthMonths: defaultLeaseLengthMonths || 0,
    // >> Financial modeling - outputs
    projectCosts: defaultProjectCosts,
    financialModels: defaultFinancialModels,
  });

  useEffect(() => {
    const updatedErrors: Record<string, string> = {};
    invalidStates.forEach((error) => {
      // the error response inside invalidStates can look like this:
      //   {
      //       "loc": [
      //           "costInputs",
      //           "panelRate"
      //       ],
      //       "msg": "Must be a number.",
      //       "type": "type_error.float"
      //   }
      // we can therefore join the loc array to get the path to the error
      // i.e. in this case, it would be "costInputs.panelRate"
      updatedErrors[error.loc.join(".")] = error.msg;
    });
    setHasSdmInputsDataHashError(!!updatedErrors["site.sdmInputsDataHash"]);
    setShowSdmInputsDataHashError(true);
    setErrors(updatedErrors);
  }, [invalidStates]);

  useEffect(() => {
    if (previewSite.isSuccess) {
      setInvalidStates([]);
      setSiteCalculations((prevCalculations) => ({
        ...prevCalculations,
        ...previewSite.data.site,
        // Give local code access to a millisecond accuracy time of when this calculation was completed
        updatedAt: Date.now() / 1000.0,
      }));
      previewSite.reset();
    }

    if (previewSite.isError) {
      setInvalidStates(previewSite.error.response?.data?.invalid_states ?? []);
      previewSite.reset();
    }
  }, [previewSite]);

  const updateCalculations = (site: Partial<Site>) => {
    if (setSaveDisabled) {
      setSaveDisabled(true);
    }
    setShowSdmInputsDataHashError(false);
    previewSite.mutate(
      { site },
      {
        onSettled: () => {
          if (setSaveDisabled) {
            setSaveDisabled(false);
          }
        },
      }
    );
  };

  // NOTE: We don't show <LoadingIndicator> and <ErrorMessage> in the customer app
  return (
    <SiteCalculationsContext.Provider
      value={{
        errors,
        siteCalculations,
        setSiteCalculations,
        updateCalculations,
        isCalculating: previewSite.isLoading,
      }}
    >
      {app === "admin" && previewSite.isLoading && (
        <LoadingIndicator
          label="Calculating ..."
          testid="calculating-indicator"
        />
      )}
      {app === "admin" &&
        !previewSite.isLoading &&
        hasSdmInputsDataHashError &&
        showSdmInputsDataHashError && (
          <ErrorMessage
            label="Can't change energy flows with applied SDM matches"
            testid="calculating-indicator"
            onClose={() => setShowSdmInputsDataHashError(false)}
          />
        )}
      {children}
    </SiteCalculationsContext.Provider>
  );
};

export { SiteCalculationsContext, SiteCalculationsProvider };

interface LoadingIndicatorProps {
  label: string;
  testid: string;
}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
  label,
  testid,
}) => {
  return (
    <div
      className="alert text-center alert-primary"
      style={{
        position: "fixed",
        top: 0,
        right: 0,
        width: "100%",
        zIndex: 1000,
      }}
      role="alert"
      data-testid={testid}
    >
      {label}
    </div>
  );
};

interface ErrorProps {
  label: string;
  testid: string;
  onClose: () => void;
}

const ErrorMessage: React.FC<ErrorProps> = ({ label, testid, onClose }) => {
  return (
    <div
      className="alert text-center alert-danger"
      style={{
        position: "fixed",
        top: 0,
        right: 0,
        width: "100%",
        zIndex: 1000,
      }}
      role="alert"
      data-testid={testid}
    >
      {label} -{" "}
      <button
        onClick={onClose}
        style={{
          background: "none",
          border: "none",
          color: "inherit",
          textDecoration: "underline",
          cursor: "pointer",
          padding: 0,
          font: "inherit",
        }}
        data-testid="close-preview-error"
      >
        Close
      </button>
    </div>
  );
};
