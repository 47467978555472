import {
  useBuildingsInRegionList,
  useSiteList,
} from "@inrange/building-manager-api-client";
import {
  useBuildingsByBounds,
  useDeedsByBounds,
} from "@inrange/inrange-data-api-client";
import center from "@turf/center";
import { points, polygon } from "@turf/helpers";
import L from "leaflet";
import hash from "object-hash";
import { useCallback, useEffect, useState } from "react";
import Loading from "../../../Loading";
import BuildingMap from "./BuildingMap";
import Deeds from "./Deeds";
import { MapStylesProd, MapStylesTest } from "./MapStyles";
import {
  bmaBuildingToOsmBuilding,
  closeCoordinates,
  isIntersectingWithPolygon,
} from "./siteGeoUtils";

const addressWithoutPostcode = (address) => {
  let addressParts = address.split(",");
  addressParts = addressParts.slice(0, 2);
  return addressParts.join(",");
};

const testMode = !import.meta.env.PROD && import.meta.env.VITE_TEST_MODE;

const SiteGeo = ({
  site,
  initialSiteBuildings,
  siteNewURLBuildingID,
  setSite,
  setBuildingsForPreview,
  siteMapZoom,
  ownerships,
  setNewSiteName,
  setHasUnsavedBuildings,
}) => {
  // Handle the map
  const [map, setMap] = useState();
  const [mapStyle, setMapStyle] = useState(
    testMode ? MapStylesTest.satellite : MapStylesProd.satellite
  );
  const [mapBounds, setMapBounds] = useState();

  const params = new URL(document.location).searchParams;
  const testOrgId = params.get("testOrgId");

  useEffect(() => {
    if (!map) return;
    setMapBounds(map.getBounds());
  }, [map]);

  const zoomToSite = (site) => {
    if (!map) return;
    map.flyTo([site.latitude, site.longitude], 16, {
      animate: true,
      duration: 1,
    });
  };

  // Handle the buldings in the map - both site buildings and buildings in bounds
  const [networkBuildingsInBounds, setNetworkBuildingsInBounds] = useState({}); // These buildings owned by other sites
  const [availableBuildingsInBounds, setAvailableBuildingsInBounds] = useState(
    {}
  ); // These are selectable polygons
  const [siteBuildings, setSiteBuildings] = useState({}); // There are selected polygons
  const [buildingIntersections, setBuildingIntersections] = useState({}); // Object to store a boolean for each building in bounds to check if it intersects with the site
  const [buildingsChanged, setBuildingsChanged] = useState(false); // This is used to trigger a recalcualtion of building intersections
  const [initialSiteNewBuildingAdded, setInitialSiteNewBuildingAdded] =
    useState(false); // Ensure that the initial building is added to the site once

  const fetchAvailableBuildingsInBounds = useBuildingsByBounds(mapBounds);
  // we provide a test org id to filter the network buildings rendered during tests
  const fetchNetworkBuildingsInBounds = useBuildingsInRegionList(
    mapBounds,
    false,
    testOrgId
  );

  const calculateCenterOfBuildings = useCallback((buildings) => {
    const buildingCenters = Object.values(buildings).map(
      (building) => center(building.geometry).geometry.coordinates
    );
    return center(points(buildingCenters));
  }, []);

  // Controllers to add and remove the buildings from a site
  const addBuildingToSite = useCallback(
    (key, data) => {
      const newBuilding = data[key];
      const newSiteBuildings = {
        ...siteBuildings,
        [key]: newBuilding,
      };

      const centerOfAllNewSiteBuildings = calculateCenterOfBuildings(
        Object.values(newSiteBuildings)
      );

      const totalBuildingArea = Object.values(newSiteBuildings).reduce(
        (acc, building) => acc + Math.floor(building.surface_area_sqm),
        0
      );

      // 6 decimal places is ~10cm of accuracy. Without this rounding we have seen values like
      // "51.511124198999994,-2.6891252000000003" which is way too accurate
      setSite({
        latitude: parseFloat(
          centerOfAllNewSiteBuildings.geometry.coordinates[1].toFixed(6)
        ),
        longitude: parseFloat(
          centerOfAllNewSiteBuildings.geometry.coordinates[0].toFixed(6)
        ),
        totalBuildingArea,
      });
      setSiteBuildings(newSiteBuildings);
      setBuildingsForPreview(Object.values(newSiteBuildings));
      setBuildingsChanged(true);
    },
    [setSite, setBuildingsForPreview, siteBuildings, calculateCenterOfBuildings]
  );

  const onSaveEditModeSiteBoundaries = useCallback(
    (newSiteBuildings) => {
      const newSiteBuildingsArray = Object.values(newSiteBuildings);

      const centerOfAllNewSiteBuildings = calculateCenterOfBuildings(
        newSiteBuildingsArray
      );

      const totalBuildingArea = newSiteBuildingsArray.reduce(
        (acc, building) => acc + Math.floor(building.surface_area_sqm),
        0
      );

      setSite({
        latitude: parseFloat(
          centerOfAllNewSiteBuildings.geometry.coordinates[1].toFixed(6)
        ),
        longitude: parseFloat(
          centerOfAllNewSiteBuildings.geometry.coordinates[0].toFixed(6)
        ),
        totalBuildingArea,
      });

      setSiteBuildings(newSiteBuildings);
      setBuildingsForPreview(newSiteBuildingsArray);
      setBuildingsChanged(true);
    },
    [calculateCenterOfBuildings, setSite, setBuildingsForPreview]
  );

  const removeBuildingFromSite = (key, data) => {
    const { [key]: _value, ...rest } = data;
    setSiteBuildings(rest);

    const totalBuildingArea = Object.values(rest).reduce(
      (acc, building) => acc + building.surface_area_sqm,
      0
    );
    setSite({ totalBuildingArea });

    setBuildingsForPreview(Object.values(rest));
    setBuildingsChanged(true);
  };

  useEffect(() => {
    if (
      !fetchAvailableBuildingsInBounds.data ||
      !fetchNetworkBuildingsInBounds.data
    )
      return;

    // If we're too zoomed out, don't render billions of buildings
    const mapSizeMeters = mapBounds
      .getNorthEast()
      .distanceTo(mapBounds.getSouthWest());
    if (mapSizeMeters > 7000) return;

    const availableBuildings = {};
    const networkBuildings = {};
    const buildingsToTurfPolygons = (buildings) => {
      return Object.values(buildings).map((building) => {
        const coordinates = building.geometry.coordinates[0];
        const closedCoordinates = closeCoordinates(coordinates);
        return polygon([closedCoordinates]);
      });
    };
    const siteBuildingPolygons = buildingsToTurfPolygons(siteBuildings);

    Object.keys(fetchNetworkBuildingsInBounds.data.buildings).forEach((key) => {
      const building = fetchNetworkBuildingsInBounds.data.buildings[key];
      const osmBuilding = bmaBuildingToOsmBuilding(
        fetchNetworkBuildingsInBounds.data.buildings[key]
      );
      const isIntersecting = isIntersectingWithPolygon(
        osmBuilding,
        siteBuildingPolygons
      );
      if (!building.siteIDs.includes(site.id) && !isIntersecting) {
        networkBuildings[key] = {
          ...osmBuilding,
          siteIDs: building.siteIDs, // so that we can populate the popup
        };
      }
    });

    const networkBuildingPolygons = buildingsToTurfPolygons(networkBuildings);
    const allSitesBuildingsPolygons = [
      ...siteBuildingPolygons,
      ...networkBuildingPolygons,
    ];

    Object.keys(fetchAvailableBuildingsInBounds.data).forEach((key) => {
      if (!siteBuildings[key])
        if (key === siteNewURLBuildingID && !initialSiteNewBuildingAdded) {
          addBuildingToSite(key, fetchAvailableBuildingsInBounds.data);
          setInitialSiteNewBuildingAdded(true); // Prevents adding the initial building multiple times
        } else {
          if (buildingIntersections[key] === undefined || buildingsChanged) {
            buildingIntersections[key] = isIntersectingWithPolygon(
              fetchAvailableBuildingsInBounds.data[key],
              allSitesBuildingsPolygons
            );
          }
          if (!buildingIntersections[key]) {
            const availableBuilding = fetchAvailableBuildingsInBounds.data[key];
            availableBuilding.polygonType = "osm";
            availableBuildings[key] = availableBuilding;
          }
        }
    });

    setBuildingsChanged(false);
    setBuildingIntersections(buildingIntersections);
    setAvailableBuildingsInBounds(availableBuildings);
    setNetworkBuildingsInBounds(networkBuildings);
  }, [
    buildingsChanged,
    buildingIntersections,
    fetchAvailableBuildingsInBounds.data,
    fetchNetworkBuildingsInBounds.data,
    initialSiteNewBuildingAdded,
    mapBounds,
    siteBuildings,
    addBuildingToSite,
    siteNewURLBuildingID,
    site.id,
  ]);

  // Site buildings
  useEffect(() => {
    if (!initialSiteBuildings) return;

    const buildings = {};
    initialSiteBuildings.forEach((building) => {
      const transformedBuilding = bmaBuildingToOsmBuilding(building);
      const key = transformedBuilding.coordinatesHash;
      buildings[key] = {
        ...transformedBuilding,
        key,
      };
    });
    setSiteBuildings(buildings);
  }, [initialSiteBuildings]);

  // Deeds
  const selectedBuildingBounds = boundsFromPolygons(siteBuildings);
  const fetchBuildingDeeds = useDeedsByBounds(selectedBuildingBounds);
  const deedsLoading = fetchBuildingDeeds.isLoading;
  const deeds = fetchBuildingDeeds.data || [];
  const [renderedDeed, setRenderedDeed] = useState({});
  const onDeedHover = (deed) => {
    const key = hash(deed.geometry?.coordinates || {});
    setRenderedDeed({ [key]: { ...deed, key } });
  };

  const addDeedtoSite = (deed) => {
    const key = hash(deed.geometry);
    const geometry = deed.geometry;
    const surface_area_sqm = deed.surface_area_sqm;
    const center_distance = 0; // For backward compatibility

    const newSiteBuildings = {
      ...siteBuildings,
      [key]: {
        geometry,
        center_distance,
        surface_area_sqm,
        key,
        coordinatesHash: key,
        polygonType: "deeds",
      },
    };
    setSiteBuildings(newSiteBuildings);
    setBuildingsForPreview(Object.values(newSiteBuildings));
  };

  // Address Bar
  const addressCallback = (suggestion) => {
    let postcode = "";
    suggestion.context.forEach((context) => {
      if (context.id.includes("postcode")) postcode = context.text;
    });
    postcode = suggestion.id.includes("postcode") || postcode;
    const suggestionValues = {
      address: suggestion.place_name,
      postcode,
    };
    const ownershipsArray = ownerships ? Object.values(ownerships) : [];

    setSite(suggestionValues);
    setNewSiteName(
      addressWithoutPostcode(suggestion.place_name),
      ownershipsArray
    );

    if (suggestion.bbox) {
      map.fitBounds([
        [suggestion.bbox[1], suggestion.bbox[0]],
        [suggestion.bbox[3], suggestion.bbox[2]],
      ]);
    } else {
      const [lng, lat] = suggestion.center;
      map.setView([lat, lng], 16);
    }
  };

  const buildingCount = Object.keys(siteBuildings).length;

  return (
    <SiteGeoView
      site={site}
      setHasUnsavedBuildings={setHasUnsavedBuildings}
      addBuildingToSite={addBuildingToSite}
      onSaveEditModeSiteBoundaries={onSaveEditModeSiteBoundaries}
      addDeedtoSite={addDeedtoSite}
      addressCallback={addressCallback}
      buildingCount={buildingCount}
      buildingsInBounds={availableBuildingsInBounds}
      networkBuildingsInBounds={networkBuildingsInBounds}
      deeds={deeds}
      deedsLoading={deedsLoading}
      map={map}
      mapStyle={mapStyle}
      onDeedHover={onDeedHover}
      removeBuildingFromSite={removeBuildingFromSite}
      siteBuildings={siteBuildings}
      renderedDeed={renderedDeed}
      setMap={setMap}
      setMapBounds={setMapBounds}
      setMapStyle={setMapStyle}
      setSite={setSite}
      siteMapZoom={siteMapZoom}
      zoomToSite={zoomToSite}
    />
  );
};

export default SiteGeo;

const SiteGeoView = ({
  site,
  setHasUnsavedBuildings,
  addBuildingToSite,
  addDeedtoSite,
  addressCallback,
  onSaveEditModeSiteBoundaries,
  buildingCount,
  buildingsInBounds,
  networkBuildingsInBounds,
  deeds,
  deedsLoading,
  map,
  mapStyle,
  onDeedHover,
  removeBuildingFromSite,
  siteBuildings,
  renderedDeed,
  setMap,
  setSite,
  setMapBounds,
  setMapStyle,
  siteMapZoom,
  zoomToSite,
}) => {
  const { fetchSites } = useSiteList();

  if (fetchSites.isLoading)
    return <Loading label="Loading map..." height="600px" />;

  const sites = fetchSites.data?.sites;

  const mapCenter = map?.getCenter();
  return (
    <>
      <BuildingMap
        sites={sites}
        setHasUnsavedBuildings={setHasUnsavedBuildings}
        selectedSite={site}
        mapStyle={mapStyle}
        setMapStyle={setMapStyle}
        setMap={setMap}
        map={map}
        siteBuildings={siteBuildings}
        buildingsInBounds={buildingsInBounds}
        networkBuildingsInBounds={networkBuildingsInBounds}
        renderedDeed={renderedDeed}
        removeBuildingFromSite={removeBuildingFromSite}
        addBuildingToSite={addBuildingToSite}
        onSaveEditModeSiteBoundaries={onSaveEditModeSiteBoundaries}
        siteAddress={site.address}
        isSiteList={false}
        setSite={setSite}
        addressCallback={addressCallback}
        setMapBounds={setMapBounds}
        siteMapZoom={siteMapZoom}
        mapHeight={"600px"}
        zoomToSite={zoomToSite}
      />
      {mapCenter && (
        <div style={{ textAlign: "right" }}>
          <a
            href={`https://maps.google.com/?q=${mapCenter.lat},${mapCenter.lng}`}
            rel="noreferrer"
            target="_blank"
          >
            View in Google Maps
          </a>
        </div>
      )}
      <Deeds
        deeds={deeds}
        onHover={onDeedHover}
        isLoading={deedsLoading}
        buildingCount={buildingCount}
        addDeedtoSite={addDeedtoSite}
      />
    </>
  );
};

const boundsFromPolygons = (siteBuildings) => {
  const bounds = {
    _northEast: { lat: undefined, lng: undefined },
    _southWest: { lat: undefined, lng: undefined },
  };

  const polygons = Object.values(siteBuildings);

  if (polygons.length === 0) return;

  polygons.forEach((polygon) => {
    polygon.geometry?.coordinates?.forEach((coordinates) => {
      coordinates.forEach(([lng, lat]) => {
        if (bounds._northEast.lat === undefined) {
          bounds._northEast.lat = lat;
          bounds._northEast.lng = lng;
          bounds._southWest.lat = lat;
          bounds._southWest.lng = lng;
        }
        if (lat > bounds._northEast.lat) {
          bounds._northEast.lat = lat;
        }
        if (lat < bounds._southWest.lat) {
          bounds._southWest.lat = lat;
        }
        if (lng > bounds._northEast.lng) {
          bounds._northEast.lng = lng;
        }
        if (lng < bounds._southWest.lng) {
          bounds._southWest.lng = lng;
        }
      });
    });
  });
  return L.latLngBounds(
    [bounds._northEast.lat, bounds._northEast.lng],
    [bounds._southWest.lat, bounds._southWest.lng]
  );
};
