import { createBrowserRouter, RouterProvider } from "react-router-dom";

import LoginRedirect from "./auth/LoginRedirect";
import OrgEdit from "./routes/org/OrgEdit";
import OrgList from "./routes/org/OrgList";
import Reports from "./routes/Reports";
import SiteDebug from "./routes/site/SiteDebug";
import SiteEdit from "./routes/site/SiteEdit";
import SiteList from "./routes/site/SiteList";
import SiteNew from "./routes/site/SiteNew";
import SiteSdm from "./routes/site/SiteSdm";
import Template from "./routes/Template";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Template />,
    children: [
      {
        index: true,
        element: <SiteList />,
      },
      {
        path: "/site/:siteId/edit",
        element: <SiteEdit />,
      },
      {
        path: "/site/:siteId/sdm",
        element: <SiteSdm />,
      },
      {
        path: "/site/:siteId/debug",
        element: <SiteDebug />,
      },
      {
        path: "/site/new",
        element: <SiteNew />,
        children: [
          {
            path: "/site/new/lat/:lat/lon/:lon/bkey/:bkey",
            element: <SiteNew />,
          },
        ],
      },
      {
        path: "/site",
        element: <SiteList />,
      },
      {
        path: "/organisation",
        element: <OrgList />,
      },
      {
        path: "/organisation/:orgId/edit",
        element: <OrgEdit />,
      },
      {
        path: "/login-redirect",
        element: <LoginRedirect />,
      },
      {
        path: "/reports",
        element: <Reports />,
      },
    ],
  },
]);

const AppRoutes = () => {
  return <RouterProvider router={router} />;
};

export default AppRoutes;
