import { GeoJsonTypes } from "geojson";

export interface InRangeBaseModel {
  createdBy: string;
  createdByName?: string;
  createdAt: number;
  updatedAt: number;
  updatedByEmail?: string;
  updatedByName?: string;
}

export interface SiteMPAN {
  importMPAN: string | null;
  exportMPAN: string | null;
}

export interface DnoData {
  name: string;
  annualAverageIntensity: number;
}

export interface Substation {
  success: boolean;
  generationHeadroomKva: number;
}

export interface SiteOwnership {
  orgID: string;
  ownership: string;
  siteID?: string;
  name?: string;
}

export interface CostInputsGrid {
  gridConnectionCost: number;
}

export interface CostInputsPv {
  // Define properties as needed
}

export interface CostInputsBattery {
  batteryCost: number;
}

export interface HalfHourlyDemand {
  source: string;
}

export interface HalfHourlyGeneration {
  kwpPerSqm: number;
}

// SdmMatchConfig interface
export interface SdmMatchConfig {
  // >> Buyer/seller
  buyerId: string;
  buyerName?: string;
  sellerId: string;
  sellerName?: string;

  // >> Match config
  tariff: number;
  volume: number;
  ppaContractType: string;
  ppaLength: number;
  ppaIndex: number;

  // >> Energy flow storage information
  energyFlowInputsFileVersionId?: string;

  // >> State to allow us to detect when a match curve is stale
  existingMatchesHash?: string;
  existingMatchesHashStale?: boolean;
}

export interface EnergyMatchConfig {
  tariff: number;
  ppaContractType: string;
  ppaLength: number;
  ppaType: string;
  ppaIndex: number;
}

export interface AggregateMatchedEnergy {
  sourceType: string;
  sinkType: string;
  energyFlow: number;
  energyMatchConfig: EnergyMatchConfig;
  sinkId?: string;
  sourceId?: string;
}

export interface AgregateEnergyFlow {
  demand: number;
  generation: number;
  totalPeriodCount: number;
  carbonFreePeriods: number;
  batteryCharge: number;
  batteryDischarge: number;
  batteryChargeLoss: number;
  behindMeter: number;
  behindMeterPV: number;
  behindMeterBattery: number;
  exported: number;
  exportedPV: number;
  exportedBattery: number;
  curtailed: number;
  networkImport: number;
  networkExport: number;
  gridImport: number;
  procurement: number;

  peakGrid: number;
  peakInRange: number;
  peakBehindMeter: number;
  peakTotalDemand: number;

  offPeakGrid: number;
  offPeakInRange: number;
  offPeakBehindMeter: number;
  offPeakTotalDemand: number;
  tenantEnergyShare: number;

  matchedEnergy: AggregateMatchedEnergy[];

  energyBalancePV: number;
  energyBalanceBatteryDischarge: number;
  energyBalanceNetworkImport: number[];
  energyBalanceDemand: number;
  energyBalanceBatteryCharge: number;
  energyBalanceNetworkExport: number[];
}

export interface EnergyFlowActualsMetadata {
  first_day: string; // Assuming the date is returned as a string
  last_day: string; // Assuming the date is returned as a string
}

export interface MonthlyEnergyFlowActuals {
  energyFlow: AgregateEnergyFlow;
  metadata?: EnergyFlowActualsMetadata;
}

export interface PeriodEnergyFlow {
  // >> Energy Sources
  generation: number[];
  // >> Energy Sinks
  demand: number[];
  // >> Battery
  batteryCharge: number[];
  batteryDischarge: number[];
  batteryFinalCharge: number[];
  // >> Energy matches
  behindMeterPV: number[];
  behindMeterBattery: number[];
  networkImport: number[];
  networkExport: number[];
  exportedPV: number[];
  exportedBattery: number[];
  // >> Energy matches: Excess energy
  curtailed: number[];
  gridImport: number[];
}

export interface ProjectCosts {
  initialCostPerKWp: number;

  total: number;
  initialInvestment: number;

  // Hardware costs
  hardware: number;
  panel: number;
  mounting: number;
  inverter: number;
  electrical: number;
  bos: number;
  misc: number;

  // Install costs
  installation: number;

  // Ongoing costs
  maintenance: number;
  insurance: number;
  maintenanceYearOne: number;
  insuranceYearOne: number;

  // Replacement costs
  replacementCapex: number;

  // Battery costs
  batteryReplacementCapex: number;

  // These are always 0 for lease customers
  leaseInitialCostPerKWp: number;
  leaseInstallation: number;
  leaseHardware: number;
  leaseInitialInvestment: number;
  leaseMaintenance: number;
  leaseBatteryCost: number;
}

export interface FinancialModelOwnerOccupierLease {
  revenue: number;
  savings: number;
  lifetimeSavings?: number;
  lifetimeRevenue?: number;
}

export interface FinancialModelOwnerOccupierLicense
  extends FinancialModelOwnerOccupierLease {
  managementCosts: number;
  irr?: number;
  paybackMonths?: number;
}

export interface FinancialModelOwnerOccupierInvestmentModels {
  lease: FinancialModelOwnerOccupierLease;
  license: FinancialModelOwnerOccupierLicense;
}

export interface FinancialModelLandlordLease {
  revenue: number;
  lifetimeRevenue?: number;
}

export interface FinancialModelLandlordLicense
  extends FinancialModelLandlordLease {
  irr?: number;
  paybackMonths?: number;
}

export interface FinancialModelLandlordInvestmentModels {
  lease: FinancialModelLandlordLease;
  license: FinancialModelLandlordLicense;
}

export interface FinancialModelTenant {
  savings: number;
  lifetimeSavings: number;
}

export interface FinancialModelScenarios {
  ownerOccupier: FinancialModelOwnerOccupierInvestmentModels;
  landlord: FinancialModelLandlordInvestmentModels;
  tenant: FinancialModelTenant;
}

export interface HubSpotSite {
  siteID: string;
  hubspotID: string;
  createdBy: string;
  hubspotUpdateTime?: number;
}

export interface SiteFile extends InRangeBaseModel {
  siteID: string;
  fileType: string;
  fileName: string;
  fileGuid: string;
  fileVersionId: string;
  date?: number;
  isLandlord?: boolean;
  description?: string;
  invoiceNumber?: string;
  invoiceAmount?: number;
  invoiceDueDate?: number;
  statementNumber?: string;
  statementAmount?: number;
  issueDate?: number;
}

export interface Panel {
  manufacturer: string;
  model: string;
  width: number;
  height: number;
  weight: number;
  pnom: number;
}

export interface Building extends InRangeBaseModel {
  id: string;
  siteID: string;
  geometry: {
    type: GeoJsonTypes;
    coordinates: string | number[][][];
  };
  surfaceAreaSqM: number;
  polygonType?: string;
}

export const getBuildingCoordinates = (building: Building): number[][][] => {
  let coordinates = building.geometry.coordinates;
  if (typeof building.geometry.coordinates === "string") {
    coordinates = JSON.parse(building.geometry.coordinates);
  }
  return coordinates as unknown as [number, number][][];
};

export interface Calculations {
  emissionsAvoided: { totalAvoidance: number };
}

type MakeOptionalExcept<T, K extends keyof T> = Partial<T> & Pick<T, K>;

// Type used for creating a site - all fields are optional except for id
export type NewSite = MakeOptionalExcept<Site, "id" | "name">;

// Type used for previewing a site - all fields are optional
export type PartialSite = Partial<Site>;

export interface Site extends InRangeBaseModel {
  id: string;

  // >> Basic attributes
  name: string;
  address: string;
  postcode: string;
  latitude: number;
  longitude: number;
  mapZoom: number;
  isScenario: boolean;
  siteMPANs: SiteMPAN[];

  // >> Location linked data
  countryCode: string;
  currencyCode: string;
  dno?: DnoData;
  substationData: Substation;

  // >> Buildings
  buildings: Building[];
  totalBuildingArea: number;

  // >> Ownership
  siteOwnerships: SiteOwnership[];

  // >> Demand
  demandCoefficientKWhPerM2: number;
  buildingProfile: string;
  profileShape: string;
  hhDemandFileVersionId: string;
  hhDemandFileDescription: string;
  costInputsGrid: CostInputsGrid;
  tenantAnnualDemandKwh: number;

  // >> Generation
  installedCapacity: number;
  generationLossFactors: Record<string, number>;
  pvPanelName: string;
  pvDesignSystemLastUpdatedAt?: number;
  pvDesignSystemLastUpdatedBy?: string;
  annualGenerationLoss: number;
  pvInverterBrand: string;
  costInputsPv: CostInputsPv;
  pvPanelCount: number;
  pvPanel: Panel;

  // >> Battery
  batteryPower?: number;
  batteryCapacity?: number;
  batteryModel?: string;
  costInputsBattery: CostInputsBattery;

  // >> Energy flow inputs
  exportLimit?: number; // Units: kVA
  halfHourlyDemand: HalfHourlyDemand;
  halfHourlyGeneration: HalfHourlyGeneration;
  sdmInputsDataHash?: string;
  batteryEnergyFlowPayloadHash?: string;
  batteryEnergyFlowFileVersionId?: string;
  sdmMatches: SdmMatchConfig[];

  // >> Energy flow outputs
  energyFlowAnnual: AgregateEnergyFlow;
  energyFlowMonthly: {
    [month: number]: AgregateEnergyFlow;
  };
  monthlyTenantEnergyShare: number[];
  energyFlowSummerDay: PeriodEnergyFlow;
  energyFlowWinterDay: PeriodEnergyFlow;

  // >> Operational attributes
  commerciallyOperationalDateEpochSeconds?: number;
  commerciallyOperationalMonths: number;
  operationalStatus: string;

  // >> Tariffs
  marketTariff: number;
  tenantTariff: number;
  // inrangeExportTariff is a bad name, this is actually the "Spill export tariff"
  inrangeExportTariff: number;
  networkImportTariff: number;
  blendedExportTariff: number;

  // >> Deal and contract attributes
  ppaLength: number;
  exportPPALength: number;
  ppaType: string;
  exportPPAType: string;
  ppaIndex: number;
  exportPPAIndex: number;
  leaseLengthDateEpochSeconds?: number;
  leaseLengthMonths: number;

  // >> Financial modeling - inputs
  landlordLeaseRevenueShare: number;
  landlordLicenseRevenueShare: number;
  ownerOccupierLicenseRevenueShare: number;
  ownerOccupierLicenseInRangeSaasTariffRate: number;
  energyPriceInflationRate: number;
  costInputsEconomic: { costInflationRates: number[] };
  investmentModel: string;
  activeInvestmentModel: string;

  // >> Financial modeling - outputs
  projectCosts: ProjectCosts;
  financialModels: FinancialModelScenarios;

  // >> Actuals
  energyFlowMonthlyActuals?: {
    [month: number]: MonthlyEnergyFlowActuals;
  };
  financialModelsMonthlyActuals?: Record<string, FinancialModelScenarios>;

  // >> Marketplace
  minMarketplaceMatchSize: number;

  // >> HubSpot attributes
  updateHubSpot: boolean;
  hubspotSite?: HubSpotSite;

  // >> Files
  siteFiles: SiteFile[];
  calculations: Calculations;
}

export interface NetworkSite {
  id: string;
  latitude: number;
  longitude: number;
  networkImportTariff: number;
  commerciallyOperationalMonths: number;
  isNetwork?: boolean;
  energyFlowAnnual: AgregateEnergyFlow;
  currencyCode: string;
}

export interface SdmOffer {
  financialModels: FinancialModelScenarios;
  config: SdmMatchConfig;
  dest: {
    id: string;
    dno: string;
    sub: string;
    km: number;
    from_additional_org: boolean;
    is_scenario: boolean;
    minMarketplaceMatchSize: number;
    gridImport: number;
    exported: number;
    availabilityMonths: number;
  };
  emissionsAvoidedYearOne: number;
}

export interface BuyOfferEnergyFlows {
  summerDay?: number[];
  winterDay?: number[];
  monthly: { [key: string]: number };
}

export interface SitePreviewError {
  error: string;
  invalid_states: { loc: string[]; msg: string; type: string }[];
}

export interface SiteCalculations {
  // >> Location linked data
  countryCode: string;
  currencyCode: string;
  dno: Partial<DnoData>;
  substationData: Partial<Substation>;
  // >> Buildings
  totalBuildingArea: number;
  // >> Demand
  tenantAnnualDemandKwh: number;
  // >> Generation
  pvPanelCount: number;
  // >> Battery
  costInputsBattery: Partial<CostInputsBattery>;
  // >> Energy flow inputs
  halfHourlyGeneration: Partial<HalfHourlyGeneration>;
  // >> Energy flow outputs
  energyFlowAnnual: Partial<AgregateEnergyFlow>;
  monthlyTenantEnergyShare: number[];
  // >> Tariffs
  blendedExportTariff: number;
  // >> Deal and contract attributes
  leaseLengthMonths: number;
  // >> Financial modeling - outputs
  projectCosts: Partial<ProjectCosts>;
  financialModels: Partial<FinancialModelScenarios>;
}
