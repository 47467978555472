import { useSiteList } from "@inrange/building-manager-api-client";
import Loading from "../Loading";
import MapOfSites from "./MapOfSites";

const SiteMap = () => {
  const { fetchSites } = useSiteList();

  if (fetchSites.isLoading)
    return <Loading label="Loading site map..." height={"800px"} />;

  const sites = fetchSites.data?.sites;
  return <MapOfSites sites={sites} />;
};

export default SiteMap;
