/* eslint-disable react-refresh/only-export-components */
import * as L from "leaflet";
import "leaflet-editable";
import "leaflet.path.drag";
import "leaflet/dist/leaflet.css";
import React, { useEffect, useRef } from "react";
import { useMap } from "react-leaflet";

export const MapHook: React.FC<{ bounds: L.LatLngBounds }> = ({ bounds }) => {
  const map = useMap();
  map.invalidateSize();

  map.dragging.enable();
  const boundsRef = useRef<L.LatLngBounds>();
  const prevBounds = boundsRef.current;
  // We zoom the map if the computed bounds change
  const shouldZoomMap = prevBounds === undefined || !prevBounds.equals(bounds);
  boundsRef.current = bounds;
  useEffect(() => {
    if (shouldZoomMap) {
      map.fitBounds(boundsRef.current!);
    }
  }, [map, shouldZoomMap]);
  return null;
};
