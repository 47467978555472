import {
  formatCurrency,
  formatPercentage,
} from "@inrange/theme-components/formatting";
import isSiteOwnerOccupied from "../../../utils/isSiteOwnerOccupied";
import { numString2dp } from "./utils";

const SdmMatchImpactDescription = ({
  site,
  selectedOffer,
  isOfferBeingAdded,
  fetchSiteBuyer,
  previewSiteBuyer,
  fetchSiteSeller,
  previewSiteSeller,
}) => {
  const getSavings = (site) => {
    const savingsOwnership = isSiteOwnerOccupied(site.siteOwnerships)
      ? "ownerOccupier"
      : "tenant";
    const savingsFinancialModel = site.financialModels[savingsOwnership];
    return savingsOwnership === "ownerOccupier"
      ? savingsFinancialModel[site.activeInvestmentModel]
      : savingsFinancialModel;
  };

  const getIrr = (site) => {
    const irrOwnership = isSiteOwnerOccupied(site.siteOwnerships)
      ? "ownerOccupier"
      : "landlord";
    return site.financialModels[irrOwnership]["license"].irr;
  };

  const buyerMessage = (
    <>
      buyer&apos;s savings by{" "}
      {formatCurrency(
        getSavings(previewSiteBuyer.data.site).savings -
          getSavings(fetchSiteBuyer.data.site).savings,
        previewSiteBuyer.data.site.currencyCode,
        2,
        false,
        true
      )}{" "}
      (Y1) to{" "}
      <b>
        {formatCurrency(
          getSavings(previewSiteBuyer.data.site).savings,
          previewSiteBuyer.data.site.currencyCode,
          2,
          false
        )}{" "}
        (Y1),{" "}
        {formatCurrency(
          getSavings(previewSiteBuyer.data.site).lifetimeSavings,
          previewSiteBuyer.data.site.currencyCode,
          2,
          false
        )}{" "}
        (Lifetime)
      </b>
    </>
  );
  const sellerMessage = (
    <>
      seller&apos;s IRR by{" "}
      {formatPercentage(
        getIrr(previewSiteSeller.data.site) - getIrr(fetchSiteSeller.data.site),
        2,
        false,
        true
      )}{" "}
      to <b>{formatPercentage(getIrr(previewSiteSeller.data.site), 2)}</b>
    </>
  );

  return (
    <>
      {selectedOffer.buyerId === site.id && (
        <>
          This match will change the {buyerMessage} and the {sellerMessage},{" "}
          {isOfferBeingAdded ? "removing" : "adding"}{" "}
          {numString2dp(selectedOffer.volume)} kWh{" "}
          {isOfferBeingAdded ? "from" : "to"} the buyer&apos;s unmet demand
          excess and the seller&apos;s excess pools.
        </>
      )}
      {selectedOffer.sellerId === site.id && (
        <>
          This match will change the {sellerMessage} and the {buyerMessage},{" "}
          {isOfferBeingAdded ? "removing" : "adding"}{" "}
          {numString2dp(selectedOffer.volume)} kWh{" "}
          {isOfferBeingAdded ? "from" : "to"} the seller&apos;s excess and the
          buyer&apos;s unmet demand pools.
        </>
      )}
    </>
  );
};

export default SdmMatchImpactDescription;
