import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import {
  useMutation,
  UseMutationResult,
  useQueries,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "react-query";
import deleteOrganisationUser from "../http/deleteOrganisationUser";
import getOrganisation from "../http/getOrganisation";
import getSite from "../http/getSite";
import postOrganisation from "../http/postOrganisation";
import postOrganisationUser from "../http/postOrganisationUser";
import putOrganisation from "../http/putOrganisation";
import { Organisation, OrgSiteListEntry } from "../models/organisation";
import { SiteOwnership } from "../models/site";

const useOrganisation = (orgId?: string, userOrgId?: string) => {
  const { getToken, getClaim } = useKindeAuth();
  const kindeTokenOrgId = getClaim("external_org_id")?.value;
  userOrgId = kindeTokenOrgId === "inrange.io" ? userOrgId : undefined;

  const queryClient = useQueryClient();
  const fetchOrganisation: UseQueryResult<
    { organisation: Organisation; sites: OrgSiteListEntry[] },
    unknown
  > = useQuery(
    ["org", orgId],
    async () => {
      return getOrganisation(await getToken(), orgId, userOrgId);
    },
    { enabled: !!orgId }
  );

  const useOrganisationSites = (siteOwnerships: SiteOwnership[] = []) => {
    const siteQueries = useQueries(
      siteOwnerships.map((siteOwnership) => {
        const siteId = siteOwnership.siteID;
        return {
          queryKey: ["site", siteId],
          queryFn: async () => getSite(await getToken(), siteId, userOrgId),
          staleTime: 5 * 60 * 1000,
        };
      })
    );
    return siteQueries;
  };

  const updateOrganisation: UseMutationResult<void, unknown, unknown, unknown> =
    useMutation(
      async (update: unknown) =>
        putOrganisation(await getToken(), orgId, update),
      {
        onSuccess: (_) => {
          queryClient.invalidateQueries(["org", orgId]);
        },
      }
    );

  const addOrgUser: UseMutationResult<void, unknown, unknown, unknown> =
    useMutation(
      async (data: unknown) => {
        return postOrganisationUser(await getToken(), orgId, data, userOrgId);
      },
      {
        onSuccess: (_) => {
          queryClient.invalidateQueries(["org", orgId]);
        },
      }
    );

  const removeOrgUser: UseMutationResult<void, unknown, string, unknown> =
    useMutation(
      async (user: string) => {
        return deleteOrganisationUser(await getToken(), orgId, user);
      },
      {
        onSuccess: (_) => {
          queryClient.invalidateQueries(["org", orgId]);
        },
      }
    );

  const createOrg: UseMutationResult<void, unknown, Organisation, unknown> =
    useMutation(async (org: Organisation) =>
      postOrganisation(await getToken(), org)
    );

  return {
    addOrgUser,
    removeOrgUser,
    createOrg,
    fetchOrganisation,
    useOrganisationSites,
    updateOrganisation,
  };
};

export default useOrganisation;
