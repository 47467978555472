import React, { ReactNode } from "react";
import styled from "styled-components";
import circleQuestionMark from "../images/icons/circleQuestionMark.svg";

interface TooltipProps {
  children?: ReactNode;
  text: string;
  position?: "top" | "bottom" | "bottomLeft" | "bottomRight" | "left" | "right";
}

const Tooltip: React.FC<TooltipProps> = ({ children, text, position }) => (
  <TooltipContainer
    data-tooltip={text}
    position={position}
    hasChildren={!!children}
  >
    {children ? (
      children
    ) : (
      <img
        src={circleQuestionMark}
        alt="info"
        style={{ width: "16px", height: "16px" }}
      />
    )}
  </TooltipContainer>
);

interface TooltipContainerProps {
  hasChildren: boolean;
  position?: "top" | "bottom" | "bottomLeft" | "bottomRight" | "left" | "right";
}

const TooltipContainer = styled.div<TooltipContainerProps>`
  position: relative;
  display: inline-block;
  &:hover::after {
    width: max-content;
    max-width: 200px;
    content: attr(data-tooltip);
    white-space: pre-wrap;
    position: absolute;
    ${({ position }) => {
      switch (position) {
        case "top":
          return "bottom: calc(100% + 10px);";
        case "bottom":
          return "top: calc(50% + 10px);";
        case "bottomLeft":
          return "top: calc(50% + 10px);";
        case "bottomRight":
          return "top: calc(50% + 10px);";
        case "left":
          return "right: calc(100% + 10px);";
        case "right":
          return "left: calc(100% + 10px);";
        default:
          return "bottom: calc(100% + 10px);";
      }
    }}
    padding: 10px;
    background: #f5f5f5;
    border-radius: 8px;
    color: #000000;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    z-index: 999999999;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.1);
    ${({ position }) => {
      if (position === "left" || position === "right") {
        return "top: 50%; transform: translateY(-80%);";
      }
      if (position === "bottomLeft") return "right: 50%;";
      if (position === "bottomRight") return "left: 50%;";
      return "left: 50%; transform: translateX(-80%);";
    }}
  }
`;

export default Tooltip;
