import { useSite } from "@inrange/building-manager-api-client";
import { buildSitePayload } from "@inrange/shared-components";
import { Modal, ModalView } from "@inrange/theme-components";
import { useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import Loading from "../../Loading";
import SdmMatchImpactDescription from "./SdmMatchImpactDescription";

const ModalWraper = ({ children }) => {
  return (
    <Modal>
      <ModalView
        title={"Delete match?"}
        fontWeight="500"
        fontSize="20px"
        titlePadding="0"
      >
        {children}
      </ModalView>
    </Modal>
  );
};

const SdmDeleteMatchModal = ({
  site,
  selectedDeleteOffer,
  setSelectedDeleteOffer,
  siteDeleteMatch,
}) => {
  const { fetchSite: fetchSiteBuyer, previewSite: previewSiteBuyer } = useSite({
    siteId: selectedDeleteOffer.buyerId,
    enableNonEssentialQueries: false,
    app: "admin",
  });
  const { fetchSite: fetchSiteSeller, previewSite: previewSiteSeller } =
    useSite({
      siteId: selectedDeleteOffer.sellerId,
      enableNonEssentialQueries: false,
      app: "admin",
    });

  const previewSiteBuyerRef = useRef();
  const previewSiteSellerRef = useRef();
  previewSiteBuyerRef.current = previewSiteBuyer;
  previewSiteSellerRef.current = previewSiteSeller;

  // Trigger preview site updates when the selected offer or PPA details change
  useEffect(() => {
    if (
      !fetchSiteBuyer.isSuccess ||
      !fetchSiteSeller.isSuccess ||
      fetchSiteBuyer.isFetching ||
      fetchSiteSeller.isFetching
    ) {
      return;
    }
    const buyerSiteWithNewMatch = buildSitePayload({
      ...fetchSiteBuyer.data.site,
      sdmMatches: fetchSiteBuyer.data.site.sdmMatches.filter((match) => {
        match.buyerId !== selectedDeleteOffer.buyerId &&
          match.sellerId !== selectedDeleteOffer.sellerId;
      }),
    });
    const sellerSiteWithNewMatch = buildSitePayload({
      ...fetchSiteSeller.data.site,
      sdmMatches: fetchSiteSeller.data.site.sdmMatches.filter((match) => {
        match.buyerId !== selectedDeleteOffer.buyerId &&
          match.sellerId !== selectedDeleteOffer.sellerId;
      }),
    });

    previewSiteBuyerRef.current.mutate({ site: buyerSiteWithNewMatch });
    previewSiteSellerRef.current.mutate({ site: sellerSiteWithNewMatch });
  }, [
    fetchSiteBuyer.isSuccess,
    fetchSiteBuyer.isFetching,
    fetchSiteBuyer.data,
    fetchSiteSeller.isSuccess,
    fetchSiteSeller.isFetching,
    fetchSiteSeller.data,
    selectedDeleteOffer,
  ]);

  if (fetchSiteBuyer.isLoading || fetchSiteSeller.isLoading) {
    return (
      <ModalWraper>
        <Loading height="100px" label="Loading match sites data..." />
      </ModalWraper>
    );
  }

  if (fetchSiteBuyer.isError || fetchSiteSeller.isError) {
    return (
      <ModalWraper>
        <div>Error loading the match sites.</div>
        <Button
          variant="secondary"
          size="sm"
          onClick={() => {
            setSelectedDeleteOffer(undefined);
          }}
        >
          Close
        </Button>
      </ModalWraper>
    );
  }
  return (
    <ModalWraper>
      <div className="py-3">
        {selectedDeleteOffer.buyerId === site.id && (
          <>
            From buyer {fetchSiteBuyer.data.site.name} to seller{" "}
            {fetchSiteSeller.data.site.name}.
          </>
        )}
        {selectedDeleteOffer.sellerId === site.id && (
          <>
            From seller {fetchSiteSeller.data.site.name} to buyer{" "}
            {fetchSiteBuyer.data.site.name}.
          </>
        )}
      </div>
      <div className="py-3">
        {(previewSiteBuyer.isLoading || previewSiteSeller.isLoading) && (
          <Loading label="Computing match impact..." height="80px" />
        )}
        {(previewSiteBuyer.isError || previewSiteSeller.isError) && (
          <>Error loading the match impact.</>
        )}
        {previewSiteBuyer.isSuccess && previewSiteSeller.isSuccess && (
          <SdmMatchImpactDescription
            site={site}
            selectedOffer={selectedDeleteOffer}
            isOfferBeingAdded={false}
            fetchSiteBuyer={fetchSiteBuyer}
            previewSiteBuyer={previewSiteBuyer}
            fetchSiteSeller={fetchSiteSeller}
            previewSiteSeller={previewSiteSeller}
          />
        )}
      </div>
      <div
        style={{
          display: "flex",
          gap: 24,
          justifyContent: "center",
        }}
      >
        <Button
          variant="secondary"
          size="sm"
          onClick={() => {
            setSelectedDeleteOffer(undefined);
          }}
        >
          No, go back
        </Button>
        <Button
          variant="success"
          size="sm"
          onClick={() => {
            siteDeleteMatch.mutate(selectedDeleteOffer);
            setSelectedDeleteOffer(undefined);
          }}
        >
          Yes, delete
        </Button>
      </div>
    </ModalWraper>
  );
};

export default SdmDeleteMatchModal;
