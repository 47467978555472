import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

interface NotesProps {
  notes: string;
  setSite: (update) => void;
}

const Notes = ({ notes, setSite }: NotesProps) => {
  return (
    <Card body className="mt-2">
      <Form.Label>
        <strong>Notes</strong>
      </Form.Label>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="notes">
            <Form.Control
              as="textarea"
              rows={6}
              value={notes}
              onChange={(e) => setSite({ notes: e.target.value })}
            />
          </Form.Group>
        </Col>
      </Row>
    </Card>
  );
};

export default Notes;
