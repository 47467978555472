const SITE_ORG_LABELS = {
  landlord: "Landlord",
  tenant: "Tenant",
  ownerOccupier: "Owner Occupied",
  agent: "Agent",
  broker: "Broker",
};

const SITE_STATUS_LABELS = {
  lease: "Lease",
  license: "License",
  none: "Not selected",
};

const USAGE_PROFILE_LABELS = {
  standard: "Daytime",
  evCharging: "At night",
  flat: "24hr usage",
};

const PPA_TYPE_LABELS = {
  fixed: "Fixed",
  indexed: "Indexed",
};

const NUMBER_DAYS_LABELS = {
  FIVE_DAYS: "5 days",
  SEVEN_DAYS: "7 days",
};

export {
  NUMBER_DAYS_LABELS,
  PPA_TYPE_LABELS,
  SITE_ORG_LABELS,
  SITE_STATUS_LABELS,
  USAGE_PROFILE_LABELS,
};
